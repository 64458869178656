import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PsycholologicalGroupSwitch from '../../common/psychologicalGroups/PsycholologicalGroupSwitch';
import AttendanceDetailsDialog from '../attendance/dialogs/AttendanceDetailsDialog';
import { useDispatch, useSelector } from 'react-redux';
import { changePsychologicalGroup } from '../../../actions/psychologicalChoosenGroupActions';
import { loadChildIndividualJournalAttendanceAsync } from '../../../actions/psychologicalIndividualJournalAttendanceActions';
import moment from 'moment';
import {
  reportChildIndividualJournalAbsence,
  reportChildIndividualJournalAttendance
} from '../../../actions/psychologicalAttendanceActions';
import ChildAttendanceNoHoursCalendar from '../../common/children/attendance/calendar/ChildAttendanceNoHoursCalendar';

const ChildIndividualJournalPsychologicalAttendance = ({ childDetails, isInitiallyExpanded }) => {
  const dispatch = useDispatch();
  const daysOff = useSelector((state) => state.configuration.daysOff);
  const attendances = useSelector((state) => state.psychologicalIndividualJournalAttendance);
  const psychologicalGroups = useSelector((state) => state.psychologicalGroups);
  const Ui = useSelector((state) => state.childDetailsUi.individualJournalAttendanceUi);

  const childGroups = useSelector((state) => state.childDetails.enrolledToPsychologicalPedagogicalGroup);
  const [selectedGroup, setSelectedGroup] = useState(childGroups[0]?.id);

  const [isDetailsOpen, setDetailsOpen] = useState(false);
  const [selectedDetailsDay, setSelectedDetailsDay] = useState();
  const [calendarDate, setCalendarDate] = useState();

  useEffect(() => {
    if (!selectedGroup) {
      setSelectedGroup(childGroups[0].id);
    }
  }, [childGroups, selectedGroup]);

  useEffect(() => {
    if (selectedGroup) {
      console.log(moment(calendarDate).format('YYYY-MM'));
      dispatch(
        loadChildIndividualJournalAttendanceAsync(
          selectedGroup,
          childDetails.id,
          moment(calendarDate).format('YYYY-MM')
        )
      );
    }
  }, [calendarDate, childDetails.id, dispatch, selectedGroup]);

  const handleGroupChange = (value) => {
    setSelectedGroup(value);
    dispatch(changePsychologicalGroup(psychologicalGroups.find((group) => group.id === value)));
  };

  const onDateChange = (date) => {
    setCalendarDate(date);
  };

  const handleDetailsOpen = (e) => {
    setSelectedDetailsDay(e);
    setDetailsOpen(true);
  };

  const handleReportAttendance = async (day) => {
    await dispatch(
      reportChildIndividualJournalAttendance({
        childId: childDetails.id,
        date: day.date,
        groupId: selectedGroup,
        droppedOfAt: day.date,
        pickedUpAt: day.date
      })
    );
  };

  const handleReportAbsence = async (day) => {
    await dispatch(
      reportChildIndividualJournalAbsence({
        groupId: selectedGroup,
        childId: childDetails.id,
        absenceFrom: day.date,
        absenceTo: moment(day.date),
        absenceNotes: ''
      })
    );
  };

  return (
    <>
      <Accordion sx={{ p: 1, mt: 2 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
            Obecności na zajęciach specjalistycznych
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <PsycholologicalGroupSwitch groups={childGroups} handleChange={handleGroupChange} value={selectedGroup} />
          </Box>
          <ChildAttendanceNoHoursCalendar
            noDescription
            allDays={attendances}
            markedDays={!!attendances?.entries ? attendances.entries : []}
            attendanceSummary={!!attendances?.summary ? attendances.summary : {}}
            daysOff={daysOff}
            onDateChange={(e) => onDateChange(e)}
            onDaySelected={() => {}}
            onReportAttendance={handleReportAttendance}
            onReportAbsence={handleReportAbsence}
            onDetailsOpen={(day) => handleDetailsOpen(day)}
            isLoading={Ui.isCalendarLoading}
            defaultMonth={moment(calendarDate).format('MM')}
            defaultYear={moment(calendarDate).format('YYYY')}
          />
        </AccordionDetails>
      </Accordion>
      <AttendanceDetailsDialog
        isPsychological
        isDialogOpen={isDetailsOpen}
        childDetails={childDetails}
        childAttendance={attendances?.entries?.find((day) => day?.date === selectedDetailsDay?.data?.date)}
        onRequestClose={() => setDetailsOpen(false)}
      />
    </>
  );
};

export default ChildIndividualJournalPsychologicalAttendance;
