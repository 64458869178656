// import api from '../api/psychologicalApi';
// import diaryApi from '../api/DigitalDiaryApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';
import api from '../api/SpecialistNotesApi';

export function setSpecialistWorkPlanDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.SPECIALIST_WORK_PLAN_START });
  };
}

export function setSpecialistWorkPlanDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.SPECIALIST_WORK_PLAN_CANCEL });
  };
}

export function addSpecialistWorkPlan(data, workId) {
  return (dispatch) => {
    return api
      .addNewEvent({ ...data, recordType: 'workPlan' }, workId)
      .then((res) => {
        dispatch({ type: types.ADD_SPECIALIST_WORK_PLAN_ELEMENT, data: { ...data, id: res.recordId } });
        dispatch(notificationActions.showSuccess('Dodano plan pracy'));
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            error?.response?.body?.generalError || 'Wystąpił błąd podczas dodawania planu pracy'
          )
        );
        return logger.error(error);
      });
  };
}

export function editSpecialistWorkPlanElement(data, workId) {
  console.log(data);
  return (dispatch) => {
    return api
      .editEvent({ ...data, recordType: 'workPlan' }, workId)
      .then((res) => {
        dispatch({ type: types.EDIT_SPECIALIST_WORK_PLAN_ELEMENT, data });
        dispatch(notificationActions.showSuccess('Edytowano plan pracy'));
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            error?.response?.body?.generalError || 'Wystąpił błąd podczas edycji planu pracy'
          )
        );
        return logger.error(error);
      });
  };
}

export function deleteSpecialistWorkPlanElement(recordId, workId) {
  return (dispatch) => {
    return api
      .deleteEvent(recordId, workId)
      .then(() => {
        dispatch({ type: types.DELETE_SPECIALIST_WORK_PLAN_ELEMENT, id: recordId });
        dispatch(notificationActions.showSuccess(`Usunięto wpis z planu pracy`));
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć wpisu z planu pracy`));
        return logger.error(error);
      });
  };
}
