import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import DatePicker from '../../common/DatePicker';
import DialogForm from '../../forms/DialogForm';
import TextField from '../../common/TextField';
import { tryConvertToNumber } from '../../../utils/numberConverters';

const ApproveDuesDialog = ({ isEmployee, onSave, onCancel, isDialogOpen, isProcessing, dueCalculationsIds }) => {
  const paymentDueDate = useSelector((state) => state.configuration.settlementsSettings.paymentDueDate);

  const initialMaturityDate = useMemo(() => {
    if (paymentDueDate) {
      return moment(paymentDueDate);
    }
    return moment().add(14, 'days');
  }, [paymentDueDate]);

  const [approveDetails, setApproveDetails] = useState({
    maturityDate: initialMaturityDate.format('YYYY-MM-DD'),
    maturityDateDays: initialMaturityDate.diff(moment().startOf('day'), 'days'),
    dueCalculationsIds
  });
  const [errors, setErrors] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const handleMaturityDateChange = useCallback((value) => {
    const newMaturityDate = value.format('YYYY-MM-DD');
    const newMaturityDateDays = value.diff(moment().startOf('day'), 'days');
    const newError = newMaturityDateDays < 0 ? 'data nie może wskazywać przeszłości' : undefined;

    setApproveDetails((prev) => ({
      ...prev,
      maturityDate: newMaturityDate,
      maturityDateDays: newMaturityDateDays
    }));
    setErrors((prev) => ({ ...prev, maturityDate: newError }));
    setIsSaveDisabled(newMaturityDateDays < 0);
  }, []);

  const handleMaturityDateDaysChange = useCallback((value) => {
    const numValue = tryConvertToNumber(value);
    const newMaturityDate = moment().add(numValue, 'days').format('YYYY-MM-DD');
    const newError = numValue < 0 ? 'termin zapłaty nie może być ujemny' : undefined;

    setApproveDetails((prev) => ({
      ...prev,
      maturityDate: newMaturityDate,
      maturityDateDays: numValue
    }));
    setErrors((prev) => ({ ...prev, maturityDateDays: newError }));
    setIsSaveDisabled(numValue < 0);
  }, []);

  const handleValidationDone = useCallback((newErrors) => {
    setErrors(newErrors);
  }, []);

  return (
    <DialogForm
      maxWidth="sm"
      header="Zatwierdzanie naliczonych opłat"
      onSave={() => onSave(approveDetails)}
      onCancel={onCancel}
      isDialogOpen={isDialogOpen}
      isProcessing={isProcessing}
      onValidationDone={handleValidationDone}
      statePathToUi="approveDuesUi"
      saveLabel="Zatwierdź"
      saveDisabled={isSaveDisabled}>
      {!isEmployee && <Typography>Zatwierdzone należności pojawią się na kontach opiekunów.</Typography>}

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          hintText="Termin płatności (ilość dni)"
          floatingLabelText="Termin płatności (ilość dni)"
          floatingLabelFixed={false}
          name="maturityDateDays"
          value={approveDetails.maturityDateDays || '0'}
          onChange={(e) => handleMaturityDateDaysChange(e.target.value)}
          onBlur={(e) => handleMaturityDateDaysChange(e.target.value)}
          errorText={errors.maturityDateDays}
          sx={{ mt: 0, mr: 3, mb: 3, ml: 0, width: '250px', maxWidth: '100%' }}
        />
        <DatePicker
          hintText="Termin płatności"
          floatingLabelText="Termin płatności"
          floatingLabelFixed={false}
          name="maturityDate"
          value={new Date(approveDetails.maturityDate)}
          errorText={errors.maturityDate}
          minDate={new Date('2000/01/01')}
          onChange={(empty, date) => handleMaturityDateChange(moment(date))}
          sx={{ minWidth: 100, m: 0 }}
        />
      </Box>
    </DialogForm>
  );
};

ApproveDuesDialog.propTypes = {
  isEmployee: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  dueCalculationsIds: PropTypes.array.isRequired
};

export default ApproveDuesDialog;
