import { TextField, Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DialogForm from '../forms/DialogForm';
import { NoteValidator } from '../../models/psychological/notes/NoteModel';
import DatePicker from '../common/DatePicker';
import TimePicker from '../common/TimePicker';

const NotesAndObservationsDialog = ({ handleCancel, selectedElement, onSave, type, Ui }) => {
  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState(selectedElement ? selectedElement.title : undefined);
  const [content, setContent] = useState(selectedElement ? selectedElement.content : undefined);
  const [date, setDate] = useState(selectedElement ? selectedElement?.date : null);
  const [time, setTime] = useState(!!selectedElement?.time ? moment(selectedElement.time, 'HH:mm') : null);

  const handleSave = () => {
    const data = {
      title: title,
      content: content,
      id: selectedElement ? selectedElement.id : undefined,
      type: type,
      date: !!date ? moment(date).format('YYYY-MM-DD') : date,
      time: !!time ? time?.format('HH:mm') : time
    };
    onSave(data);
  };

  const generateTitle = () => {
    if (type === 'notes') {
      return selectedElement?.id ? 'Edytuj notatkę' : 'Nowa notatka';
    } else {
      return selectedElement?.id ? 'Edytuj obserwację' : 'Nowa obserwacja';
    }
  };
  return (
    <DialogForm
      errors={errors}
      header={generateTitle()}
      maxWidth="md"
      onSave={handleSave}
      onCancel={() => handleCancel()}
      isDialogOpen={Ui.isEditing}
      isProcessing={Ui.isProcessing}
      onValidate={() => new NoteValidator().validate({ title: title, content: content, date: date, time: time })}
      onValidationDone={(errors) => setErrors(errors)}
      statePathToUi=""
      requiredFieldText>
      <Box sx={{ display: 'flex', flexDirection: 'column', my: 2 }}>
        <TextField
          sx={{ mb: 2 }}
          label="Podaj tytuł*"
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          defaultValue={title}
          error={errors?.title}
          helperText={errors?.title}
        />
        <Box sx={{ display: 'block' }}>
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mb: 2
            }}>
            <Grid item sx={{ mb: 2 }}>
              <DatePicker
                floatingLabelText="Data"
                onChange={(e, date) => setDate(date)}
                value={date}
                name="date"
                errorText={errors?.date}
              />
            </Grid>
            <Grid item sx={{ mb: 2 }}>
              <TimePicker
                floatingLabelText="Czas"
                onChange={(e, time) => setTime(time)}
                value={time}
                name="time"
                errorText={errors?.time}
              />
            </Grid>
          </Grid>
        </Box>
        <TextField
          multiline
          minRows={5}
          label="Podaj treść*"
          onChange={(e) => setContent(e.target.value)}
          fullWidth
          defaultValue={content}
          error={errors?.content}
          helperText={errors?.content}
        />
      </Box>
    </DialogForm>
  );
};

NotesAndObservationsDialog.propTypes = {
  selectedElement: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  Ui: PropTypes.object.isRequired
};

export default NotesAndObservationsDialog;
