import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventDetailsDialog from '../../eventsCalendar/dialogs/EventDetailsDialog';
import { browserHistory } from 'react-router';
import { fromTemplate } from '../../../routePaths';
import {
  addSpecialistGroupActivities,
  loadSpecialistGroulActivitiesPlan,
  setSpecialistGroupActivitiesDialogClosed,
  setSpecialistGroupActivitiesDialogOpen
} from '../../../actions/specialistGroupActivitiesActions';
import { SpecialistGroupActivitiesModel } from '../../../models/specialist/SpecialistGroupActivitiesModel';
import GroupActivitiesDialog from './dialogs/GroupActivitiesDialog';
import EventsCalendar from '../../eventsCalendar/calendar/EventsCalendar';
import { useIsSpecialist } from '../../../hooks/useIsSpecialist';
import { sortByDateTime } from '../utils/sortByDateTime';

const GroupClassesCalendar = ({ workId, routePath }) => {
  const [year, setYear] = useState(moment().year());
  const [month, setMonth] = useState(moment().month() + 1);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [eventDetailsDate, setEventDetailsDate] = useState(null);
  const [isEventDetailsDialogOpen, setIsEventDetailsDialogOpen] = useState(false);
  const daysOff = useSelector((state) => state.configuration.daysOff);
  const groupActivities = useSelector((state) => state.specialistNotes.groupActivities);
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.specialistNotesUi.groupActivities);
  const { isSpecialist } = useIsSpecialist();

  useEffect(() => {
    dispatch(loadSpecialistGroulActivitiesPlan(moment([year, month - 1]).format('YYYY-MM'), workId));
  }, [dispatch, month, workId, year]);

  const handleCalendarDateChange = (e) => {
    setYear(moment(e).year());
    setMonth(moment(e).month() + 1);
  };

  const getEventsAtDay = (date) => {
    return groupActivities.filter((x) => x.eventDate === date.format('YYYY-MM-DD'));
  };

  const handleDetailsOpen = (date) => {
    const events = getEventsAtDay(date.date);
    if (isSpecialist) {
      if (events.length) openDetailsForStaff(date, events);
      else {
        dispatch(setSpecialistGroupActivitiesDialogOpen());
        setCurrentEvent(
          new SpecialistGroupActivitiesModel().assign({
            eventDate: date.date || moment().format()
          })
        );
      }
    } else {
      if (events.length) openDetailsForStaff(date, events);
    }
  };

  const handleOpenCreateEvent = () => {
    if (eventDetailsDate) {
      dispatch(setSpecialistGroupActivitiesDialogOpen());
      setCurrentEvent(
        new SpecialistGroupActivitiesModel().assign({
          eventDate: eventDetailsDate.date || moment().format()
        })
      );
    }
  };

  const handleEventSave = (post) => {
    let data = post;
    data.group = workId;
    data.eventType = 'specialistGroupActivities';
    dispatch(addSpecialistGroupActivities(data));
  };

  const openDetailsForStaff = (date) => {
    setIsEventDetailsDialogOpen(true);
    setEventDetailsDate(date);
  };
  const handleEventCancel = () => {
    dispatch(setSpecialistGroupActivitiesDialogClosed());
    setCurrentEvent(null);
  };

  const handleDetailsClose = () => {
    setIsEventDetailsDialogOpen(false);
  };

  const handleShowEventFullDetails = () => {
    browserHistory.push(
      fromTemplate(routePath, [
        workId,
        eventDetailsDate.date.format('YYYY'),
        eventDetailsDate.date.format('MM'),
        eventDetailsDate.date.format('DD')
      ])
    );
  };

  const renderEventDetailsDialog = () => {
    return isEventDetailsDialogOpen ? (
      <EventDetailsDialog
        detailsOnly={!isSpecialist}
        isDialogOpen={isEventDetailsDialogOpen}
        onRequestClose={handleDetailsClose}
        header="Wpisy"
        events={sortByDateTime(getEventsAtDay(eventDetailsDate.date))}
        onShowEventFullDetails={handleShowEventFullDetails}
        onCreateNewEvent={handleOpenCreateEvent}
      />
    ) : null;
  };

  return (
    <Box>
      {ui.isEditing && (
        <GroupActivitiesDialog
          onSave={(post) => handleEventSave(post)}
          onCancel={(post) => handleEventCancel(post)}
          post={currentEvent}
          isEditing={ui.isEditing}
          isProcessing={ui.isProcessing}
          canSetDate={true}
        />
      )}
      <EventsCalendar
        sx={{ px: { xs: 0, sm: 3 } }}
        eventDays={groupActivities}
        daysOff={daysOff}
        onDateChange={handleCalendarDateChange}
        onDaySelected={(date) => handleDetailsOpen(date)}
        date={moment(new Date(year, month - 1, 1))}
        year={year.toString()}
        month={month.toString()}
      />
      {renderEventDetailsDialog()}
    </Box>
  );
};

export default GroupClassesCalendar;
