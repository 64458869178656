import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadChildGroupClassesAttendanceAsync,
  reportGroupAbsence,
  reportGroupAttendance
} from '../../../actions/psychologicalAttendanceActions';
import moment from 'moment';
import { changePsychologicalGroup } from '../../../actions/psychologicalChoosenGroupActions';
import { Box } from '@mui/system';
import AttendanceDetailsDialog from '../attendance/dialogs/AttendanceDetailsDialog';
import PsycholologicalGroupSwitch from '../../common/psychologicalGroups/PsycholologicalGroupSwitch';
import ChildAttendanceNoHoursCalendar from '../../common/children/attendance/calendar/ChildAttendanceNoHoursCalendar';

const ChildGroupPsychologicalAttendance = ({ childDetails, isInitiallyExpanded }) => {
  const dispatch = useDispatch();
  const daysOff = useSelector((state) => state.configuration.daysOff);
  const attendances = useSelector((state) => state.childPsychologicalGroupAttendances);
  const psychologicalGroups = useSelector((state) => state.psychologicalGroups);
  const Ui = useSelector((state) => state.childDetailsUi.groupAttendanceUi);
  const childGroups = useSelector((state) => state.childDetails.enrolledToPsychologicalPedagogicalGroup);
  const [selectedGroup, setSelectedGroup] = useState(childGroups[0]?.id);
  const [isDetailsOpen, setDetailsOpen] = useState(false);
  const [selectedDetailsDay, setSelectedDetailsDay] = useState();
  const [calendarDate, setCalendarDate] = useState();

  useEffect(() => {
    if (!selectedGroup) {
      setSelectedGroup(childGroups[0].id);
    }
  }, [childGroups, selectedGroup]);

  useEffect(() => {
    if (selectedGroup) {
      dispatch(
        loadChildGroupClassesAttendanceAsync(selectedGroup, childDetails.id, moment(calendarDate).format('YYYY-MM'))
      );
    }
  }, [attendances.length, calendarDate, childDetails.id, childGroups, dispatch, selectedGroup]);

  const handleGroupChange = (value) => {
    setSelectedGroup(value);
    dispatch(changePsychologicalGroup(psychologicalGroups.find((group) => group.id === value)));
  };
  const onDateChange = (date) => {
    setCalendarDate(date);
  };

  const handleDetailsOpen = (e) => {
    setSelectedDetailsDay(e);
    setDetailsOpen(true);
  };

  const handleReportAttendance = async (day) => {
    await dispatch(
      reportGroupAttendance({
        childId: childDetails.id,
        date: day.date,
        groupId: selectedGroup,
        droppedOfAt: day.date,
        pickedUpAt: day.date
      })
    );
  };

  const handleReportAbsence = async (day) => {
    await dispatch(
      reportGroupAbsence({
        groupId: selectedGroup,
        childId: childDetails.id,
        absenceFrom: day.date,
        absenceTo: day.date,
        absenceNotes: ''
      })
    );
  };

  return (
    <>
      <Accordion sx={{ p: 1, mt: 2 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
            Obecności na zajęciach grupowych
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <PsycholologicalGroupSwitch groups={childGroups} handleChange={handleGroupChange} value={selectedGroup} />
          </Box>
          <ChildAttendanceNoHoursCalendar
            noDescription
            allDays={attendances}
            markedDays={!!attendances.entries ? attendances.entries : []}
            attendanceSummary={!!attendances.summary ? attendances.summary : {}}
            daysOff={daysOff}
            onDateChange={(e) => onDateChange(e)}
            onDaySelected={() => {}}
            onReportAttendance={(day) => {
              handleReportAttendance(day);
            }}
            onReportAbsence={(day) => {
              handleReportAbsence(day);
            }}
            onDetailsOpen={(day) => handleDetailsOpen(day)}
            isLoading={Ui.isCalendarLoading}
            defaultMonth={moment(calendarDate).format('MM')}
            defaultYear={moment(calendarDate).format('YYYY')}
          />
        </AccordionDetails>
      </Accordion>
      <AttendanceDetailsDialog
        isPsychological
        isDialogOpen={isDetailsOpen}
        childDetails={childDetails}
        childAttendance={attendances?.entries?.find((day) => day?.date === selectedDetailsDay?.data?.date)}
        onRequestClose={() => setDetailsOpen(false)}
      />
    </>
  );
};

export default ChildGroupPsychologicalAttendance;
