/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';

export default function psychologicalChildrenListReducer(state = initialState.psychologicalChildrenList, action) {
  switch (action.type) {
    case types.LOAD_PSYCHOLOGICAL_HELP_LIST: {
      return action.payload;
    }
    case types.ARCHIVE_PSYCHOLOGICAL_HELP: {
      return state.filter((e) => e.id !== action.helpId);
    }

    case types.ADD_PSYCHOLOGICAL_HELP: {
      const preparedChildData = {
        IPETs: [],
        WOPFUs: [],
        childFirstName: action.child.child.firstName,
        childId: action.child.child.id,
        childLastName: action.child.child.lastName,
        from: action.child.from,
        to: action.child.to,
        lettersOfNotification: [],
        others: [],
        reason: action.child.cause,
        id: action.id.id
      };
      return [...state, preparedChildData];
    }
    case types.EDIT_PSYCHOLOGICAL_HELP: {
      let newState = Object.assign([], state);
      let editedHelp = newState.find((help) => help.id === action.data.id);

      editedHelp.reason = action.data.cause;
      editedHelp.from = action.data.from;
      editedHelp.to = action.data.to;
      return [...newState.filter((help) => help.id !== editedHelp.id), editedHelp];
    }

    default:
      return state;
  }
}
