/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import _ from 'lodash';
import { UiModel } from '../models/UiModel';

export default function specialistNotesUiReducer(state = initialState.specialistNotesUi, action) {
  switch (action.type) {
    //element list
    case types.MODIFY_SPECIALIST_NOTES_START:
    case types.CREATE_SPECIALIST_NOTES_START: {
      return { ...state, specialistWorksList: UiModel.start() };
    }
    case types.MODIFY_SPECIALIST_NOTES_CANCEL:
    case types.CREATE_SPECIALIST_NOTES_CANCEL: {
      return { ...state, specialistWorksList: UiModel.cancel() };
    }
    case types.MODIFY_SPECIALIST_NOTES_SUBMIT:
    case types.CREATE_SPECIALIST_NOTES_SUBMIT: {
      return { ...state, specialistWorksList: UiModel.submit() };
    }
    case types.MODIFY_SPECIALIST_NOTES_FAILURE:
    case types.CREATE_SPECIALIST_NOTES_FAILURE: {
      return { ...state, specialistWorksList: UiModel.failure(action.errors) };
    }
    case types.MODIFY_SPECIALIST_NOTES_SUCCESS:
    case types.CREATE_SPECIALIST_NOTES_SUCCESS: {
      return { ...state, specialistWorksList: UiModel.success() };
    }
    // case types.ADD_SPECIALIST_WORK_PLAN_ELEMENT: {
    //   return { ...state, specialistNotesList: UiModel.success() };
    // }
    // case types.EDIT_SPECIALIST_WORK_PLAN_ELEMENT: {
    //   return { ...state, specialistNotesList: UiModel.success() };
    // }
    // case types.DELETE_SPECIALIST_WORK_PLAN_ELEMENT: {
    //   return { ...state, specialistNotesList: UiModel.success() };
    // }

    //classSchedule
    case types.SPECIALIST_CLASS_SCHEDULE_START: {
      return { ...state, classSchedule: UiModel.start() };
    }
    case types.SPECIALIST_CLASS_SCHEDULE_CANCEL: {
      return { ...state, classSchedule: UiModel.cancel() };
    }
    case types.SPECIALIST_CLASS_SCHEDULE_SUBMIT: {
      return { ...state, classSchedule: UiModel.submit() };
    }
    case types.ADD_SPECIALIST_CLASS_SCHEDULE_ELEMENT: {
      return { ...state, classSchedule: UiModel.success() };
    }
    case types.EDIT_SPECIALIST_CLASS_SCHEDULE_ELEMENT: {
      return { ...state, classSchedule: UiModel.success() };
    }
    case types.DELETE_SPECIALIST_CLASS_SCHEDULE_ELEMENT: {
      return { ...state, classSchedule: UiModel.success() };
    }
    case types.SPECIALIST_CLASS_SCHEDULE_FAILURE: {
      return { ...state, classSchedule: UiModel.failure(action.errors) };
    }
    //workProgram
    case types.SPECIALIST_WORK_PLAN_START: {
      return { ...state, workPlan: UiModel.start() };
    }
    case types.SPECIALIST_WORK_PLAN_CANCEL: {
      return { ...state, workPlan: UiModel.cancel() };
    }
    case types.SPECIALIST_WORK_PLAN_SUBMIT: {
      return { ...state, workPlan: UiModel.submit() };
    }
    case types.ADD_SPECIALIST_WORK_PLAN_ELEMENT: {
      return { ...state, workPlan: UiModel.success() };
    }
    case types.EDIT_SPECIALIST_WORK_PLAN_ELEMENT: {
      return { ...state, workPlan: UiModel.success() };
    }
    case types.DELETE_SPECIALIST_WORK_PLAN_ELEMENT: {
      return { ...state, workPlan: UiModel.success() };
    }
    case types.SPECIALIST_WORK_PLAN_FAILURE: {
      return { ...state, workPlan: UiModel.failure(action.errors) };
    }
    //Contacts
    case types.ADD_CONTACT_START: {
      return { ...state, contacts: UiModel.start() };
    }
    case types.ADD_CONTACT_CANCEL: {
      return { ...state, contacts: UiModel.cancel() };
    }
    case types.ADD_CONTACT_SUBMIT: {
      return { ...state, contacts: UiModel.submit() };
    }
    case types.ADD_CONTACT_FAILURE: {
      return { ...state, contacts: UiModel.failure(action.errors) };
    }
    case types.ADD_CONTACT_TO_SPECIALIST_NOTES_SUCCESS:
    case types.EDIT_SPECIALIST_CONTACT:
    case types.ARCHIVE_SPECIALIST_CONTACT:
    case types.ADD_CONTACT_SUCCESS: {
      return { ...state, contacts: UiModel.success() };
    }
    //workPlanProgress
    case types.SPECIALIST_WORK_PLAN_PROGRESS_START: {
      return { ...state, workPlanProgress: UiModel.start() };
    }
    case types.SPECIALIST_WORK_PLAN_PROGRESS_CANCEL: {
      return { ...state, workPlanProgress: UiModel.cancel() };
    }
    case types.SPECIALIST_WORK_PLAN_PROGRESS_SUBMIT: {
      return { ...state, workPlanProgress: UiModel.submit() };
    }
    case types.ADD_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT: {
      return { ...state, workPlanProgress: UiModel.success() };
    }
    case types.EDIT_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT: {
      return { ...state, workPlanProgress: UiModel.success() };
    }
    case types.DELETE_SPECIALIST_WORK_PLAN_PROGRESS_ELEMENT: {
      return { ...state, workPlanProgress: UiModel.success() };
    }
    case types.SPECIALIST_WORK_PLAN_PROGRESS_FAILURE: {
      return { ...state, workPlanProgress: UiModel.failure(action.errors) };
    }
    //groupActivities
    case types.SPECIALIST_GROUP_ACTIVITIES_START: {
      return { ...state, groupActivities: UiModel.start() };
    }
    case types.SPECIALIST_GROUP_ACTIVITIES_CANCEL: {
      return { ...state, groupActivities: UiModel.cancel() };
    }
    case types.SPECIALIST_GROUP_ACTIVITIES_SUBMIT: {
      return { ...state, groupActivities: UiModel.submit() };
    }
    case types.ADD_SPECIALIST_GROUP_ACTIVITIES_ELEMENT: {
      return { ...state, groupActivities: UiModel.success() };
    }
    case types.EDIT_SPECIALIST_GROUP_ACTIVITIES_ELEMENT: {
      return { ...state, groupActivities: UiModel.success() };
    }
    case types.DELETE_SPECIALIST_GROUP_ACTIVITIES_ELEMENT: {
      return { ...state, groupActivities: UiModel.success() };
    }
    case types.SPECIALIST_GROUP_ACTIVITIES_FAILURE: {
      return { ...state, groupActivities: UiModel.failure(action.errors) };
    }
    //notes
    case types.SPECIALIST_NOTE_START: {
      return { ...state, notes: UiModel.start() };
    }
    case types.SPECIALIST_NOTE_CANCEL: {
      return { ...state, notes: UiModel.cancel() };
    }
    case types.SPECIALIST_NOTE_SUBMIT: {
      return { ...state, notes: UiModel.submit() };
    }
    case types.ADD_SPECIALIST_NOTE: {
      return { ...state, notes: UiModel.success() };
    }
    case types.EDIT_SPECIALIST_NOTE: {
      return { ...state, notes: UiModel.success() };
    }
    case types.DELETE_SPECIALIST_NOTE: {
      return { ...state, notes: UiModel.success() };
    }
    case types.SPECIALIST_NOTE_FAILURE: {
      return { ...state, notes: UiModel.failure(action.errors) };
    }
    //observations
    case types.SPECIALIST_OBSERVATION_START: {
      return { ...state, observations: UiModel.start() };
    }
    case types.SPECIALIST_OBSERVATION_CANCEL: {
      return { ...state, observations: UiModel.cancel() };
    }
    case types.SPECIALIST_OBSERVATION_SUBMIT: {
      return { ...state, observations: UiModel.submit() };
    }
    case types.ADD_SPECIALIST_OBSERVATION: {
      return { ...state, observations: UiModel.success() };
    }
    case types.EDIT_SPECIALIST_OBSERVATION: {
      return { ...state, observations: UiModel.success() };
    }
    case types.DELETE_SPECIALIST_OBSERVATION: {
      return { ...state, observations: UiModel.success() };
    }
    case types.SPECIALIST_OBSERVATION_FAILURE: {
      return { ...state, observations: UiModel.failure(action.errors) };
    }

    // case types.INDIVIDUAL_CLASSES_START: {
    //   return { ...state, individualClasses: UiModel.start() };
    // }
    // case types.INDIVIDUAL_CLASSES_CANCEL: {
    //   return { ...state, individualClasses: UiModel.cancel() };
    // }
    // case types.INDIVIDUAL_CLASSES_SUBMIT: {
    //   return { ...state, individualClasses: UiModel.submit() };
    // }
    // case types.ADD_INDIVIDUAL_CLASSES: {
    //   return { ...state, individualClasses: UiModel.success() };
    // }
    // case types.EDIT_INDIVIDUAL_CLASSES: {
    //   return { ...state, individualClasses: UiModel.success() };
    // }
    // case types.DELETE_INDIVIDUAL_CLASSES: {
    //   return { ...state, individualClasses: UiModel.success() };
    // }
    // case types.INDIVIDUAL_CLASSES_FAILURE: {
    //   return { ...state, individualClasses: UiModel.failure(action.errors) };
    // }

    default:
      return state;
  }
}
