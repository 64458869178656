import React from 'react';
import { Route, IndexRoute } from 'react-router';
import App from './components/App';
import MainApp from './components/MainApp';
import PinApp from './components/PinApp';
import Dashboard from './components/dashboard/Dashboard';
import GroupsPage from './components/groups/GroupsPage';
import GroupsArchivePage from './components/groupsArchive/GroupsArchivePage';
import GroupRegisterPage from './components/groupDetails/GroupRegisterPage';
import GroupDetailsPage from './components/groupDetails/GroupDetailsPage';
import GroupArchiveDetailsPage from './components/groupArchiveDetails/GroupArchiveDetailsPage';
import ActivitiesPage from './components/activities/ActivitiesPage';
import ActivitiesArchivePage from './components/activitiesArchive/ActivitiesArchivePage';
import ActivityRegisterPage from './components/activityDetails/ActivityRegisterPage';
import ActivityDetailsPage from './components/activityDetails/ActivityDetailsPage';
import ChildrenPage from './components/children/ChildrenPage';
import ChildrenArchivePage from './components/childrenArchvie/ChildrenArchivePage';
import ChildRegisterPage from './components/childDetails/ChildRegisterPage';
import ChildDetailsPage from './components/childDetails/ChildDetailsPage';
import ChildFinancialDetailsPage from './components/childDetails/ChildFinancialDetailsPage';
import StaffFinancialDetailsPage from './components/staffMemberDetails/chargingSchemes/StaffFinancialDetailsPage';
import ChildArchiveDetailsPage from './components/childrenArchiveDetails/ChildArchiveDetailsPage';
import StaffMembersPage from './components/staffMembers/StaffMembersPage';
import StaffMembersArchivePage from './components/staffMembersArchive/StaffMembersArchivePage';
import StaffMemberRegisterPage from './components/staffMemberDetails/StaffMemberRegisterPage';
import StaffMemberDetailsPage from './components/staffMemberDetails/StaffMemberDetailsPage';
import GuardianDuesPage from './components/guardianDues/GuardianDuesPage';
import GuardianDueDetailsPage from './components/guardianDues/details/GuardianDueDetailsPage';
import GuardianChildAttendancePage from './components/guardianChildAttendance/GuardianChildAttendancePage';
import GuardianChildDetailsPage from './components/guardianChildDetails/GuardianChildDetailsPage';
import SignInPage from './components/userAccount/signIn/SignInPage';
import AccountActivationPage from './components/userAccount/accountActivation/AccountActivationPage';
import PasswordRecoveryPage from './components/userAccount/passwordRecovery/PasswordRecoveryPage';
import PasswordExpiredPage from './components/userAccount/passwordExpired/PasswordExpiredPage';
import ResetPasswordPage from './components/userAccount/resetPassword/ResetPasswordPage';
import UserProfilePage from './components/userAccount/userProfile/UserProfilePage';
import MessageBoardPage from './components/messageBoard/MessageBoardPage';
import EventsCalendarPage from './components/eventsCalendar/EventsCalendarPage';
import CurrentAttendanceJournalPage from './components/attendanceSheet/CurrentAttendanceJournalPage';
import AttendanceJournalsHistoryPage from './components/attendanceSheet/AttendanceJournalsHistoryPage';
import PreviousAttendanceJournalPage from './components/attendanceSheet/PreviousAttendanceJournalPage';
import DaysOffPage from './components/configuration/daysOff/DaysOffPage';
import UnitPage from './components/configuration/unit/UnitPage';
import SettlementsSettingsPage from './components/configuration/settlementsSettings/SettlementsSettingsPage';
import ChargingSchemesPage from './components/configuration/chargingSchemes/ChargingSchemesPage';
import ReliefsPage from './components/configuration/reliefs/ReliefsPage';
import ChildrenSettlementsNavigationPage from './components/settlements/ChildrenSettlementsNavigationPage';
import DueDetailsPage from './components/settlements/details/DueDetailsPage';
import StaffSettlementsPage from './components/settlements/StaffSettlementsPage';
import LegalGuardiansPage from './components/legalGuardians/LegalGuardianPage';
import ReportsPage from './components/reports/ReportsPage';
import NumberingSchemesPage from './components/configuration/numberingSchemes/NumberingSchemesPage';
import ReportsConfigurationPage from './components/configuration/reports/ReportsConfigurationPage';
import AttributesSetsPage from './components/configuration/attributes/AttributesSetsPage';
import GuardiansPinServicePage from './components/configuration/guardiansPinService/GuardiansPinServicePage';
import DaycareConfigurationPage from './components/configuration/daycare/DaycareConfigurationPage';
import SenderMessagesPage from './components/privateMessages/SenderMessagesPage';
import GuardianMessagesPage from './components/privateMessages/GuardianMessagesPage';
import AllMessagesPage from './components/privateMessages/AllMessagesPage';
import PinSignInPage from './components/pins/PinSignInPage';
import KeypadPage from './components/pins/KeypadPage';
import AttendanceDashboardPage from './components/pins/AttendanceDashboardPage';
import DaycarePage from './components/daycare/DaycarePage';
import CustomCalendarPage from './components/digitalDiary/customCalendar/CustomCalendarPage';
import { routePaths } from './routePaths';
import DuesExportPage from './components/settlements/export/DuesExportPage';
import workPlanPage from './components/digitalDiary/workPlan/workPlanPage';
import DiagnosticSchemesSetsPage from './components/configuration/diagnosticSchemes/DiagnosticSchemesSetsPage';
import DigitalDiaryMessageBoardPage from './components/digitalDiary/digitalDiaryMessageBoardPage/DigitalDiaryMessageBoardPage';
import FrameworkDaySchedulePage from './components/digitalDiary/frameworkDaySchedule/FrameworkDaySchedulePage';
import EntrancesPage from './components/configuration/entrances/EntrancesPage';
import FkExport from './components/settlements/fkExport/DuesExportPage';
import SymphonyExport from './components/settlements/symphonyExport/DuesExportPage';
import GuardianApplicationsPage from './components/guardianApplications/GuardianApplicationsPage';
import ApplicationsPage from './components/applications/PrincipalApplicationsPage';
import EventsPage from './components/configuration/events/EventsPage';
import ChangeLogPage from './components/changeLog/ChangeLogPage';
import AuthorizedBySignPage from './components/common/authorizedBySign/AuthorizedBySign';
import LoginHistoryPage from './components/configuration/loginHistory/LoginHistoryPage';
import CurrentUsersPage from './components/configuration/currentUsers/CurrentUsersPage';
import ExpiringReliefsPage from './components/settlements/expiringReliefs/ExpiringReliefsPage';
import OverdueListPage from './components/settlements/overdueList/OverdueListPage';
import OverpaymentsListPage from './components/settlements/overpaymentsList/OverpaymentsListPage';
import LoginGovPage from './components/common/loginGov/LoginGovPage';
import withHelmet from './hoc/withHelmet';
import metadata from './metadata';
import ChildDetailsSummary from './components/childDetails/ChildDetailsSummary';
import GalleryPage from './components/gallery/GalleryPage';
import StatuteChangedPage from './components/statuteChanged/StatuteChangedPage';
import ContextManualPage from './components/ContextManualPage';
import StaffCalendarPage from './components/StaffCalendar/StaffCalendarPage';
import StaffCalendarDetailPage from './components/staffCalendarDetails/StaffCalendarDetailPage';
import StaffMessageBoardPage from './components/staffMessageBoard/StaffMessageBoardPage';
import ScheduledTransferPage from './components/scheduledTransfer/ScheduledTransferPage';
import VirtualDiscPage from './components/virtualDisc/VirtualDiscPage';
import AuthorizedToPickUpChildPage from './components/authorizedToPickUpChild/AuthorizedToPickUpChildPage';
import MobileDownloadPage from './components/mobileDownload/MobileDownloadPage';
import ImportPage from './components/import/ImportPage';
import activitiesAttendanceSheetPage from './components/activitiesAttendanceSheet/ActivitiesAttendanceSheetPage';
import SettlementsImport from './components/settlements/import/SettlementsImport';
import { featuresFromPrefixValidator } from './utils/featuresFromPrefixValidator';
import ReducedReportsPage from './components/reports/ReducedReportsPage';
import StudentPage from './components/student/StudentPage';
import StudentRegisterPage from './components/studentDetails/StudentRegisterPage';
import StudentDetailsPage from './components/studentDetails/StudentDetailsPage';
import StudentArchivePage from './components/student/studentArchive/StudentArchivePage';
import ReducedUnitPage from './components/configuration/unit/ReducedUnitPage';
import AbsenceTomorrowPage from './components/attendanceSheet/AbsenceTomorrowPage';
import MealsPage from './components/meals/MealsPage';
import { MealRegisterPage } from './components/meals/MealRegisterPage';
import { MealDetailsPage } from './components/meals/MealDetailsPage';
import ArchivedMealsPage from './components/meals/ArchivedMealsPage';
import { DietsPage } from './components/meals/DietsPage';
import { CreateDietPage } from './components/meals/CreateDietPage';
import { DietDetailsPage } from './components/meals/DietDetailsPage';
import PsychologicalSchemesPage from './components/psychologicalSchemes/PsychologicalSchemesPage';
import PsychologicalHelpChildPage from './components/PsychologicalHelpPage/PsychologicalHelpChildPage';
import PsychologicalHelpPage from './components/PsychologicalHelpPage/PsychologicalHelpPage';
import IndividualClassesPage from './components/individualPsychologicalClasses/IndividualClassesPage';
import IndividualClassesDetailsPage from './components/individualClassesDetails/IndividualClassesDetailsPage';

import PsychologicalGroupDetailsPage from './components/psychologicalGroups/PsychologicalGroupDetailsPage';
import PsychologicalGroupsPage from './components/psychologicalGroups/PsychologicalGroupsPage';

import PsychologicalGroupClassesPage from './components/psychologicalGroupClasses/PsychologicalGroupClassesPage';
import IndividualClassesEventsPage from './components/individualPsychologicalClasses/IndividualClassesEventsPage';
import GroupClassesEventsPage from './components/psychologicalGroupClasses/GroupClassesEventsPage';
import GroupDiaryEventsPage from './components/psychologicalGroupClasses/GroupDiaryEventsPage';
import TakenOverByDebtCollectionPage from './components/settlements/takenOverByDebtCollection/TakenOverByDebtCollectionPage';
import ChildrenOnVacationPeriodPage from './components/settlements/childrenOnVacationPeriod/ChildrenOnVacationPeriodPage';
import PosterDownloadPage from './components/posterDownloadPage/posterDownloadPage';
// import PsychologicalHelpPage from './components/psychologicalHelpPage/PsychologicalHelpPage';
// import individualClassesPage from './components/common/individualClasses/individualClassesPage';
// import IndividualClassesChildPage from './components/common/individualClasses/IndividualClassesChildPage';
// import GroupClassesPage from './components/common/GroupClasses/groupClassesPage';
// import PsychologicalHelpChildPage from './components/psychologicalHelpPage/PsychologicalHelpChildPage';
// import PsychologicalSchemesPage from './components/psychologicalSchemes/PsychologicalSchemesPage';
import SpecialistWorkNotesPage from './components/specialistNotes/SpecialistWorkNotesWrapper';
import SpecialistGroupActivitiesPageDetails from './components/specialistNotes/SpecialistGroupActivitiesDetails';
import SpecialistClassSchedulePageDetails from './components/specialistNotes/SpecialistClassSchedulePageDetails';
import SpecialistWorkPlanProgressPageDetails from './components/specialistNotes/SpecialistWorkPlanProgressPageDetails';
import SpecialistWorkNotesDetailsPage from './components/specialistNotes/SpecialistWorkNotesDetailsPage';
import IndividualClassesJournalPage from './components/individualClassesJournal/IndividualClassesJournalPage';
import IndividualClassesJournalDetailsPage from './components/individualClassesJournal/IndividualClassesJournalDetailsPage';
import IndividualJournalClassesEventsPage from './components/individualClassesJournal/IndividualJournalClassesEventsPage';

const routes = () => {
  const features = featuresFromPrefixValidator();
  const nadarzyn = features ? features.nadarzyn : false;
  return (
    <Route path={routePaths.home} component={withHelmet(App, metadata.home)}>
      <Route path={routePaths.signIn} component={withHelmet(SignInPage, metadata.signIn)} />
      <Route
        path={routePaths.accountActivation}
        component={withHelmet(AccountActivationPage, metadata.accountActivation)}
      />
      <Route path={routePaths.passwordExpired} component={withHelmet(PasswordExpiredPage, metadata.passwordExpired)} />
      <Route
        path={routePaths.passwordRecovery}
        component={withHelmet(PasswordRecoveryPage, metadata.passwordRecovery)}
      />
      <Route path={routePaths.passwordReset} component={withHelmet(ResetPasswordPage, metadata.passwordReset)} />
      <Route path={routePaths.loginGov} component={withHelmet(LoginGovPage, metadata.loginGov)} />
      <Route path={routePaths.statuteChanged} component={withHelmet(StatuteChangedPage, metadata.statuteChanged)} />
      <Route path={routePaths.userManual} component={withHelmet(ContextManualPage, metadata.userManual)} />
      <Route component={MainApp}>
        <IndexRoute component={withHelmet(Dashboard, metadata.indexRoute)} />
        <Route path={routePaths.posterDownload} component={withHelmet(PosterDownloadPage, metadata.downloadPoster)} />
        <Route path={routePaths.userProfile} component={withHelmet(UserProfilePage, metadata.userProfile)} />
        <Route path={routePaths.groups} component={withHelmet(GroupsPage, metadata.groups)} />
        <Route path={routePaths.groupsArchive} component={withHelmet(GroupsArchivePage, metadata.groupsArchive)} />
        <Route path={routePaths.groupRegister} component={withHelmet(GroupRegisterPage, metadata.groupRegister)} />
        <Route path={routePaths.groupDetails} component={withHelmet(GroupDetailsPage, metadata.groupDetails)} />
        <Route
          path={routePaths.groupArchiveDetails}
          component={withHelmet(GroupArchiveDetailsPage, metadata.groupArchiveDetails)}
        />
        <Route path={routePaths.activities} component={withHelmet(ActivitiesPage, metadata.activities)} />
        <Route
          path={routePaths.activitiesArchive}
          component={withHelmet(ActivitiesArchivePage, metadata.activitiesArchive)}
        />
        <Route
          path={routePaths.activityRegister}
          component={withHelmet(ActivityRegisterPage, metadata.activityRegister)}
        />
        <Route
          path={routePaths.activityDetails}
          component={withHelmet(ActivityDetailsPage, metadata.activityDetails)}
        />
        <Route path={routePaths.children} component={withHelmet(ChildrenPage, metadata.children)} />
        <Route path={routePaths.student} component={withHelmet(StudentPage, metadata.student)} />
        <Route
          path={routePaths.childrenArchive}
          component={withHelmet(ChildrenArchivePage, metadata.childrenArchive)}
        />
        <Route path={routePaths.studentArchive} component={withHelmet(StudentArchivePage, metadata.studentArchive)} />
        <Route
          path={routePaths.studentRegister}
          component={withHelmet(StudentRegisterPage, metadata.studentRegister)}
        />
        <Route path={routePaths.studentDetails} component={withHelmet(StudentDetailsPage, metadata.studentDetails)} />
        <Route path={routePaths.childRegister} component={withHelmet(ChildRegisterPage, metadata.childRegister)} />
        <Route path={routePaths.childDetails} component={withHelmet(ChildDetailsPage, metadata.childDetails)} />
        <Route
          path={routePaths.childFinancialDetails}
          component={withHelmet(ChildFinancialDetailsPage, metadata.childFinancialDetails)}
        />
        <Route
          path={routePaths.staffFinancialDetails}
          component={withHelmet(StaffFinancialDetailsPage, metadata.staffFinancialDetails)}
        />
        <Route path={routePaths.childSummary} component={withHelmet(ChildDetailsSummary, metadata.childSummary)} />
        <Route
          path={routePaths.childArchiveDetails}
          component={withHelmet(ChildArchiveDetailsPage, metadata.childArchiveDetails)}
        />
        <Route path={routePaths.staffMembers} component={withHelmet(StaffMembersPage, metadata.staffMembers)} />
        <Route
          path={routePaths.staffMembersArchive}
          component={withHelmet(StaffMembersArchivePage, metadata.staffMembersArchive)}
        />
        <Route
          path={routePaths.staffMemberRegister}
          component={withHelmet(StaffMemberRegisterPage, metadata.staffMemberRegister)}
        />
        <Route
          path={routePaths.staffMemberDetails}
          component={withHelmet(StaffMemberDetailsPage, metadata.staffMemberDetails)}
        />
        <Route path={routePaths.messageBoard} component={withHelmet(MessageBoardPage, metadata.messageBoard)} />
        <Route
          path={routePaths.messageBoardEvents}
          component={withHelmet(MessageBoardPage, metadata.messageBoardEvents)}
        />
        <Route path={routePaths.eventsCalendar} component={withHelmet(EventsCalendarPage, metadata.eventsCalendar)} />
        <Route path={routePaths.attendance} component={withHelmet(CurrentAttendanceJournalPage, metadata.attendance)} />
        <Route
          path={routePaths.attendanceMonthlyHistory}
          component={withHelmet(AttendanceJournalsHistoryPage, metadata.attendanceMonthlyHistory)}
        />
        <Route path={routePaths.absentTomorrow} component={withHelmet(AbsenceTomorrowPage, metadata.absentTomorrow)} />
        <Route
          path={routePaths.attendanceDailyHistory}
          component={withHelmet(PreviousAttendanceJournalPage, metadata.attendanceDailyHistory)}
        />
        <Route
          path={routePaths.activitiesAttendanceSheetPage}
          component={withHelmet(activitiesAttendanceSheetPage, metadata.activitiesAttendanceSheetPage)}
        />
        <Route
          path={routePaths.returnFromOnlinePayments}
          component={withHelmet(GuardianDuesPage, metadata.returnFromOnlinePayments)}
        />
        <Route path={routePaths.guardianDues} component={withHelmet(GuardianDuesPage, metadata.guardianDues)} />
        <Route
          path={routePaths.guardianDueDetails}
          component={withHelmet(GuardianDueDetailsPage, metadata.guardianDueDetails)}
        />
        <Route
          path={routePaths.guardianChildAttendance}
          component={withHelmet(GuardianChildAttendancePage, metadata.guardianChildAttendance)}
        />
        <Route
          path={routePaths.guardianChildDetails}
          component={withHelmet(GuardianChildDetailsPage, metadata.guardianChildDetails)}
        />
        <Route
          path={routePaths.configurationUnit}
          component={withHelmet(nadarzyn ? ReducedUnitPage : UnitPage, metadata.configurationUnit)}
        />
        <Route
          path={routePaths.configurationSettlementsSettings}
          component={withHelmet(SettlementsSettingsPage, metadata.configurationSettlementsSettings)}
        />
        <Route
          path={routePaths.configurationDaysOff}
          component={withHelmet(DaysOffPage, metadata.configurationDaysOff)}
        />
        <Route
          path={routePaths.configurationChargingSchemes}
          component={withHelmet(ChargingSchemesPage, metadata.configurationChargingSchemes)}
        />
        <Route
          path={routePaths.configurationReliefs}
          component={withHelmet(ReliefsPage, metadata.configurationReliefs)}
        />
        <Route path={routePaths.settlementsExport} component={withHelmet(DuesExportPage, metadata.settlementsExport)} />
        <Route path={routePaths.fkExport} component={withHelmet(FkExport, metadata.fkExport)} />
        <Route path={routePaths.symphonyExport} component={withHelmet(SymphonyExport, metadata.symphonyExport)} />
        <Route
          path={routePaths.settlementsImport}
          component={withHelmet(SettlementsImport, metadata.settlementsImport)}
        />
        <Route
          path={routePaths.electronicDiaryWorkPlan}
          components={withHelmet(workPlanPage, metadata.electronicDiaryWorkPlan)}
        />
        <Route
          path={routePaths.settlements}
          component={withHelmet(ChildrenSettlementsNavigationPage, metadata.settlements)}>
          <Route path={routePaths.settlementsGroup} />
          <Route path={routePaths.settlementsGroupDetails} />
        </Route>
        <Route path={routePaths.childDueDetails} component={withHelmet(DueDetailsPage, metadata.childDueDetails)} />
        <Route path={routePaths.events} component={withHelmet(EventsPage, metadata.events)} />
        <Route
          path={routePaths.staffMembersSettlements}
          component={withHelmet(StaffSettlementsPage, metadata.staffMembersSettlements)}
        />
        <Route path={routePaths.legalGuardians} component={withHelmet(LegalGuardiansPage, metadata.legalGuardians)} />
        <Route
          path={routePaths.reports}
          component={withHelmet(nadarzyn ? ReducedReportsPage : ReportsPage, metadata.reports)}
        />
        <Route
          path={routePaths.configurationNumberingSchemes}
          component={withHelmet(NumberingSchemesPage, metadata.configurationNumberingSchemes)}
        />
        <Route
          path={routePaths.configurationReports}
          component={withHelmet(ReportsConfigurationPage, metadata.configurationReports)}
        />
        <Route
          path={routePaths.configurationAttributesSets}
          component={withHelmet(AttributesSetsPage, metadata.configurationAttributesSets)}
        />
        <Route
          path={routePaths.configurationGuardiansPinService}
          component={withHelmet(GuardiansPinServicePage, metadata.configurationGuardiansPinService)}
        />
        <Route
          path={routePaths.configurationDaycare}
          component={withHelmet(DaycareConfigurationPage, metadata.configurationDaycare)}
        />
        <Route
          path={routePaths.staffMemberPrivateMessages}
          component={withHelmet(SenderMessagesPage, metadata.staffMemberPrivateMessages)}
        />
        <Route
          path={routePaths.guardianPrivateMessages}
          component={withHelmet(GuardianMessagesPage, metadata.guardianPrivateMessages)}
        />
        <Route path={routePaths.privateMessages} component={withHelmet(AllMessagesPage, metadata.privateMessages)} />
        <Route path={routePaths.daycare} component={withHelmet(DaycarePage, metadata.daycare)} />
        <Route
          path={routePaths.electronicDiaryTimeTable}
          component={withHelmet(CustomCalendarPage, metadata.electronicDiaryTimeTable)}
        />
        <Route
          path={routePaths.electronicDiarySaveWork}
          component={withHelmet(CustomCalendarPage, metadata.electronicDiarySaveWork)}
        />
        <Route
          path={routePaths.electronicDiaryTrip}
          component={withHelmet(CustomCalendarPage, metadata.electronicDiaryTrip)}
        />
        <Route
          path={routePaths.electronicDiaryAdditionalActivities}
          component={withHelmet(CustomCalendarPage, metadata.electronicDiaryAdditionalActivities)}
        />
        <Route
          path={routePaths.electronicDiaryWorkWithParents}
          component={withHelmet(CustomCalendarPage, metadata.electronicDiaryWorkWithParents)}
        />
        <Route
          path={routePaths.electronicDiaryMessageBoard}
          component={withHelmet(DigitalDiaryMessageBoardPage, metadata.electronicDiaryMessageBoard)}
        />
        <Route
          path={routePaths.electronicDiaryFrameworkDaySchedule}
          component={withHelmet(FrameworkDaySchedulePage, metadata.electronicDiaryFrameworkDaySchedule)}
        />
        <Route
          path={routePaths.configurationDiagnosisTemplate}
          component={withHelmet(DiagnosticSchemesSetsPage, metadata.configurationDiagnosisTemplate)}
        />
        <Route path={routePaths.entrances} component={withHelmet(EntrancesPage, metadata.entrances)} />
        <Route
          path={routePaths.guardianApplications}
          component={withHelmet(GuardianApplicationsPage, metadata.guardianApplications)}
        />
        <Route path={routePaths.applications} component={withHelmet(ApplicationsPage, metadata.applications)} />
        <Route path={routePaths.changeLog} component={withHelmet(ChangeLogPage, metadata.changeLog)} />
        <Route
          path={routePaths.authorizedBySign}
          component={withHelmet(AuthorizedBySignPage, metadata.authorizedBySign)}
        />
        <Route path={routePaths.loginHistory} component={withHelmet(LoginHistoryPage, metadata.loginHistory)} />
        <Route path={routePaths.currentUsers} component={withHelmet(CurrentUsersPage, metadata.currentUsers)} />
        <Route path={routePaths.overdueList} component={withHelmet(OverdueListPage, metadata.overdueList)} />
        <Route
          path={routePaths.overpaymentsList}
          component={withHelmet(OverpaymentsListPage, metadata.overpaymentsList)}
        />
        <Route
          path={routePaths.childrenOnVacationPeriod}
          component={withHelmet(ChildrenOnVacationPeriodPage, metadata.childrenOnVacationPeriod)}
        />
        <Route
          path={routePaths.psychologicalSchemes}
          component={withHelmet(PsychologicalSchemesPage, metadata.psychologicalSchemes)}
        />
        <Route
          path={routePaths.psychologicalHelpChild}
          component={withHelmet(PsychologicalHelpChildPage, metadata.psychologicalHelp)}
        />

        <Route
          path={routePaths.psychologicalHelp}
          component={withHelmet(PsychologicalHelpPage, metadata.psychologicalHelp)}
        />
        <Route
          path={routePaths.individualClasses}
          component={withHelmet(IndividualClassesPage, metadata.individualClasses)}
        />

        <Route
          path={routePaths.individualClassesDetails}
          component={withHelmet(IndividualClassesDetailsPage, metadata.individualClassesDetails)}
        />
        <Route
          path={routePaths.individualClassesEventsDetails}
          component={withHelmet(IndividualClassesEventsPage, metadata.individualClassesDetails)}
        />

        <Route
          path={routePaths.individualClassesJournal}
          component={withHelmet(IndividualClassesJournalPage, metadata.individualClassesJournal)}
        />
        <Route
          path={routePaths.individualClassesJournalDetails}
          component={withHelmet(IndividualClassesJournalDetailsPage, metadata.individualClassesJournalDetails)}
        />
        <Route
          path={routePaths.individualClassesJournalEventDetails}
          component={withHelmet(IndividualJournalClassesEventsPage, metadata.individualClassesJournalEventDetails)}
        />

        <Route
          path={routePaths.groupClassesEventsDetails}
          component={withHelmet(GroupClassesEventsPage, metadata.groupClasses)}
        />
        <Route
          path={routePaths.groupDiaryEventsDetails}
          component={withHelmet(GroupDiaryEventsPage, metadata.groupClasses)}
        />
        <Route
          path={routePaths.groupClasses}
          component={withHelmet(PsychologicalGroupClassesPage, metadata.groupClasses)}
        />
        <Route
          path={routePaths.psychologicalGroups}
          component={withHelmet(PsychologicalGroupsPage, metadata.psychologicalGroups)}
        />
        <Route
          path={routePaths.psychologicalGroupDetails}
          component={withHelmet(PsychologicalGroupDetailsPage, metadata.psychologicalGroups)}
        />
        <Route
          path={routePaths.specialistNotes}
          component={withHelmet(SpecialistWorkNotesPage, metadata.specialistNotes)}
        />
        <Route
          path={routePaths.specialistNotesDetails}
          component={withHelmet(SpecialistWorkNotesDetailsPage, metadata.specialistNotes)}
        />
        <Route
          path={routePaths.specialistClassScheduleDetails}
          component={withHelmet(SpecialistClassSchedulePageDetails, metadata.specialistNotes)}
        />
        <Route
          path={routePaths.specialistGroupActivitiesDetails}
          component={withHelmet(SpecialistGroupActivitiesPageDetails, metadata.specialistNotes)}
        />
        <Route
          path={routePaths.specialistWorkPlanProgressDetails}
          component={withHelmet(SpecialistWorkPlanProgressPageDetails, metadata.specialistNotes)}
        />
        <Route
          path={routePaths.expiringReliefs}
          component={withHelmet(ExpiringReliefsPage, metadata.expiringReliefs)}
        />
        <Route
          path={routePaths.takenOverByDebtCollection}
          component={withHelmet(TakenOverByDebtCollectionPage, metadata.takenOverByDebtCollection)}
        />
        <Route path={routePaths.gallery} component={withHelmet(GalleryPage, metadata.gallery)} />
        <Route path={routePaths.staffCalendar} component={withHelmet(StaffCalendarPage, metadata.staffCalendar)} />
        <Route
          path={routePaths.staffCalendarDetails}
          component={withHelmet(StaffCalendarDetailPage, metadata.staffCalendar)}
        />
        <Route
          path={routePaths.staffMessageBoard}
          component={withHelmet(StaffMessageBoardPage, metadata.staffMessageBoard)}
        />
        <Route
          path={routePaths.scheduledTransfer}
          component={withHelmet(ScheduledTransferPage, metadata.scheduledTransfer)}
        />
        <Route path={routePaths.virtualDisc} component={withHelmet(VirtualDiscPage, metadata.virtualDisc)} />
        <Route
          path={routePaths.authorizedToPickUpChild}
          component={withHelmet(AuthorizedToPickUpChildPage, metadata.authorizedToPickUpChild)}
        />
        <Route path={routePaths.mobileDownload} component={withHelmet(MobileDownloadPage, metadata.mobileDownload)} />
        <Route path={routePaths.childrenImport} component={withHelmet(ImportPage, metadata.childrenImport)} />
        <Route path={routePaths.meals} component={withHelmet(MealsPage, metadata.meals)} />
        <Route path={routePaths.mealRegister} component={withHelmet(MealRegisterPage, metadata.mealRegister)} />
        <Route path={routePaths.mealsArchive} component={withHelmet(ArchivedMealsPage, metadata.mealsArchive)} />
        <Route path={routePaths.mealDetails} component={withHelmet(MealDetailsPage, metadata.mealDetails)} />
        <Route path={routePaths.diets} component={withHelmet(DietsPage, metadata.diets)} />
        <Route path={routePaths.createDiet} component={withHelmet(CreateDietPage, metadata.createDiet)} />
        <Route path={routePaths.dietDetails} component={withHelmet(DietDetailsPage, metadata.dietDetails)} />
      </Route>
      <Route component={withHelmet(PinApp, metadata.pinPage)}>
        <Route path={routePaths.pinSignIn} component={withHelmet(PinSignInPage, metadata.pinSignIn)} />
        <Route path={routePaths.pinKeypad} component={withHelmet(KeypadPage, metadata.pinKeypad)} />
        <Route path={routePaths.pinEntries} component={withHelmet(AttendanceDashboardPage, metadata.pinEntries)} />
      </Route>
      {/* <Route
        path={routePaths.psychologicalHelpChild}
        component={withHelmet(PsychologicalHelpChildPage, metadata.psychologicalHelp)}
      />


      <Route
        path={routePaths.psychologicalSchemes}
        component={withHelmet(PsychologicalSchemesPage, metadata.psychologicalSchemes)}
      />



      <Route path={routePaths.groupClasses} component={withHelmet(GroupClassesPage, metadata.groupClasses)} /> */}
    </Route>
  );
};

export default routes();
