/*eslint indent:0*/
import initialState from './initial';
import { ChildPersonalBalanceModel } from '../models/children/ChildModels';
import { LOAD_CHILD_PERSONAL_BALANCE_SUCCESS } from '../actions/actionTypes';

export default function childPersonalBalanceReducer(state = initialState.childPersonalBalance, action) {
  switch (action.type) {
    case LOAD_CHILD_PERSONAL_BALANCE_SUCCESS: {
      const personalBalance = new ChildPersonalBalanceModel().assign(action.personalBalance);
      return personalBalance.assign(action.childDetails);
    }
    default:
      return state;
  }
}
