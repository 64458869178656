import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useIsSpecialist } from '../../hooks/useIsSpecialist';
import { fromTemplate, routePaths } from '../../routePaths';
import ClassesCalendar from '../individualClassesDetails/ClassesCalendar';
import ChildDetailsHeader from '../childDetails/header/ChildDetailsHeader';
import ContentWrapper from '../common/contentWrapper';
import {
  addIndividualJournalClasses,
  loadIndividualJournalClassesWorkPlan,
  setIndividualJournalClassesDialogClosed,
  setIndividualJournalClassesDialogOpen
} from '../../actions/psychologicalIndividualJournalClassesActions';
import IndividualJournalProgram from './components/IndividualJournalProgram';
import IndividualJournalNotesList from './components/notes/IndividualJournalNotes';
import IndividualJournalObservationsList from './components/observations/IndividualJournalObservationsList';
import { browserHistory } from 'react-router';

const IndividualClassesJournalDetailsPage = ({ params }) => {
  const dispatch = useDispatch();
  const lessons = useSelector((state) => state.psychologicalIndividualJournalWorkProgram.posts);
  const schemesArray = useSelector((state) => state.psychologicalSchemes);
  const ui = useSelector((state) => state.psychologicalIndividualJournalUi.individualClasses);
  const childData = useSelector((state) => state.childDetails);
  const [date, setDate] = useState(moment());
  const { isSpecialistOrPrincipal } = useIsSpecialist();
  const { childId, journalId } = params;

  const handleDateChange = (value) => {
    setDate(value);
  };
  const handleOpen = () => {
    dispatch(setIndividualJournalClassesDialogOpen());
  };
  const handleCancel = () => {
    dispatch(setIndividualJournalClassesDialogClosed());
  };

  const handleSave = (event) => {
    let data = event;
    data.group = journalId;
    data.eventType = 'psychologicalPedagogicalIndividualJournalClassesTimetable';
    dispatch(addIndividualJournalClasses(data));
    dispatch(setIndividualJournalClassesDialogClosed());
  };

  useEffect(() => {
    dispatch(loadIndividualJournalClassesWorkPlan(moment(date).format('YYYY-MM'), childId, journalId));
  }, [date, dispatch, childId, journalId]);

  const handleCustomRedirect = (eventDetailsDate) => {
    browserHistory.replace(
      fromTemplate(routePaths.individualClassesJournalEventDetails, [
        journalId,
        childId,
        eventDetailsDate.date.format('YYYY'),
        eventDetailsDate.date.format('MM'),
        eventDetailsDate.date.format('DD')
      ])
    );
  };

  return (
    <Box>
      <PageHeaderText title="Rozkład zajęć" titleIcon={<CalendarMonthIcon />} />

      <ContentWrapper>
        <ChildDetailsHeader
          photoChangeDisabled
          onPhotoDrop={() => {}}
          onPhotoRemove={() => {}}
          name={`${childData.firstName} ${childData.lastName}`}
          photoUrl={childData.photoUrl}
        />
        <ClassesCalendar
          handleRedirect={(eventDetailsDate) => {
            handleCustomRedirect(eventDetailsDate);
          }}
          handleDateChange={handleDateChange}
          helpId={childId}
          lessons={lessons}
          onSave={(e) => handleSave(e)}
          schemesArray={schemesArray.individualSchedules}
          ui={ui}
          onOpen={handleOpen}
          onCancel={handleCancel}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <IndividualJournalProgram />
          {isSpecialistOrPrincipal && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <IndividualJournalNotesList />
              <IndividualJournalObservationsList />
            </Box>
          )}
        </Box>
      </ContentWrapper>
    </Box>
  );
};

export default IndividualClassesJournalDetailsPage;
