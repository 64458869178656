import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';

import {
  loadChildIndividualClassesAttendanceAsync,
  reportAbsence,
  reportAttendance
} from '../../../actions/psychologicalAttendanceActions';
import moment from 'moment';
import AttendanceDetailsDialog from '../attendance/dialogs/AttendanceDetailsDialog';
import ChildAttendanceNoHoursCalendar from '../../common/children/attendance/calendar/ChildAttendanceNoHoursCalendar';

const ChildIndividualPsychologicalAttendance = ({ childDetails, isInitiallyExpanded }) => {
  const dispatch = useDispatch();
  const daysOff = useSelector((state) => state.configuration.daysOff);
  const attendances = useSelector((state) => state.childPsychologicalIndividualAttendances);
  const Ui = useSelector((state) => state.childDetailsUi.individualAttendanceUi);
  const [isDetailsOpen, setDetailsOpen] = useState(false);
  const [selectedDetailsDay, setSelectedDetailsDay] = useState();
  const [calendarDate, setCalendarDate] = useState();

  useEffect(() => {
    dispatch(loadChildIndividualClassesAttendanceAsync(childDetails.id, moment().format('YYYY-MM')));
  }, [childDetails.id, dispatch]);

  const onDateChange = (date) => {
    setCalendarDate(date);
  };

  const handleDetailsOpen = (e) => {
    setSelectedDetailsDay(e);
    setDetailsOpen(true);
  };

  const handleReportAttendance = async (day) => {
    await dispatch(
      reportAttendance({
        childId: childDetails.id,
        date: day.date,
        droppedOfAt: day.date,
        pickedUpAt: day.date
      })
    );
  };

  const handleReportAbsence = async (day) => {
    await dispatch(
      reportAbsence({
        childId: childDetails.id,
        absenceFrom: day.date,
        absenceTo: day.date,
        absenceNotes: ''
      })
    );
  };

  return (
    <>
      <Accordion sx={{ p: 1 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1" sx={{ fontWeight: (theme) => theme.typography.fontWeightBold }}>
            Obecności na zajęciach indywidualnych
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ChildAttendanceNoHoursCalendar
            noDescription
            allDays={attendances}
            markedDays={!!attendances.entries ? attendances.entries : []}
            attendanceSummary={!!attendances.summary ? attendances.summary : {}}
            daysOff={daysOff}
            onDateChange={(e) => onDateChange(e)}
            onDaySelected={() => {}}
            onReportAttendance={(day) => {
              handleReportAttendance(day);
            }}
            onReportAbsence={(day) => {
              handleReportAbsence(day);
            }}
            onDetailsOpen={(day) => handleDetailsOpen(day)}
            isLoading={Ui.isCalendarLoading}
            defaultMonth={moment(calendarDate).format('MM')}
            defaultYear={moment(calendarDate).format('YYYY')}
          />
        </AccordionDetails>
      </Accordion>
      <AttendanceDetailsDialog
        isPsychological
        isDialogOpen={isDetailsOpen}
        childDetails={childDetails}
        childAttendance={attendances?.entries?.find((day) => day?.date === selectedDetailsDay?.data?.date)}
        onRequestClose={() => setDetailsOpen(false)}
      />
    </>
  );
};

export default ChildIndividualPsychologicalAttendance;
