import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import StarIcon from '@mui/icons-material/Star';
import { Box, Typography } from '@mui/material';
import PostUpdateInfo from '../../../messageBoard/post/PostUpdateInfo';
import ErrorAlertBox from '../../../common/ErrorAlertBox';
import { TimeModel } from '../../../../models/TimeModel';

const PostHeader = ({ post, sx, noAvatar }) => {
  const { title, eventDate, eventStartTime, eventEndTime, pinned, author, replacement } = post;
  console.log(post);
  const startTime = new TimeModel().assign(eventStartTime);
  const endTime = new TimeModel().assign(eventEndTime);
  return (
    <Box sx={{ py: 1, ...sx }}>
      {(author?.userFullName || author?.fullName) && <PostUpdateInfo post={post} noAvatar={noAvatar} />}
      {replacement ? (
        <Box sx={{ mb: 2 }}>
          <ErrorAlertBox severity="info" message="Zastępstwo" state={true} />
        </Box>
      ) : null}
      {eventDate && (
        <Typography
          sx={{
            mb: 1,
            wordBreak: 'break-word',
            color: (theme) => theme.palette.text.primary,
            fontWeight: (theme) => theme.typography.fontWeightMedium
          }}>
          {eventDate && `${moment(eventDate).format('DD MMMM')}`}
          {!!startTime && !startTime.isEmpty() && `, godzina ${startTime.toString()}`}
          {!!endTime && !endTime.isEmpty() && ` do ${endTime.toString()}`}
        </Typography>
      )}
      <Box>
        {pinned && <StarIcon sx={{ mr: 1, color: (theme) => theme.palette.color.color20 }} />}
        {title}
      </Box>
    </Box>
  );
};

PostHeader.propTypes = {
  post: PropTypes.object.isRequired
};

export default PostHeader;
