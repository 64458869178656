import { TextField, Box } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createNewProgramElement,
  editProgramElement
} from '../../../actions/psychologicalIndividualJournalClassesActions';
import { PsychologicalClassesValidator } from '../../common/psychologicalWorkProgram/PsychologicalClassesValidator';
import { psychologicalSchemesTypes } from '../../../constants/psychologicalSchemesTypes';
import AdvancedFormField from '../../common/AdvancedFormField';
import DialogForm from '../../forms/DialogForm';
import moment from 'moment';

const IndividualJournalProgramDialog = ({ dialogType, handleCancel, selectedElement, onSave }) => {
  const Ui = useSelector((state) => state.psychologicalIndividualJournalUi.individualProgram);
  const [errors, setErrors] = useState();
  const [title, setTitle] = useState(selectedElement ? selectedElement.title : undefined);
  const [content, setContent] = useState(selectedElement ? selectedElement.content : undefined);
  const assistanceId = useSelector((state) => state.psychologicalIndividualJournalWorkProgram.id);
  const dispatch = useDispatch();

  const handleSave = () => {
    let data = {
      date: moment(),
      type: dialogType,
      title: title,
      content: content.content,
      id: selectedElement ? selectedElement.id : undefined
    };
    selectedElement
      ? dispatch(editProgramElement(data, assistanceId))
      : dispatch(createNewProgramElement(data, assistanceId));
    onSave();
  };

  const generateTitle = (type) => {
    return psychologicalSchemesTypes[type]?.title || 'Nowy wpis';
  };
  return (
    <DialogForm
      header={generateTitle(dialogType)}
      maxWidth="md"
      onSave={handleSave}
      onCancel={() => handleCancel()}
      isDialogOpen={Ui.isEditing}
      isProcessing={Ui.isProcessing}
      onValidate={() => new PsychologicalClassesValidator().validate({ title: title, content: content.content })}
      onValidationDone={(errors) => setErrors({ errors })}
      statePathToUi=""
      requiredFieldText>
      <Box sx={{ my: 1 }}>
        <TextField
          label="Podaj nazwę"
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          defaultValue={!!selectedElement ? selectedElement.title : undefined}
          error={errors?.errors?.title}
          helperText={errors?.errors?.title}
        />
        <AdvancedFormField
          error={errors?.errors}
          value={!!selectedElement ? selectedElement : undefined}
          schemeType={dialogType}
          setData={setContent}
          label={null}
        />
      </Box>
    </DialogForm>
  );
};

export default IndividualJournalProgramDialog;
