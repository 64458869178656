import PropTypes from 'prop-types';
import React from 'react';
import DialogForm from '../../../forms/DialogForm';
import UnitPenaltiesForm from '../forms/UnitPenaltiesForm';
import { UnitPenaltiesValidator } from '../../../../models/configuration/unit/UnitModelsValidators';
import { PenaltiesSettings } from '../../../../models/configuration/unit/UnitModels';
import { afterHoursChargingBase } from '../../../../constants/afterHoursChargingBase';

class UnitPenaltiesDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      penalties: Object.assign({}, this.props.penalties),
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const field = event.target.name;
    const penalties = Object.assign({}, this.state.penalties);
    penalties[field] = event.target.value;

    // Reset fields when switching to exceeded hours penalty mode
    if (field === 'afterHoursChargingBase' && event.target.value === afterHoursChargingBase.penaltyForExceededHours.value) {
      penalties.afterHoursChargingInterval = '';
      penalties.afterHoursChargingOffsetInMinutes = '';
    }

    return this.setState({ penalties });
  }

  render() {
    const { onSave, onCancel, isDialogOpen, isProcessing } = this.props;
    return (
      <DialogForm
        header="Zmiana ustawień naliczania kar"
        onSave={() => onSave(this.state.penalties)}
        onCancel={onCancel}
        isDialogOpen={isDialogOpen}
        isProcessing={isProcessing}
        onValidate={() => new UnitPenaltiesValidator().validate(this.state.penalties)}
        onValidationDone={(errors) => this.setState({ errors })}
        statePathToUi="configuration.unitPenaltiesUi"
        requiredFieldText>
        <UnitPenaltiesForm
          penalties={this.state.penalties}
          onChange={this.handleChange}
          onClear={() => onSave(new PenaltiesSettings())}
          errors={this.state.errors}
        />
      </DialogForm>
    );
  }
}

UnitPenaltiesDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  penalties: PropTypes.object.isRequired
};

export default UnitPenaltiesDialog;
