import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Paper,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import GroupIcon from '@mui/icons-material/Group';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonIcon from '@mui/icons-material/Person';

import SpecialistWorkPlanDialog from './components/dialogs/SpecialistWorkPlanDialog';
import {
  deleteSpecialistWorkPlan,
  setSpecialistWorkDialogClosed,
  setSpecialistWorkDialogOpen
} from '../../actions/specialistNotesActions';
import { useDispatch, useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { fromTemplate, routePaths } from '../../routePaths';
import DotsMenu from '../common/menu/DotsMenu';
import ActionConfirmationMenuItem from '../forms/ActionConfirmationMenuItem';
import { useIsSpecialist } from '../../hooks/useIsSpecialist';

const SpecialistWorkNotesList = () => {
  const ui = useSelector((state) => state.specialistNotesUi.specialistWorksList);
  const workPlans = useSelector((state) => state.specialistNotes.specialistWorksList);
  const dispatch = useDispatch();
  const [selectedPlan, setSelectedPlan] = useState();
  const { isPrincipal } = useIsSpecialist();

  const handleDialogOpen = () => {
    dispatch(setSpecialistWorkDialogOpen());
  };
  const handleDialogClose = () => {
    dispatch(setSpecialistWorkDialogClosed());
    setSelectedPlan();
  };
  const handleEdit = (plan) => {
    setSelectedPlan(plan);
    dispatch(setSpecialistWorkDialogOpen());
  };
  const handleRemove = (specialistWorkId) => {
    dispatch(deleteSpecialistWorkPlan(specialistWorkId));
  };

  const generateList = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}>
          <Typography variant="h6">Wybierz zapis pracy</Typography>
          {isPrincipal && (
            <Button variant="contained" onClick={() => handleDialogOpen()}>
              dodaj
            </Button>
          )}
        </Box>
        <List>
          <Divider component="li" />
          {workPlans.map((plan) => (
            <Box
              key={plan.id}
              sx={{ '& :hover': { background: (theme) => theme.palette.background.hover, cursor: 'pointer' } }}
              onClick={() => {
                browserHistory.push(fromTemplate(routePaths.specialistNotesDetails, [plan.id]));
              }}>
              <ListItem
                sx={{ p: 1.5 }}
                secondaryAction={
                  isPrincipal ? (
                    <Box onClick={(e) => e.stopPropagation()}>
                      <DotsMenu>
                        {() => [
                          <MenuItem
                            key="1"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleEdit(plan);
                            }}>
                            edytuj
                          </MenuItem>,

                          <ActionConfirmationMenuItem
                            key="2"
                            onItemClick={() => {}}
                            actionLabel="usuń"
                            confirmationText="Czy na pewno chcesz usunąć zapis pracy specjalisty?"
                            confirmationTitle="Usuwanie zapisu pracy specjalisty"
                            confirmLabel="Usuń"
                            onConfirm={() => handleRemove(plan.id)}
                            onCancel={() => {}}
                          />
                        ]}
                      </DotsMenu>
                    </Box>
                  ) : (
                    <ArrowForwardIosIcon />
                  )
                }>
                <ListItemAvatar>
                  <Avatar sx={{ background: (theme) => theme.palette.background.color2 }}>
                    {plan.specialistIds.length > 1 ? <GroupIcon /> : <PersonIcon />}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={plan.name} />
              </ListItem>
              <Divider component="li" />
            </Box>
          ))}
        </List>
      </>
    );
  };

  const generateFormDialog = () => {
    return ui.isEditing && <SpecialistWorkPlanDialog handleClose={handleDialogClose} selectedPlan={selectedPlan} />;
  };

  return (
    <Paper sx={{ p: 2 }}>
      {generateList()} {generateFormDialog()}
    </Paper>
  );
};

export default SpecialistWorkNotesList;
