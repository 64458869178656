import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { Box, Typography } from '@mui/material';
import PostUpdateInfo from './PostUpdateInfo';
import { TimeModel } from '../../../models/TimeModel';

const PostHeader = ({ post, sx, noAvatar }) => {
  const { title, eventDate, eventStartTime, eventEndTime, pinned, author } = post;
  const startTime = new TimeModel().assign(eventStartTime);
  const endTime = new TimeModel().assign(eventEndTime);
  return (
    <Box sx={{ py: 1, ...sx }}>
      <PostUpdateInfo post={post} noAvatar={noAvatar} />
      {eventDate && (
        <Typography
          sx={{
            mb: 1,
            wordBreak: 'break-word',
            color: (theme) => theme.palette.text.primary,
            fontWeight: (theme) => theme.typography.fontWeightMedium
          }}>
          {eventDate && `${moment(eventDate).format('DD MMMM')}`}
          {!!startTime && !startTime.isEmpty() && `, godzina ${startTime.toString()}`}
          {!!endTime && !endTime.isEmpty() && ` do ${endTime.toString()}`}
        </Typography>
      )}

      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: (theme) => theme.typography.fontWeightLarge,
          fontSize: 24,
          display: 'flex',
          alignItems: 'center',
          wordBreak: 'break-word',
          color: (theme) => theme.palette.text.primary
        }}>
        {pinned && <VerifiedOutlinedIcon sx={{ mr: 1, mb: 0.3, color: (theme) => theme.palette.color.color2 }} />}
        {title}
      </Typography>
    </Box>
  );
};

PostHeader.propTypes = {
  post: PropTypes.object.isRequired,
  noAvatar: PropTypes.bool
};

export default PostHeader;
