import moment from 'moment';
import { BaseModelValidator } from '../../../../models/BaseModelValidator';
import { check } from '../../../../models/validation/validateHelper';

export class ContactValidator extends BaseModelValidator {
  constructor() {
    super();

    this.constraints = {
      note: (value) => check.isEmpty(value) && 'Nie podano notatki',
      contactType: (value) => check.isEmpty(value) && 'Nie wybrano typu zawiadomienia',
      eventDate: (value) => {
        if (check.isEmpty(value)) return 'Nie podano daty spotkania';
        if (!moment(value).isValid()) return 'Niepoprawna data';
        // if (!moment(value).isSameOrBefore(moment(new Date()).add(2, 'year')))
        //   return `Wybierz datę wcześniejszą niż ${moment(new Date()).add(2, 'year').format('DD/MM/YYYY')}`;
        // if (!moment(value).isSameOrAfter(moment(new Date()).subtract(1, 'day')))
        //   return `Wybierz datę późniejszą niż ${moment(new Date()).subtract(1, 'day').format('DD/MM/YYYY')}`;
        // return null;
      },
      eventTime: (value, model) => {
        if (!check.isEmpty(value) && !moment(value, 'hh:mm').isValid()) return 'Niepoprawna godzina spotkania';
        if (check.isEmpty(value)) return 'Nie podano godziny spotkania';
        return null;
      },
      location: (value) => check.isEmpty(value) && 'Nie podano miejsca spotkania',
      purpose: (value) => check.isEmpty(value) && 'Nie podano celu spotkania'
    };
  }
}
