import staffMemberRoles from '../constants/staffMemberRoles';
import userRoles from '../constants/userRoles';

export const mapStaffRoleToUserRole = (role) => {
  switch (role) {
    case staffMemberRoles.teacher.value:
      return userRoles.staffMemberTeacher;
    case staffMemberRoles.principal.value:
      return userRoles.staffMemberPrincipal;
    case staffMemberRoles.canteenWorker.value:
      return userRoles.staffMemberCanteenWorker;
    case staffMemberRoles.accountant.value:
      return userRoles.staffMemberAccountant;
    case staffMemberRoles.secretary.value:
      return userRoles.staffMemberSecretary;
    case staffMemberRoles.digitalDiary.value:
      return userRoles.staffMemberDigitalDiary;
    case staffMemberRoles.teacherSupport.value:
      return userRoles.staffMemberTeacherSupport;
    case staffMemberRoles.specialist.value:
      return userRoles.staffMemberSpecialist;
    default:
      return undefined;
  }
};
