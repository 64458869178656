import './css/style.css';
import image1 from './assets/header.jpg';
import image3 from './assets/footer.jpg';
import img3 from './assets/arrow.jpg';

const PosterTemplateNoQr = ({ domain }) => {
  return (
    <div className="container">
      <header>
        <img src={image1} alt="header" />
      </header>
      <main>
        <div className="www">
          Zaloguj się
          <img src={img3} alt="arrow" className="img" />
          <div className="placowka">{domain ? `${domain}.4parents.pl` : 'adrestwojejplacowki.4parents.pl'}</div>
        </div>
      </main>
      <footer>
        <img src={image3} alt="footer" />
      </footer>
    </div>
  );
};

export default PosterTemplateNoQr;
