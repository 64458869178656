import { check } from '../../validation/validateHelper';
import { UnitAddress, WorkingHours, PenaltiesSettings } from './UnitModels';
import { BaseModelValidator } from '../../BaseModelValidator';
import { isNumericGreaterThanOrEqual, isNumericGreaterThan } from '../../../utils/numberValidators';
import { afterHoursChargingBase } from '../../../constants/afterHoursChargingBase';

export class UnitAddressValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = UnitAddress;

    this.constraints = {
      name: (value) => {
        if (check.isEmpty(value)) return 'Nie podano nazwy placówki';
        if (value.search(/'|"/g) !== -1) return 'Nazwa placówki nie może zawierać znaków \' oraz "';
        return null;
      },
      city: (value) => check.isEmpty(value) && 'Nie podano miasta',
      address: (value) => check.isEmpty(value) && 'Nie podano adresu',
      zipCode: (value) => {
        if (check.isEmpty(value)) return 'Nie podano kodu pocztowego';
        if (check.single(value, { format: '^[0-9]{2}-[0-9]{3}$' })) return 'Kod pocztowy powinien mieć postać 00-000';
        return null;
      }
    };
  }
}

export class UnitWorkingHoursValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = WorkingHours;
    this.constraints = {
      from: (value, model) => {
        if (value.isEmpty() || isNaN(value.hourLocal) || isNaN(value.minuteLocal))
          return 'Nie podano czasu rozpoczęcia pracy';
        if (value.isAfter(model.to)) return `Czas rozpoczęcia pracy musi być wcześniejszy niż ${model.to.toString()}`;
        return null;
      },
      to: (value) => {
        if (value.isEmpty() || isNaN(value.hourLocal) || isNaN(value.minuteLocal))
          return 'Nie podano czasu zakończenia pracy';
        return null;
      }
    };
  }
}

export class UnitAbsenceRemovalDeadlineValidator extends BaseModelValidator {
  constructor() {
    super();
    this.constraints = {
      legalGuardiansAbsenceRemovalDeadlineHours: (value) =>
        !value && !isNumericGreaterThan(value, 0) && 'Wartość musi być liczbą dodatnią'
    };
  }
}

export class UnitPenaltiesValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = PenaltiesSettings;

    this.constraints = {
      afterHoursChargingRate: (value) =>
        !value && !isNumericGreaterThanOrEqual(value, 0) && 'Należy podać dodatnią kwotę',
      afterHoursChargingInterval: (value, penalties) =>
        penalties.afterHoursChargingBase !== afterHoursChargingBase.penaltyForExceededHours.value &&
        (!value || !isNumericGreaterThanOrEqual(value, 0)) &&
        'Wartość musi być liczbą dodatnią',
      afterHoursChargingOffsetInMinutes: (value, penalties) =>
        penalties.afterHoursChargingBase !== afterHoursChargingBase.penaltyForExceededHours.value &&
        (!value || !isNumericGreaterThanOrEqual(value, 0)) &&
        'Wartość musi być liczbą dodatnią',
      afterHoursChargingBase: (value) => check.isEmpty(value) && 'Należy wybrać sposób naliczania',
      exceededHoursLimit: (value, penalties) =>
        penalties.afterHoursChargingBase === afterHoursChargingBase.penaltyForExceededHours.value &&
        (!value || !isNumericGreaterThanOrEqual(value, 0)) &&
        'Należy podać dodatnią liczbę godzin'
    };
  }
}
