import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import BoardPostCard from './post/BoardPostCard';
import EmptyState from '../../common/EmptyState';
import userRoles from '../../../constants/userRoles';
import { useSelector } from 'react-redux';
import { Box, Button } from '@mui/material';
import _ from 'lodash';

const BoardPostsList = (props) => {
  const auth = useSelector((state) => state.auth);
  const { hasNextPage, pageCount, posts, onEdit, onDelete, onLoadMoreClick, isGroupArchived, editable } = props;
  const editConfig = useSelector((state) => state.configuration.unit.postsModifyingEnabled);

  const filterByHours = (array) => {
    const newArr = [...array];
    newArr.sort((a, b) => {
      if (a.eventStartTime && b.eventStartTime) {
        const minutesA = a.eventStartTime?.hourLocal * 60 + a.eventStartTime.minuteLocal;
        const minutesB = b.eventStartTime?.hourLocal * 60 + b.eventStartTime.minuteLocal;
        return minutesA - minutesB;
      }
      return 0;
    });
    return newArr;
  };

  const sortedArray = useMemo(() => {
    return filterByHours(posts);
  }, [posts]);

  if (posts.length === 0) {
    return <EmptyState contrast isPaper message="Brak wpisów" />;
  }

  const canEdit = (post) => {
    if (typeof editable !== 'undefined') {
      return editable;
    }
    if (isGroupArchived) {
      return false;
    }
    if (editConfig) {
      return editConfig;
    }
    return post.author.id === auth.userId || auth.userRole === userRoles.staffMemberPrincipal;
  };

  return (
    <>
      {sortedArray.map((post) => {
        return (
          <Box sx={{ my: 2 }} key={_.uniqueId()}>
            <BoardPostCard
              id={post.id}
              key={post.id}
              post={post}
              canEdit={canEdit(post)}
              onEdit={() => onEdit(post)}
              onDelete={() => onDelete(post)}
            />
          </Box>
        );
      })}
      {pageCount > 0 && hasNextPage && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" aria-label="Załaduj kolejne wpisy" fullWidth={true} onClick={onLoadMoreClick}>
            Załaduj kolejne wpisy
          </Button>
        </Box>
      )}
    </>
  );
};

BoardPostsList.propTypes = {
  posts: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  onLoadMoreClick: PropTypes.func,
  isGroupArchived: PropTypes.bool
};

export default BoardPostsList;
