/*eslint indent:0*/
import initialState from './initial';
import { AUTHENTICATE_PIN_SUCCESS, CLEAR_AUTHENTICATED_PIN_STATE } from '../actions/actionTypes';
import { PinEntriesModel } from '../models/pins/PinModels';

export default function pinEntriesReducer(state = initialState.pinEntries, action) {
  switch (action.type) {
    case AUTHENTICATE_PIN_SUCCESS: {
      return new PinEntriesModel().assign(action.entries);
    }
    case CLEAR_AUTHENTICATED_PIN_STATE: {
      return initialState.pinEntries;
    }
    default:
      return state;
  }
}
