import BaseModel from '../BaseModel';

export class RoutingModel extends BaseModel {
  constructor() {
    super();
    this.url = '';
    this.context = null;
    this.matchedRoute = null;
    this.isPopAction = false;
    this.params = {};
  }
}
