import React from 'react';
import PropTypes from 'prop-types';
import HomeIcon from '@mui/icons-material/Home';
import ManualIcon from '@mui/icons-material/DescriptionOutlined';

import ElectronicDiaryIcon from '@mui/icons-material/AssignmentOutlined';
import TimeTable from '@mui/icons-material/DateRange';
import BookIcon from '@mui/icons-material/Book';
import TravelIcon from '@mui/icons-material/CardTravel';
import ActivitiesIcon from '@mui/icons-material/GroupAddOutlined';
import AssignmentIndIcon from '@mui/icons-material/PermContactCalendarOutlined';
import MessageBoardIcon from '@mui/icons-material/SmsFailedOutlined';
import EventsCalendarIcon from '@mui/icons-material/Event';
import StorageIcon from '@mui/icons-material/SaveOutlined';
import { eventTypes } from '../../../constants/eventTypes';
import MenuRoute from './MenuRoute';
import config from '../../../config/config';
import moment from 'moment';
import { routePaths } from '../../../routePaths';
import { connect, useSelector } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';

const DigitalDiaryTeacherMenu = ({ currentRoute, onRouteSelect }) => {
  const year = moment().format('YYYY');
  const month = moment().format('MM');
  let constKey = 0;
  const getNextKey = () => constKey++;
  const rest = { selectedRoutePath: currentRoute, onClick: onRouteSelect };
  const unreadPostsCount = useSelector((state) => state.staffMessageBoard.unreadedPosts).length;
  const theme = useTheme();
  const isContrastColor = useSelector((state) => state.theme);

  return (
    <List>
      <MenuRoute
        text="Pulpit"
        icon={
          <HomeIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color1 }}
          />
        }
        routePath={routePaths.home}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Dziennik obecności - zajęcia dodatkowe"
        icon={
          <ElectronicDiaryIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color25 }}
          />
        }
        routePath={routePaths.activitiesAttendanceSheetPage}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Dysk wirtualny"
        icon={
          <StorageIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color7 }}
          />
        }
        routePath={routePaths.virtualDisc}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        primaryText="Dziennik elektroniczny"
        icon={
          <ElectronicDiaryIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
          />
        }
        primaryTogglesNestedList={true}
        nestedItems={[
          <MenuRoute
            text="Ramowy rozkład dnia"
            icon={
              <TimeTable
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryTimeTable}
            routeParams={[eventTypes.daySchedule.type, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Roczny ramowy plan dnia"
            icon={
              <TimeTable
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryFrameworkDaySchedule}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Wycieczki"
            icon={
              <TravelIcon
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryTrip}
            routeParams={[eventTypes.trip.type, year, month]}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Zajęcia dodatkowe"
            icon={
              <ActivitiesIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color13 }}
              />
            }
            routePath={routePaths.electronicDiaryAdditionalActivities}
            routeParams={[eventTypes.additionalActivities.type, year, month]}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      <MenuRoute
        primaryText="Wewnętrzne"
        icon={
          <AssignmentIndIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
          />
        }
        primaryTogglesNestedList={true}
        badgeValue={unreadPostsCount}
        nestedItems={[
          <MenuRoute
            text="Ogłoszenia"
            icon={
              <MessageBoardIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
              />
            }
            routePath={routePaths.staffMessageBoard}
            badgeValue={unreadPostsCount}
            key={getNextKey()}
            {...rest}
          />,
          <MenuRoute
            text="Kalendarz"
            icon={
              <EventsCalendarIcon
                fontSize="medium"
                sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
              />
            }
            routePath={routePaths.staffCalendar}
            routeParams={[year, month]}
            key={getNextKey()}
            {...rest}
          />
        ]}
      />
      <MenuRoute
        text="Zajęcia dodatkowe"
        icon={
          <ActivitiesIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color8 }}
          />
        }
        routePath={routePaths.activities}
        key={getNextKey()}
        {...rest}
      />
      <MenuRoute
        text="Blog 4Parents"
        icon={
          <BookIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color6 }}
          />
        }
        key={getNextKey()}
        component="a"
        href="https://4parents.education/blog/"
        target="_blank"
      />

      <ListItem
        button
        key={getNextKey()}
        onClick={() => window.open(config.staffManualUrl, '_blank')}
        component="li"
        aria-label="Otwórz instrukcję obsługi">
        <ListItemIcon>
          <ManualIcon
            fontSize="medium"
            sx={{ color: isContrastColor === 'contrast' ? theme.palette.color.wcag : theme.palette.color.color19 }}
          />
        </ListItemIcon>
        <ListItemText
          sx={{ '& .MuiListItemText-primary': { fontWeight: (theme) => theme.typography.fontWeightBold } }}
          primary="Instrukcja obsługi"
        />
      </ListItem>
    </List>
  );
};

DigitalDiaryTeacherMenu.propTypes = {
  firstGroupId: PropTypes.string,
  currentRoute: PropTypes.string.isRequired,
  onRouteSelect: PropTypes.func.isRequired,
  choosenGroup: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    choosenGroup: state.digitalDiary.choosenGroup,
    unit: state.configuration.unit
  };
}

export default connect(mapStateToProps)(DigitalDiaryTeacherMenu);
