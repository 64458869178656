import { check } from '../../validation/validateHelper';
import { BaseModelValidator } from '../../BaseModelValidator';
import moment from 'moment';

export class DailyScheduleEventValidator extends BaseModelValidator {
  constructor() {
    super();

    this.constraints = {
      title: (value) => {
        if(check.isEmpty(value)) {
          return 'Nie podano tytułu'
        }
        if (value.length < 3) {
          return 'Tytuł musi mieć minimum 3 znaki'
        }
        if (value.length > 100) {
          return 'Tytuł może miec maksymalnie 100 znaków'
        }
      },
      description: (value) => {
        if(check.isEmpty(value)) {
          return 'Nie podano opisu'
        }
        if (value.length < 3) {
          return 'Opis musi mieć minimum 3 znaki'
        }
      },
      startTime: (value, model) => {
        if (!moment(value, 'HH:mm').isBefore(moment(model.endTime, 'HH:mm')))
          return `Zadeklarowana godzina musi być wcześniejsza niż ${model.endTime.format('HH:mm')}`;
        if (!check.isEmpty(value) && !moment(value, 'HH:mm').isValid()) return 'Niepoprawny czas rozpoczęcia';
        return null;
      },
      endTime: (value) => {
        if (!check.isEmpty(value) && !moment(value, 'HH:mm').isValid()) return 'Niepoprawny czas zakończenia';
        return null;
      },
      items: (value, model) => {
        const values = value?.filter((item) => ((model.startTime.isAfter(moment(item.startTime, 'HH:mm')) && model.startTime.isBefore(moment(item.endTime, 'HH:mm'))) ||
                                (model.endTime.isAfter(moment(item.startTime, 'HH:mm')) && model.endTime.isBefore(moment(item.endTime, 'HH:mm'))) ||
                                (model.startTime.isBefore(moment(item.startTime, 'HH:mm')) && model.endTime.isAfter(moment(item.endTime, 'HH:mm')))) &&
                              (!model.id || model.id !== item.id) )
        if (values.length > 0) {
          return `Zadeklarowane godziny pokrywają się z innym wydarzeniem`
        }
      }
    };
  }
}
