import React from 'react';
import PropTypes from 'prop-types';
import DotsMenu from '../../common/menu/DotsMenu';
import ActionConfirmationMenuItem from '../../forms/ActionConfirmationMenuItem';
import {
  Typography,
  Box,
  Paper,
  MenuItem
} from '@mui/material';

const DailyScheduleItem = ({ item, onEdit, onDelete }) => {

  return (
    <Paper sx={{ my: 2, p: 4 }} elevation={1}>
      <Typography>godzina {item.startTime} do {item.endTime}</Typography>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography
          variant="h5"
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            fontWeight: (theme) => theme.typography.fontWeightBold
          }}>
          {item.title}
        </Typography>
        <DotsMenu key={`dostMenu-${item.id}`}>
          {(onClose) => [
            <MenuItem key={`edit-${item.id}`} onClick={() => onEdit(item)}>
              Edytuj
            </MenuItem>,
            <ActionConfirmationMenuItem
              id={item.id}
              key={`action-${item.id}`}
              actionLabel="Usuń"
              confirmationText="Czy na pewno chcesz usunąć element ramowego planu dnia?"
              confirmationTitle="Usuń element"
              confirmLabel="Usuń"
              onItemClick={onClose}
              onConfirm={() => onDelete(item)}
              onCancel={() => {}}
            />
          ]}
        </DotsMenu>
      </Box>
      <Typography>{item.description}</Typography>
    </Paper>
  );
}

DailyScheduleItem.propTypes = {
  item: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default DailyScheduleItem;