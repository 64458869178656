import TableBody from '@mui/material/TableBody';
import { getComparator, stableSort } from '../../../utils/tableSorting';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import numberFormatter from '../../../utils/numberFormatter';
import { ChargingReportTypes } from '../../../constants/chargingReportTypes';
import { Checkbox, Typography, IconButton, Collapse, Table, TableHead, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const OverdueListBody = ({ groupedData, page, rowsPerPage, order, orderBy, notificationList, setNotificationList }) => {
  const [expandedRows, setExpandedRows] = useState([]);

  const data = Object.values(groupedData);

  const handleExpandClick = (childId) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(childId)
        ? prevExpandedRows.filter((id) => id !== childId)
        : [...prevExpandedRows, childId]
    );
  };

  const handleSelectAllPayments = (childId, isChecked) => {
    const childGroup = groupedData.find((group) => group.childId === childId);
    if (!childGroup) return;
    const childPayments = childGroup.overduePayments.map((payment) => payment.id);
    setNotificationList(
      isChecked
        ? [...new Set([...notificationList, ...childPayments])]
        : notificationList.filter((id) => !childPayments.includes(id))
    );
  };

  const handlePaymentCheckboxChange = (paymentId, isChecked) => {
    setNotificationList(
      isChecked ? [...notificationList, paymentId] : notificationList.filter((id) => id !== paymentId)
    );
  };

  return (
    <TableBody>
      {stableSort(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => {
          const isExpanded = expandedRows.includes(row.childId);
          const allPaymentsSelected = row.overduePayments.every((payment) => notificationList.includes(payment.id));
          return (
            <React.Fragment key={row.childId}>
              <TableRow
                sx={{
                  '&:hover': {
                    background: (theme) => theme.palette.background.hover
                  }
                }}>
                <TableCell align="left">
                  <Stack direction="row" justifyContent="space-between">
                    <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 18 }}>
                      {row.firstName}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="left">
                  <Typography sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, fontSize: 18 }}>
                    {row.lastName}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography>{row.calculatedTotal}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Checkbox
                    checked={allPaymentsSelected}
                    indeterminate={
                      !allPaymentsSelected &&
                      row.overduePayments.some((payment) => notificationList.includes(payment.id))
                    }
                    onChange={(e) => handleSelectAllPayments(row.childId, e.target.checked)}
                  />
                  <IconButton onClick={() => handleExpandClick(row.childId)} size="small">
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Collapse in={isExpanded} timeout={500} unmountOnExit>
                    <Table
                      sx={{
                        mt: 1,
                        mb: 1
                      }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{ borderBottom: '1px solid grey', fontWeight: 'bold' }}>
                            Numer
                          </TableCell>
                          <TableCell align="center" sx={{ borderBottom: '1px solid grey', fontWeight: 'bold' }}>
                            Kwota
                          </TableCell>
                          <TableCell align="center" sx={{ borderBottom: '1px solid grey', fontWeight: 'bold' }}>
                            Typ opłaty
                          </TableCell>
                          <TableCell align="center" sx={{ borderBottom: '1px solid grey', fontWeight: 'bold' }}>
                            Termin
                          </TableCell>
                          <TableCell align="center" sx={{ borderBottom: '1px solid grey', fontWeight: 'bold' }}>
                            Zaznacz
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.overduePayments.map((payment) => (
                          <TableRow key={payment.id}>
                            <TableCell align="center">
                              <Typography>{payment.number}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>
                                {numberFormatter.format(payment.calculatedTotal - (payment.paidAmount || 0))}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{ChargingReportTypes[payment.dueType].name}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>{moment(payment.maturityDate).format('YYYY-MM-DD')}</Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={notificationList.includes(payment.id)}
                                onChange={(e) => handlePaymentCheckboxChange(payment.id, e.target.checked)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
    </TableBody>
  );
};

OverdueListBody.propTypes = {
  groupedData: PropTypes.array.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.object,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  notificationList: PropTypes.array.isRequired,
  setNotificationList: PropTypes.func.isRequired
};

export default OverdueListBody;
