import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BoardPostsList from './BoardPostsList';
import * as digitalDiaryDetailsActions from '../../../actions/messageBoardDigitalDiaryActions';
import PostEditor from '../customCalendar/BoardPostEditDialog';
import { DigitalDiaryEventModel } from '../../../models/digitalDiary/digitalDiaryEvent/DigitalDiaryEventModel';
import PageHeader from '../../common/PageHeader';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import { routePathActions } from '../../../routePathActions';
import { getNameByType } from '../../../constants/eventTypes';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { Box, Button } from '@mui/material';

class DigitalDiaryMessageBoardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPost: null
    };
    this.handlePostSave = this.handlePostSave.bind(this);
    this.handlePostEdit = this.handlePostEdit.bind(this);
    this.handlePostCancel = this.handlePostCancel.bind(this);
    this.renderEditDialog = this.renderEditDialog.bind(this);
    this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this);
    this.handlePostDelete = this.handlePostDelete.bind(this);
    this.renderAddPostButton = this.renderAddPostButton.bind(this);
  }

  handleLoadMoreClick() {
    // this.loadPosts(this.props.board.limit, this.props.board.offset);
  }

  // loadPosts(limit, offset) {
  // if (this.props.isAllowed(routePathActions.messageBoard.viewAllPosts)) {
  //   this.props.actions.loadNextPostsAsync(limit, offset);
  // } else {
  //   this.props.actions.loadNextPostsToMeAsync(limit, offset);
  // }
  // }

  handlePostDelete(post) {
    this.setState({
      currentPost: null
    });
    this.props.detailsActions.deleteEventAsync(post.id);
  }

  handlePostEdit(post) {
    this.setState({ currentPost: new DigitalDiaryEventModel().assign(post) });
    if (post.id) {
      this.props.detailsActions.updateEventStart();
    } else {
      this.props.detailsActions.createEventStart();
    }
  }

  handlePostSave(post) {
    if (post.id) {
      this.props.detailsActions.updateEventAsync({
        eventData: post,
        eventType: this.props.params.eventType,
        eventDate: `${this.props.params.year}-${this.props.params.month}-${this.props.params.day}`,
        groupId: this.props.params.groupId
      });
    } else {
      this.props.detailsActions.addNewEventAsync({
        eventData: post,
        eventType: this.props.params.eventType
      });
    }
  }

  handlePostCancel(post) {
    this.setState({ currentPost: null });
    if (post.id) {
      this.props.detailsActions.updateEventCancel();
    } else {
      this.props.detailsActions.createEventCancel();
    }
  }

  renderEditDialog() {
    return this.props.ui.isEditing ? (
      <PostEditor
        coursebook={this.props.params.eventType === 'saveWork'}
        defaultPublishingHouseId={this.props.params.eventType === 'saveWork' ? this.props.defaultPublishingHouseId : ''}
        post={this.state.currentPost}
        groups={this.props.groups}
        activities={this.props.activities}
        onSave={(post) => this.handlePostSave(post)}
        onCancel={(post) => this.handlePostCancel(post)}
        isEditing={this.props.ui.isEditing}
        isProcessing={this.props.ui.isProcessing}
        canSetDate={true}
        canClearDate={false}
        eventType={this.props.eventType}
        staffMembers={this.props.staffMembers}
        title={this.state.currentPost?.id ? 'Edytuj zapis' : 'Utwórz nowy zapis'}
        showReplacement
      />
    ) : null;
  }

  renderAddPostButton() {
    if (this.props.isAllowed(routePathActions.messageBoard.createPost)) {
      return (
        <Button
          variant="contained"
          aria-label="Nowy wpis"
          disabled={!this.props.groups.some((group) => group.id === this.props.params.groupId)}
          onClick={() =>
            this.handlePostEdit({
              eventDate: `${this.props.params.year}-${this.props.params.month}-${this.props.params.day}`,
              group: this.props.params.groupId
            })
          }>
          Nowy wpis
        </Button>
      );
    }
    return null;
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeader>
            <PageHeaderText title={getNameByType(this.props.params.eventType)} titleIcon={<EventNoteIcon />} />
            {this.renderAddPostButton()}
          </PageHeader>
          {this.renderEditDialog()}
          <BoardPostsList
            posts={this.props.board.events}
            pageCount={this.props.board.pageCount}
            hasNextPage={this.props.board.hasNextPage}
            onEdit={(post) => this.handlePostEdit(post)}
            onDelete={(post) => this.handlePostDelete(post)}
            onLoadMoreClick={this.handleLoadMoreClick}
            isGroupArchived={!this.props.groups.some((group) => group.id === this.props.params.groupId)}
          />
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

DigitalDiaryMessageBoardPage.propTypes = {
  board: PropTypes.object.isRequired,
  ui: PropTypes.object.isRequired,
  isAllowed: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  detailsActions: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  eventType: PropTypes.string.isRequired,
  staffMembers: PropTypes.array.isRequired,
  defaultPublishingHouseId: PropTypes.string
};

function mapStateToProps(state, { params }) {
  return {
    board: state.messageBoardDigitalDiary,
    ui: state.messageBoardDigitalDiaryUi,
    groups: state.groups,
    activities: state.activities,
    isAllowed: state.auth.isAllowed.bind(state.auth),
    eventType: params.eventType,
    staffMembers: state.staffMembers,
    defaultPublishingHouseId: state.configuration.unit.defaultPublishingHouseId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    detailsActions: bindActionCreators(digitalDiaryDetailsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DigitalDiaryMessageBoardPage);
