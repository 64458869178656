/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import _ from 'lodash';
import { UiModel } from '../models/UiModel';

export default function psychologicalIndividualProgramUiReducer(
  state = initialState.psychologicalIndividualProgramUi,
  action
) {
  switch (action.type) {
    case types.INDIVIDUAL_PROGRAM_START: {
      return { ...state, individualProgram: UiModel.start() };
    }
    case types.INDIVIDUAL_PROGRAM_CANCEL: {
      return { ...state, individualProgram: UiModel.cancel() };
    }
    case types.INDIVIDUAL_PROGRAM_SUBMIT: {
      return { ...state, individualProgram: UiModel.submit() };
    }
    case types.ADD_INDIVIDUAL_PROGRAM: {
      return { ...state, individualProgram: UiModel.success() };
    }
    case types.EDIT_INDIVIDUAL_PROGRAM: {
      return { ...state, individualProgram: UiModel.success() };
    }
    case types.DELETE_INDIVIDUAL_PROGRAM: {
      return { ...state, individualProgram: UiModel.success() };
    }
    case types.INDIVIDUAL_PROGRAM_FAILURE: {
      return { ...state, individualProgram: UiModel.failure(action.errors) };
    }
    //classes
    case types.INDIVIDUAL_CLASSES_START: {
      return { ...state, individualClasses: UiModel.start() };
    }
    case types.INDIVIDUAL_CLASSES_CANCEL: {
      return { ...state, individualClasses: UiModel.cancel() };
    }
    case types.INDIVIDUAL_CLASSES_SUBMIT: {
      return { ...state, individualClasses: UiModel.submit() };
    }
    case types.ADD_INDIVIDUAL_CLASSES: {
      return { ...state, individualClasses: UiModel.success() };
    }
    case types.EDIT_INDIVIDUAL_CLASSES: {
      return { ...state, individualClasses: UiModel.success() };
    }
    case types.DELETE_INDIVIDUAL_CLASSES: {
      return { ...state, individualClasses: UiModel.success() };
    }
    case types.INDIVIDUAL_CLASSES_FAILURE: {
      return { ...state, individualClasses: UiModel.failure(action.errors) };
    }

    case types.INDIVIDUAL_PROGRAM_NOTE_START: {
      return { ...state, notes: UiModel.start() };
    } 
    case types.INDIVIDUAL_PROGRAM_NOTE_CANCEL: {
      return { ...state, notes: UiModel.cancel() };
    }
    case types.INDIVIDUAL_PROGRAM_NOTE_SUBMIT: {
      return { ...state, notes: UiModel.submit() };
    }
    case types.ADD_INDIVIDUAL_PROGRAM_NOTE : {
      return { ...state, notes: UiModel.success() };
    }
    case types.EDIT_INDIVIDUAL_PROGRAM_NOTE: {
      return { ...state, notes: UiModel.success() };
    }
    case types.DELETE_INDIVIDUAL_PROGRAM_NOTE: {
      return { ...state, notes: UiModel.success() };
    }
    case types.INDIVIDUAL_PROGRAM_NOTE_FAILURE: {
      return { ...state, notes: UiModel.failure(action.errors) };
    }

    case types.INDIVIDUAL_PROGRAM_OBSERVATION_START: {
      return { ...state, observations: UiModel.start() };
    } 
    case types.INDIVIDUAL_PROGRAM_OBSERVATION_CANCEL: {
      return { ...state, observations: UiModel.cancel() };
    }
    case types.INDIVIDUAL_PROGRAM_OBSERVATION_SUBMIT: {
      return { ...state, observations: UiModel.submit() };
    }
    case types.ADD_INDIVIDUAL_PROGRAM_OBSERVATION : {
      return { ...state, observations: UiModel.success() };
    }
    case types.EDIT_INDIVIDUAL_PROGRAM_OBSERVATION: {
      return { ...state, observations: UiModel.success() };
    }
    case types.DELETE_INDIVIDUAL_PROGRAM_OBSERVATION: {
      return { ...state, observations: UiModel.success() };
    }
    case types.INDIVIDUAL_PROGRAM_OBSERVATION_FAILURE: {
      return { ...state, observations: UiModel.failure(action.errors) };
    }

    default:
      return state;
  }
}
