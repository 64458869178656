import React, { useState } from 'react';
import ContentWrapper from '../common/contentWrapper';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Box, Button, List, ListItem, ListItemText, Paper, Stack } from '@mui/material';
import UnitSchemeElementHeader from '../configuration/unit/UnitSchemeElementHeader';
import UnitSchemeElement from '../configuration/unit/UnitSchemeElement';
import UnitSchemeElementDialog from '../configuration/unit/dialogs/UnitSchemeElementDialog';
import { useDispatch, useSelector } from 'react-redux';
import { cancelPsychologicalSchemesDialog, openPsychologicalSchemesDialog } from '../../actions/psychologicalActions';

const PsychologicalSchemesContainer = () => {
  const dispatch = useDispatch();
  const [selectedForm, setSelectedForm] = useState(true);
  const [selectedField, setSelectedField] = useState();
  const [selectedScheme, setSelectedScheme] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const schemesArray = useSelector((state) => state.psychologicalSchemes);

  const onClick = (field) => {
    setSelectedField(field);
    setIsEditing(false);
    setSelectedScheme(null);
    dispatch(openPsychologicalSchemesDialog());
  };
  const handlePreview = (data) => {
    setSelectedScheme(data);
    dispatch(openPsychologicalSchemesDialog());
  };

  const handleDialogCancel = () => {
    setSelectedScheme(undefined);
    dispatch(cancelPsychologicalSchemesDialog());
    setIsEditing(false);
  };
  const handleStartEditing = (data) => {
    setSelectedField(data.fieldName);
    setIsEditing(true);
    setSelectedScheme(data);
    dispatch(openPsychologicalSchemesDialog());
  };

  const renderWopfu = () => {
    return (
      <>
        <UnitSchemeElementHeader title="Wielospecjalistyczna ocena funkcjonowania ucznia" />
        <UnitSchemeElement
          onEdit={handleStartEditing}
          onPreview={handlePreview}
          type="individualDevelopmentAndEducationalNeeds"
          rows={schemesArray?.individualDevelopmentAndEducationalNeeds}
          onClick={onClick}
          title="Indywidualne potrzeby rozwojowe i edukacyjne"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          title="Mocne strony, predyspozycje i zainteresowania ucznia"
          type="studentStrengthsAndPreferences"
          rows={schemesArray?.studentStrengthsAndPreferences}
          onClick={onClick}
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="parentSupportScope"
          rows={schemesArray?.parentSupportScope}
          onClick={onClick}
          title="Zakres i charakter wsparcia ze strony nauczycieli, specjalistów lub pomocy nauczyciela"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="reasonsForEducationalFailures"
          rows={schemesArray?.reasonsForEducationalFailures}
          onClick={onClick}
          title="Przyczyny niepowodzeń edukacyjnych i trudności w funkcjonowaniu dziecka"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="programEffectivenessAssessment"
          rows={schemesArray?.programEffectivenessAssessment}
          onClick={onClick}
          title="Ocena efektywności programu"
        />
      </>
    );
  };

  const renderIpet = () => {
    return (
      <>
        <UnitSchemeElementHeader
          title="Indywidualny program edukacyjno-terapeutyczny"
          onClick={() => {
            setSelectedForm(false);
          }}
        />

        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="programAdaptationScopeAndMethod"
          rows={schemesArray?.programAdaptationScopeAndMethod}
          onClick={onClick}
          title="Zakres i sposób dostosowania programu wychowania przedszkolnego oraz wymagań edukacyjnych do indywidualnych potrzeb rozwojowych i edukacyjnych  oraz możliwości psychofizycznych ucznia"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="parentSupportActions"
          rows={schemesArray?.parentSupportActions}
          onClick={onClick}
          title="Działania wspierające rodziców dziecka"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="psychologicalCounselingRecommendations"
          rows={schemesArray?.psychologicalCounselingRecommendations}
          onClick={onClick}
          title="Zalecenia poradni psychologicznej"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="psychologicalSupport"
          rows={schemesArray?.psychologicalSupport}
          onClick={onClick}
          title="Udzielana pomoc psychologiczno-pedagogiczna"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="rehabilitationSessions"
          rows={schemesArray?.rehabilitationSessions}
          onClick={onClick}
          title="Zajęcia rewalidacyjne"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="teacherSpecialistCooperationScope"
          rows={schemesArray?.teacherSpecialistCooperationScope}
          onClick={onClick}
          title="Zakres współpracy nauczycieli z rodzicami"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="integratedActions"
          rows={schemesArray?.integratedActions}
          onClick={onClick}
          title="Zintegrowane działania"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="educationConditionAdaptationTypeAndMethod"
          rows={schemesArray?.educationConditionAdaptationTypeAndMethod}
          onClick={onClick}
          title="Rodzaj i sposób dostosowania warunków organizacji kształcenia do rodzaju niepełnosprawności ucznia w tym w zakresie wykorzystywania technologii wspomagających to kształcenie - w zależności od potrzeb"
        />
      </>
    );
  };

  const renderDiary = () => {
    return (
      <>
        <UnitSchemeElementHeader
          title="Dziennik zajęć indywidualnych"
          onClick={() => {
            setSelectedForm(false);
          }}
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="basicGoals"
          rows={schemesArray?.basicGoals}
          onClick={onClick}
          title="Cele ogólne"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="specificGoals"
          rows={schemesArray?.specificGoals}
          onClick={onClick}
          title="Cele szczegółowe"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="individualClassesMethods"
          rows={schemesArray?.individualClassesMethods}
          onClick={onClick}
          title="Metody"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="conclusions"
          rows={schemesArray?.conclusions}
          onClick={onClick}
          title="Wnioski"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="teachingResources"
          rows={schemesArray?.teachingResources}
          onClick={onClick}
          title="Środki dydaktyczne"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="individualSchedules"
          rows={schemesArray?.individualSchedules}
          onClick={onClick}
          title="Rozkład zajęć"
        />
      </>
    );
  };

  const renderGroupLessons = () => {
    return (
      <>
        <UnitSchemeElementHeader
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          title="Dziennik zajęć grupowych"
          onClick={() => {
            setSelectedForm(false);
          }}
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="basicGroupGoals"
          rows={schemesArray?.basicGroupGoals}
          onClick={onClick}
          title="Cele ogólne"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="specificGroupGoals"
          rows={schemesArray?.specificGroupGoals}
          onClick={onClick}
          title="Cele szczegółowe"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="groupMethods"
          rows={schemesArray?.groupMethods}
          onClick={onClick}
          title="Metody"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="groupConclusions"
          rows={schemesArray?.groupConclusions}
          onClick={onClick}
          title="Wnioski"
        />
        <UnitSchemeElement
          onPreview={handlePreview}
          onEdit={handleStartEditing}
          type="groupSchedules"
          rows={schemesArray?.groupSchedules}
          onClick={onClick}
          title="Rozkład zajęć"
        />
      </>
    );
  };
  const renderAll = () => {
    return (
      <Box>
        {renderWopfu()}
        {renderIpet()}
        {renderDiary()}
        {renderGroupLessons()}
      </Box>
    );
  };

  const schemes = [
    { title: 'Wielospecjalistyczna ocena funkcjonowania ucznia', value: 'wopfu' },
    { title: 'Indywidualny program edukacyjno-terapeutyczny', value: 'ipet' },
    { title: 'Dziennik zajęć indywidualnych', value: 'diary' },
    { title: 'Dziennik zajęć grupowych', value: 'group' },
    { title: 'Wszystkie', value: 'all' }
  ];

  const renderMenu = () => {
    return (
      <Paper>
        <List>
          {schemes.map((scheme) => (
            <ListItem>
              <ListItemText
                sx={{
                  '&:hover': { background: (theme) => theme.palette.background.hover, cursor: 'pointer' },
                  m: 0,
                  p: 2
                }}
                primary={scheme.title}
                primaryTypographyProps={{ fontSize: 18, fontWeight: (theme) => theme.typography.fontWeightBold }}
                onClick={() => {
                  setSelectedForm(scheme.value);
                }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    );
  };

  const renderSelected = () => {
    switch (selectedForm) {
      case 'wopfu':
        return renderWopfu();
      case 'ipet':
        return renderIpet();
      case 'diary':
        return renderDiary();
      case 'group':
        return renderGroupLessons();
      case 'all': {
        return renderAll();
      }
      default: {
        return renderAll();
      }
    }
  };

  return (
    <>
      <PageHeaderText title="Schematy psychologiczno-pedagogiczne" titleIcon={<PendingActionsIcon />} />
      <ContentWrapper>
        <UnitSchemeElementDialog
          onCancel={handleDialogCancel}
          isEditing={isEditing}
          selectedField={selectedField}
          selectedScheme={selectedScheme}
        />
        {selectedForm && (
          <Stack alignItems="end" sx={{ width: '100%' }}>
            <Button
              variant="outlined"
              onClick={() => {
                setSelectedForm(false);
              }}>
              Powrót
            </Button>
          </Stack>
        )}
        <Box sx={{ my: 2 }}>{selectedForm ? renderSelected() : renderMenu()}</Box>
      </ContentWrapper>
    </>
  );
};

export default PsychologicalSchemesContainer;
