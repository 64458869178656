/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { DailyScheduleEventModel, DailyScheduleModel } from '../models/digitalDiary/dailySchedule/DailyScheduleModel';

export default function dailySchedulesReducer(state = initialState.dailySchedules, action) {
  switch (action.type) {
    case types.CREATE_DAILY_SCHEDULE_SUCCESS: {
      return new DailyScheduleModel().assign({ ...state, id: action.dailyScheduleId });
    }
    case types.ADD_DAILY_SCHEDULE_ITEM_SUCCESS: {
      return new DailyScheduleModel().assign({ ...state, scheduleItems: [...state.scheduleItems, action.item] });
    }
    case types.UPDATE_DAILY_SCHEDULE_ITEM_SUCCESS: {
      const nextItems = state.scheduleItems.map((e) => {
        return e.id === action.item.id ? new DailyScheduleEventModel(action.item) : e;
      });

      return Object.assign({}, state, { scheduleItems: [...nextItems] });
    }
    case types.REMOVE_DAILY_SCHEDULE_ITEM_SUCCESS: {
      const nextItems = state.scheduleItems.filter((item) => item.id !== action.item.id);

      return Object.assign({}, state, { scheduleItems: [...nextItems] });
    }
    case types.LOAD_DAILY_SCHEDULE_SUCCESS: {
      return action.dailySchedules;
    }
    case types.LOAD_DAILY_SCHEDULE_FAILURE: {
      return new DailyScheduleModel();
    }
    default:
      return state;
  }
}
