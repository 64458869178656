import React from 'react';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import GroupsIcon from '@mui/icons-material/Groups';

import ContentWrapper from '../common/contentWrapper';

import SpecialistWorkNotesList from './SpecialistWorkNotesList';

const SpecialistWorkNotesPage = (params) => {
  return (
    <ContentWrapper>
      <PageHeaderText title="Zapis pracy specjalisty" titleIcon={<GroupsIcon />} />
      <SpecialistWorkNotesList />
    </ContentWrapper>
  );
};

export default SpecialistWorkNotesPage;
