import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as attendanceActions from '../../../actions/attendanceActions';
import EventsCalendarIcon from '@mui/icons-material/Event';
import GroupsAttendanceSummary from './GroupsAttendanceSummary';
import EventsList from './EventsList';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import UnitAttendanceSummary from './UnitAttendanceSummary';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { Box, Stack, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FooterText from '../../footer/FooterText';

class TeacherDashboardPage extends React.Component {
  componentDidMount() {
    this.props.actions.loadTodayAttendanceSummaryAsync();
  }

  render() {
    return (
      <LoadingRenderWrapper>
        <Box>
          <PageHeaderText title={`Witaj ${this.props.loggedUserName}`} titleIcon={<HomeOutlinedIcon />} />
          <UnitAttendanceSummary attendanceSummary={this.props.attendanceSummary} />
          {this.props.attendanceSummary.groups.length > 0 ? (
            <GroupsAttendanceSummary attendanceSummary={this.props.attendanceSummary.groups}>
              {this.props.children}
            </GroupsAttendanceSummary>
          ) : (
            <Typography
              sx={{
                color: (theme) => theme.palette.color.primary,
                pt: 3,
                pb: 5,
                pl: 4,
                fontWeight: (theme) => theme.typography.fontWeightBold
              }}
              variant="h6">
              Nie założono jeszcze żadnych grup
            </Typography>
          )}
          <Stack spacing={1} sx={{ mt: 3 }}>
            <Typography
              sx={{
                color: (theme) => theme.palette.color.primary,
                fontWeight: (theme) => theme.typography.fontWeightBold,
                opacity: 0.9,
                display: 'flex',
                alignItems: 'flex-end',
                my: 2
              }}
              component="h3"
              variant="h6">
              <EventsCalendarIcon fontSize="large" sx={{ mr: 2 }} /> Najbliższe wydarzenia
            </Typography>
            {this.props.todaysEvents.length > 0 ? (
              <EventsList events={this.props.todaysEvents} />
            ) : (
              <Typography
                sx={{
                  color: (theme) => theme.palette.color.primary,
                  pt: 3,
                  pb: 5,
                  pl: 4,
                  fontWeight: (theme) => theme.typography.fontWeightBold
                }}
                variant="h6">
                Brak wydarzeń na dziś
              </Typography>
            )}
            <Typography
              sx={{
                color: (theme) => theme.palette.color.primary,
                fontWeight: (theme) => theme.typography.fontWeightBold,
                opacity: 0.9,
                display: 'flex',
                alignItems: 'flex-end',
                my: '16px !important'
              }}
              component="h3"
              variant="h6">
              <EventsCalendarIcon fontSize="large" sx={{ mr: 2 }} /> Najnowsze ogłoszenia
            </Typography>
            {this.props.newestPosts.length > 0 ? (
              <EventsList events={this.props.newestPosts} dontDisplayHistory />
            ) : (
              <Typography
                sx={{
                  color: (theme) => theme.palette.color.primary,
                  pt: 3,
                  pb: 5,
                  pl: 4,
                  fontWeight: (theme) => theme.typography.fontWeightBold
                }}
                variant="h6">
                Brak ogłoszeń
              </Typography>
            )}
          </Stack>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'nowrap',
              mt: 4,
              mb: 2,
              color: (theme) => theme.palette.color.primary
            }}>
            <FooterText />
          </Box>
        </Box>
      </LoadingRenderWrapper>
    );
  }
}

TeacherDashboardPage.propTypes = {
  actions: PropTypes.object.isRequired,
  loggedUserName: PropTypes.string.isRequired,
  todaysEvents: PropTypes.array.isRequired,
  newestPosts: PropTypes.array.isRequired,
  attendanceSummary: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    loggedUserName: state.auth.userFullName,
    todaysEvents: state.messageBoardEvents.posts,
    newestPosts: state.messageBoardPosts.posts,
    attendanceSummary: state.staffDashboard.attendanceSummary,
    children: state.children
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(attendanceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TeacherDashboardPage);
