import { TextField, Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';  
import { NoteValidator } from './NoteValidator';
import DialogForm from '../../../forms/DialogForm';
import { createSpecialistNoteElement, editSpecialistNoteElement } from '../../../../actions/specialistNotesActions';
import DatePicker from '../../../common/DatePicker';
import TimePicker from '../../../common/TimePicker';

const NewNoteDialog = ({ handleCancel, selectedElement, onSave, specialistWorkRecordId }) => {
  const ui = useSelector((state) => state.specialistNotesUi.notes);
  const [errors, setErrors] = useState();
  const [title, setTitle] = useState(selectedElement?.title);
  const [content, setContent] = useState(selectedElement?.content);
  const [date, setDate] = useState(selectedElement ? selectedElement?.date : null);
  const [time, setTime] = useState(!!selectedElement?.time ? moment(selectedElement.time, 'HH:mm') : null);

  const dispatch = useDispatch();

  useEffect(() => {
    setTitle(selectedElement?.title);
    setContent(selectedElement?.content);
    setDate(selectedElement ? selectedElement?.date : null);
    setTime(!!selectedElement?.time ? moment(selectedElement.time, 'HH:mm') : null);
  }, [selectedElement]);

  const handleSave = () => {
    let data = {
      title: title,
      content: content,
      id: selectedElement?.id,
      date: !!date ? moment(date).format('YYYY-MM-DD') : date,
      time: !!time ? time?.format('HH:mm') : time,
      recordType: 'notes'
    };
    selectedElement ? dispatch(editSpecialistNoteElement(data, specialistWorkRecordId)) : dispatch(createSpecialistNoteElement(data, specialistWorkRecordId));
    onSave();
  };

  return (
    <>
      {ui.isEditing && (
        <DialogForm
          errors={errors}
          header={selectedElement ? 'Edycja notatki' : 'Nowa notatka'}
          maxWidth="md"
          onSave={handleSave}
          onCancel={() => handleCancel()}
          isDialogOpen={ui.isEditing}
          isProcessing={ui.isProcessing}
          onValidate={() => new NoteValidator().validate({ title: title, content: content, date: date, time: time })}
          onValidationDone={(errors) => setErrors(errors)}
          statePathToUi="specialistNotesUi.notes"
          requiredFieldText>
          <Box sx={{ my: 1 }}>
            <TextField
              sx={{ mb: 2 }}
              label="Podaj tytuł*"
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              defaultValue={!!selectedElement ? selectedElement.title : undefined}
              error={errors?.title}
              helperText={errors?.title}
            />
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mb: 2
            }}>
            <Grid item sx={{mb: 2}}>
              <DatePicker
                floatingLabelText="Data"
                onChange={(e, date) => setDate(date)}
                value={date}
                name="date"
                errorText={errors?.date}
              />
            </Grid>
            <Grid item sx={{mb: 2}}>
              <TimePicker
                floatingLabelText="Czas"
                onChange={(e, time) => setTime(time)}
                value={time}
                name="time"
                errorText={errors?.time}
              />
            </Grid>
          </Grid>
            <TextField
              multiline
              minRows={5}
              label="Podaj treść notatki*"
              onChange={(e) => setContent(e.target.value)}
              fullWidth
              defaultValue={!!selectedElement ? selectedElement.content : undefined}
              error={errors?.content}
              helperText={errors?.content}
            />
          </Box>
        </DialogForm>
      )}
    </>
  );
};

export default NewNoteDialog;
