import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import FullCalendar from '../../../calendar/FullCalendar';
import CalendarDay from '../../../calendar/Grid/CalendarDay';
import CalendarDayContent from '../../../calendar/Grid/CalendarDayContent';
import AbsentDayContent from './AbsentDayContent';
import AttendantDayContent from './AttendantDayContent';
import InvalidDayContent from './InvalidDayContent';
import UndeclaredDayContent from './UndeclaredDayContent';
import DayOffDayContent from './DayOffDayContent';
import ChildAttendanceSummary from './ChildAttendanceSummary';
import LoadingIndicatorWrapper from '../../../loading/LoadingIndicatorWrapper';
import moment from 'moment';
import { Box } from '@mui/system';

const ChildAttendanceCalendar = ({
  hidePaidDays,
  noDescription,
  allDays,
  showMore,
  markedDays,
  attendanceSummary,
  daysOff = [],
  onDateChange,
  onReportAttendance,
  onReportAbsence,
  onRevertAbsence,
  onDetailsOpen,
  isReadOnly,
  isParent = false,
  defaultYear,
  defaultMonth,
  isLoading = false
}) => {
  const excusedAbsences = useMemo(() => {
    if (!!allDays) {
      return (
        allDays.entries?.filter(
          (entry) => !!entry.absenceFrom && moment(entry.date).weekday() !== 5 && moment(entry.date).weekday() !== 6
        ).length - allDays.entries?.filter((entry) => entry.violatedSchemeDeadlines.length > 0).length
      );
    }
  }, [allDays]);

  const includeMarkedDays = (day) => {
    const isAttendant = !!(day.droppedOffAt && day.pickedUpAt);
    const isInvalid = !!(day.droppedOffAt && !day.pickedUpAt);
    const isAbsent = !!(day.absenceFrom && day.absenceTo);
    const isUndeclared = !isAttendant && !isInvalid && !isAbsent;
    const dayOff = daysOff.find((d) => moment(d.date).format('YYYY-MM-DD') === moment(day.date).format('YYYY-MM-DD'));
    const isDayOff = !!dayOff;
    return {
      date: moment(day.date).format('YYYY-MM-DD'),
      data: {
        ...day,
        isAttendant,
        isInvalid,
        isAbsent,
        isUndeclared,
        isDayOff,
        dayOffName: isDayOff ? dayOff.name : ''
      }
    };
  };
  const renderDayContent = (day, containerWidth, data) => {
    if (data.isAbsent) {
      return (
        <AbsentDayContent
          noDescription={noDescription}
          day={day}
          showMore={showMore}
          containerWidth={containerWidth}
          absenceNotes={data.absenceNotes}
          onReportAttendance={onReportAttendance}
          onRevertAbsence={onRevertAbsence}
          onDetailsOpen={onDetailsOpen}
          isReadOnly={isReadOnly}
          isParent={isParent}
          exceededAbsenceReportTime={!!day.data.violatedSchemeDeadlines.length}
          isWeekend={day.isWeekend}
        />
      );
    }
    if (data.isAttendant) {
      return (
        <AttendantDayContent
          day={day}
          containerWidth={containerWidth}
          droppedOffAt={data.droppedOffAt}
          pickedUpAt={data.pickedUpAt}
          pickedUpBy={data.pickedUpBy}
          onReportAttendance={onReportAttendance}
          onReportAbsence={onReportAbsence}
          onDetailsOpen={onDetailsOpen}
          isReadOnly={isReadOnly}
        />
      );
    }
    if (day.isWeekend || !day.isCurrentMonth) {
      return <CalendarDayContent day={day} containerWidth={containerWidth} />;
    }
    if (data.isDayOff) {
      return <DayOffDayContent day={day} containerWidth={containerWidth} dayOffName={data.dayOffName} />;
    }
    if (data.isInvalid) {
      return (
        <InvalidDayContent
          day={day}
          containerWidth={containerWidth}
          onReportAttendance={onReportAttendance}
          onReportAbsence={onReportAbsence}
          isReadOnly={isReadOnly}
        />
      );
    }
    if (data.isUndeclared) {
      return (
        <UndeclaredDayContent
          day={day}
          containerWidth={containerWidth}
          onReportAttendance={onReportAttendance}
          onReportAbsence={onReportAbsence}
          isReadOnly={isReadOnly}
        />
      );
    }
    return <CalendarDayContent day={day} containerWidth={containerWidth} />;
  };

  return (
    <LoadingIndicatorWrapper
      isProcessing={isLoading}
      shouldUnmountChildrenWhenProcessing={false}
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <FullCalendar
        markedDays={markedDays?.map((a) => includeMarkedDays(a)) || []}
        onYearOrMonthSelected={(year, month) => onDateChange(moment(new Date(year, month - 1, 1)))}
        onDaySelected={() => {}}
        onDayRender={(day, contentRender, onDaySelected, containerWidth, isDisabled) => (
          <CalendarDay
            clickable={false}
            extraClassName="no-hover"
            isDisabled={day.data.isDayOff || isDisabled}
            day={day}
            onDaySelected={onDaySelected}
            contentRender={contentRender}
            containerWidth={containerWidth}
          />
        )}
        onDayContentRender={(day, containerWidth) => renderDayContent(day, containerWidth, day.data)}
        defaultMonth={defaultMonth}
        defaultYear={defaultYear}
        header={
          <Box>
            {!noDescription && (
              <ChildAttendanceSummary
                hidePaidDays={hidePaidDays}
                summary={attendanceSummary}
                excusedAbsences={excusedAbsences}
              />
            )}
          </Box>
        }
      />
    </LoadingIndicatorWrapper>
  );
};

ChildAttendanceCalendar.propTypes = {
  markedDays: PropTypes.array.isRequired,
  attendanceSummary: PropTypes.object.isRequired,
  daysOff: PropTypes.array.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onDaySelected: PropTypes.func.isRequired,
  onReportAttendance: PropTypes.func.isRequired,
  onReportAbsence: PropTypes.func.isRequired,
  onRevertAbsence: PropTypes.func.isRequired,
  onDetailsOpen: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  defaultYear: PropTypes.string,
  defaultMonth: PropTypes.string,
  isLoading: PropTypes.bool,
  hidePaidDays: PropTypes.bool
};

export default ChildAttendanceCalendar;
