/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { StaffMemberModel } from '../models/staffMembers/StaffMemberModels';

export default function specialistsReducer(state = initialState.specialists, action) {
  switch (action.type) {
    case types.LOAD_SPECIALISTS: {
      return action.response.map((m) => new StaffMemberModel().assign(m));
    }
    default:
      return state;
  }
}
