import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import NavigationBar from './navigation/NavigationBar';
import NavigationDrawer from './navigation/NavigationDrawer';
import NotificationHandlerContainer from './notificationsHandler/NotificationHandlerContainer';
import LoadingWrapper from './common/loading/LoadingIndicatorWrapper';
import dimensions from 'react-dimensions';
import RightBottomNotification from './notificationsHandler/RightBottomNotification';
import { Alert, AlertTitle, Box, Drawer, SwipeableDrawer } from '@mui/material';
import backgroundAdorment from '../assets/svg/hero-bg-dots.svg';
import userRoles from '../constants/userRoles';

import { gdanskFromPrefixValidator } from '../utils/gdanskPrefixValidator';

const alertName = 'survey18102024'; // used keys: alertOpen, survey19112021, survey15022023

class MainApp extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isDrawerOpen: !this.isSmSize(),
      alertOpen: localStorage.getItem(alertName),
      gdanskPrefixValidator: gdanskFromPrefixValidator()
    };
    this.content = React.createRef();
    this.skipToContent = React.createRef();
    this.isSmSize = this.isSmSize.bind(this);
    this.onSkipToMainContent = this.onSkipToMainContent.bind(this);
    this.handleFocusOfSkipToContentAnchor = this.handleFocusOfSkipToContentAnchor.bind(this);
    this.handleBlurOfSkipToContentAnchor = this.handleBlurOfSkipToContentAnchor.bind(this);
    this.onAlertClose = this.onAlertClose.bind(this);
  }

  handleFocusOfSkipToContentAnchor() {
    this.skipToContent.current.className = 'skip-to-content-visible';
  }

  handleBlurOfSkipToContentAnchor() {
    this.skipToContent.current.className = 'skip-to-content-invisible';
  }

  onAlertClose() {
    localStorage.setItem(alertName, 'closed');
    this.setState({
      alertOpen: 'closed'
    });
  }

  onSkipToMainContent() {
    this.content.current.focus();
  }

  isSmSize() {
    return this.props.containerWidth <= 1150;
  }

  isPathAndWidthProper(path) {
    if (path === '/wiadomosci/moje' && this.props.containerWidth > 900) {
      return true;
    } else if (path === '/wiadomosci' && this.props.containerWidth > 1200) {
      return true;
    } else {
      return false;
    }
  }

  renderBar() {
    return (
      <NavigationBar
        title={this.props.unitName}
        onMenuButtonClick={() => {
          this.setState({
            isDrawerOpen: !this.state.isDrawerOpen
          });
        }}
        containerWidth={this.props.containerWidth}
      />
    );
  }

  renderDrawerContent() {
    return (
      <NavigationDrawer
        openChangeRequest={() => {
          this.onSkipToMainContent();
          this.setState({
            isDrawerOpen: !this.state.isDrawerOpen
          });
        }}
      />
    );
  }

  renderAppContent() {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box>
          <Drawer
            anchor="left"
            open={!this.isSmSize()}
            variant="permanent"
            PaperProps={{
              sx: {
                width: (theme) => theme.layout.drawerWidth,
                borderRadius: 0,
                zIndex: 9999
              }
            }}
            sx={{
              width: (theme) => (this.isSmSize() ? 0 : theme.layout.drawerWidth),
              height: '100vh',
              boxSizing: 'padding-box',
              display: this.isSmSize() ? 'none' : 'block',
              position: this.isSmSize() ? 'fixed' : 'sticky',
              top: 0,
              overflow: 'auto'
            }}>
            {this.renderDrawerContent()}
          </Drawer>
          <SwipeableDrawer
            onOpen={() => {}}
            PaperProps={{
              sx: {
                width: (theme) => theme.layout.drawerWidth,
                borderRadius: 0,

                zIndex: 9999
              }
            }}
            anchor="left"
            onClose={() => {
              this.setState({
                isDrawerOpen: !this.state.isDrawerOpen
              });
            }}
            open={this.state.isDrawerOpen && this.isSmSize()}
            sx={{
              height: '100vh',
              overflow: 'hidden'
            }}>
            <Box>{this.renderDrawerContent()}</Box>
          </SwipeableDrawer>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            overflowX: 'hidden',
            flexDirection: 'column',
            background: (theme) =>
              this.props.selectedTheme !== 'contrast'
                ? ` url(${backgroundAdorment}), transparent linear-gradient(33deg, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor2} 100%) 0% 0% no-repeat padding-box`
                : `  transparent linear-gradient(33deg, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor1} 0%, ${theme.palette.gradient.mainColor2} 100%) 0% 0% no-repeat padding-box`,
            height: '100vh',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}>
          {this.props.signedIn ? this.renderBar() : null}
          <Box
            ref={this.content}
            sx={{
              px: this.props.containerWidth <= 600 ? '16px' : '72px',
              overflow: 'auto',
              flexGrow: 1,
              transition: (theme) =>
                theme.transitions.create('margin', {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen
                })
            }}>
            <LoadingWrapper isProcessing={this.props.isLoading} shouldUnmountChildrenWhenProcessing={false}>
              <>
                {
                // !this.state.gdanskPrefixValidator &&
                //   (this.props.role === userRoles.staffMemberPrincipal ||
                //     this.props.role === userRoles.staffMemberAccountant ||
                //     this.props.role === userRoles.staffMemberSecretary) &&
                //   this.state.alertOpen !== 'closed' && (
                //     <Alert
                //       className="private-message-alert"
                //       severity="success"
                //       onClose={this.onAlertClose}
                //       sx={{
                //         mb: 2,
                //         mr: this.isPathAndWidthProper(this.props.children.props.location.pathname) && '250px'
                //       }}>
                //       <AlertTitle>
                //         Szanowni Państwo
                //         <br />
                //         <br />
                //         <strong>
                //           Zachęcamy Was do pobrania aplikacji mobilnej 4Parents!
                //         </strong>
                //         <br />
                //         Pracownicy korzystający z systemu, mogą tak jak rodzice, zalogować się do aplikacji
                //         4Parents.
                //         <br />
                //         W aplikacji będą mogli korzystać Państwo w takich modułów jak:
                //         <br />
                //         <br />
                //         - Dziennik obecności
                //         <br />
                //         - Dziennik obecności dla zajęć dodatkowych
                //         <br />
                //         - Wiadomości (wraz z chatem grupowym i zbiorczym wysyłaniem wiadomości do odbiorców)
                //         <br />
                //         - Ogłoszenia i wydarzenia (w tym ogłoszenia i wydarzenia wewnętrzne, dostępne tylko dla
                //         pracowników)
                //         <br />
                //         - Galeria
                //         <br />
                //         - Rejestr dzieci wraz z podglądem opiekunów prawnych i osób upoważnionych do odbioru
                //         <br />
                //         - Dysk wirtualny (odczyt plików)
                //         <br />
                //         <br />
                //         Chcielibyśmy podkreślić, że aplikacja nie ma na celu zastępowania Państwu całego systemu. Naszym
                //         celem jest usprawnienie Państwa pracy poprzez udostępnienie najważniejszych i najczęściej
                //         używanych funkcji. Na przykład, moduł rozliczeń jest na tyle obszerny, że nie będzie dostępny w
                //         aplikacji. Ze względu na wielkość tego modułu nie jest możliwe przeniesienie wszystkich jego
                //         funkcji do aplikacji w najbliższym czasie.
                //         <br />
                //         <br />
                //         Jeżeli spodoba się Państwu aplikacja - prosimy o pozytywną ocenę i opinię w sklepie
                //         Google/AppStore, co bardzo nas motywuje ❤️
                //       </AlertTitle>
                //       W przypadku pytań zapraszamy do kontaktu z biuro@4parents.pl
                //       <br />
                //       <br />
                //       <strong>Dziękujemy za Wasze wsparcie i zaufanie! Zespół 4Parents</strong>
                //     </Alert>
                //   ) 
                  }
                {this.props.children}
              </>
            </LoadingWrapper>
          </Box>
          <NotificationHandlerContainer />
          <RightBottomNotification />
        </Box>
      </Box>
    );
  }

  render() {
    return <>{this.renderAppContent()}</>;
  }
}

MainApp.propTypes = {
  unitActive: PropTypes.bool.isRequired,
  signedIn: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired,
  unitName: PropTypes.string.isRequired,
  containerWidth: PropTypes.number.isRequired,
  role: PropTypes.string.isRequired,
  selectedTheme: PropTypes.string
};

function mapStateToProps(state) {
  return {
    signedIn: state.auth.signedIn,
    role: state.auth.userRole,
    selectedTheme: state.theme,
    disc: state.virtualDiscFolder,
    isLoading: state.isLoading,
    unitActive: state.configuration.unit != null,
    unitName: state.configuration.unit && state.configuration.unit.address ? state.configuration.unit.address.name : ''
  };
}

export default connect(mapStateToProps)(dimensions()(MainApp));
