import PropTypes from 'prop-types';
import React from 'react';
import { MenuItem, Button, Stack } from '@mui/material';
import { tryConvertToFloat, tryConvertToNumber } from '../../../../utils/numberConverters';
import { afterHoursChargingBase } from '../../../../constants/afterHoursChargingBase';
import TextField from '../../../common/TextField';
import SelectField from '../../../common/SelectField';
import FieldsetWrapper from '../../../forms/FieldsetWrapper';

const UnitPenaltiesForm = ({ penalties, onChange, onClear, errors }) => {
  console.log(penalties);
  return (
    <Stack>
      <FieldsetWrapper legend="Dane naliczania kar">
        <TextField
          hintText="Ile wynosi stawka kary (zł)"
          floatingLabelText="Stawka (zł)*"
          floatingLabelFixed={false}
          autoFocus={true}
          name="afterHoursChargingRate"
          value={penalties.afterHoursChargingRate || ''}
          errorText={errors.afterHoursChargingRate}
          onBlur={(e) => {
            onChange({ target: { name: 'afterHoursChargingRate', value: tryConvertToNumber(e.target.value) } });
          }}
          onChange={onChange}
        />
        <SelectField
          contrast
          floatingLabelText="Kara naliczana za przekroczenie*"
          id="afterHoursChargingBase"
          value={penalties.afterHoursChargingBase || ''}
          fullWidth
          onChange={(event) => {
            onChange({ target: { name: 'afterHoursChargingBase', value: event.target.value } });
          }}
          errorText={errors.afterHoursChargingBase}
          style={{ margin: '10px 0' }}>
          {Object.values(afterHoursChargingBase).map((base) => {
            return (
              <MenuItem key={base.value} value={base.value}>
                {base.name}
              </MenuItem>
            );
          })}
        </SelectField>
        {penalties.afterHoursChargingBase !== afterHoursChargingBase.penaltyForExceededHours.value && (
          <>
            <TextField
              hintText="Kara naliczana za każde rozpoczęte (minuty)"
              floatingLabelText="Kara naliczana za każde rozpoczęte (minuty)*"
              floatingLabelFixed={false}
              name="afterHoursChargingInterval"
              value={penalties.afterHoursChargingInterval || ''}
              errorText={errors.afterHoursChargingInterval}
              fullWidth={true}
              onChange={(e) => {
                onChange({ target: { name: 'afterHoursChargingInterval', value: tryConvertToFloat(e.target.value) } });
              }}
            />
            <TextField
              hintText="Opóźnienie rozpoczęcia naliczania (minuty)"
              floatingLabelText="Opóźnienie rozpoczęcia naliczania (minuty)*"
              floatingLabelFixed={false}
              name="afterHoursChargingOffsetInMinutes"
              value={penalties.afterHoursChargingOffsetInMinutes || ''}
              errorText={errors.afterHoursChargingOffsetInMinutes}
              fullWidth={true}
              onChange={(e) => {
                onChange({
                  target: { name: 'afterHoursChargingOffsetInMinutes', value: tryConvertToFloat(e.target.value) }
                });
              }}
            />
          </>
        )}
        {penalties.afterHoursChargingBase === afterHoursChargingBase.penaltyForExceededHours.value && (
          <TextField
            hintText="Ile gopdzin wynosi maksymalny czas pobytu dziecka w placówce (bazowo 10)"
            floatingLabelText="Maksymalny godzinowy czas pobytu dziecka w placówce*"
            floatingLabelFixed={false}
            name="exceededHoursLimit"
            value={penalties.exceededHoursLimit || ''}
            errorText={errors.exceededHoursLimit}
            fullWidth={true}
            onChange={(e) => {
              onChange({ target: { name: 'exceededHoursLimit', value: tryConvertToFloat(e.target.value) } });
            }}
          />
        )}
      </FieldsetWrapper>
      <Button sx={{ alignSelf: 'end' }} onClick={onClear} color="error" aria-label="Wyczyść ustawienia">
        Wyczyść ustawienia
      </Button>
    </Stack>
  );
};

UnitPenaltiesForm.propTypes = {
  penalties: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default UnitPenaltiesForm;
