import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';
import api from '../api/SpecialistNotesApi';
import DigitalDiaryApi from '../api/DigitalDiaryApi';

export function setSpecialistGroupActivitiesDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.SPECIALIST_GROUP_ACTIVITIES_START });
  };
}

export function setSpecialistGroupActivitiesDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.SPECIALIST_GROUP_ACTIVITIES_CANCEL });
  };
}

export function loadSpecialistGroulActivitiesPlan(date, helpId) {
  return (dispatch) => {
    return api
      .loadSpecialistGroupActivities(date, helpId)
      .then((response) => dispatch({ type: types.LOAD_SPECIALIST_GROUP_ACTIVITIES, response }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać zajęć edukacyjnych w grupach`));
        return logger.error(error);
      });
  };
}

export function addSpecialistGroupActivities(data) {
  return (dispatch) => {
    return DigitalDiaryApi.addCustomNewEvent(data, data.eventType)
      .then((response) => {
        dispatch({ type: types.ADD_SPECIALIST_GROUP_ACTIVITIES_ELEMENT, data: response });
        dispatch(notificationActions.showSuccess('Dodano wpis do zajęć edukacyjnych w grupach'));
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            error?.response?.body?.generalError ||
              'Wystąpił błąd podczas dodawania wpisu do zajęć edukacyjnych w grupach'
          )
        );
        return logger.error(error);
      });
  };
}

export function editSpecialistGroupActivitiesElement(data) {
  return (dispatch) => {
    return DigitalDiaryApi.editCustomEvent(data)
      .then(() => {
        dispatch({ type: types.EDIT_SPECIALIST_GROUP_ACTIVITIES_ELEMENT, data });
        dispatch(notificationActions.showSuccess('Edytowano wpis zajęć edukacyjnych w grupach'));
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            error?.response?.body?.generalError || 'Wystąpił błąd podczas edycji wpisu zajęć edukacyjnych w grupach'
          )
        );
        return logger.error(error);
      });
  };
}

export function deleteSpecialistGroupActivitiesElement(recordId) {
  return (dispatch) => {
    return DigitalDiaryApi.deleteEvent(recordId)
      .then(() => {
        dispatch(notificationActions.showSuccess('Usunięto wpis zajęć edukacyjnych w grupach'));
        dispatch({ type: types.DELETE_SPECIALIST_GROUP_ACTIVITIES_ELEMENT, id: recordId });
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć wpisu`));
        return logger.error(error);
      });
  };
}
