import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';

const SelectField = ({
  children,
  value,
  onChange,
  errorText,
  floatingLabelText,
  style,
  fullWidth,
  inputProps,
  displayEmpty,
  id,
  contrast,
  selectStyle,
  autoWidth,
  disabled,
  multiple,
  renderValue,
  sx
}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref && ref.current)
      ref.current.children[1].setAttribute('aria-label', `input-${floatingLabelText || 'select'}`);
  }, [floatingLabelText]);
  const labelId = id ? `select-${id}` : null;
  return (
    <FormControl
      sx={{ minWidth: `${fullWidth ? '100%' : '50px'}`, mr: 1, ...style, ...sx }}
      error={!!errorText}
      variant="standard">
      {floatingLabelText && (
        <InputLabel
          htmlFor={labelId}
          sx={{
            color: contrast ? (theme) => theme.palette.color.contrast : (theme) => theme.palette.color.primary,
            '&.Mui-focused': {
              color: contrast ? (theme) => theme.palette.color.contrast : (theme) => theme.palette.color.primary
            }
          }}>
          {floatingLabelText}
        </InputLabel>
      )}
      <Select
        inputProps={{
          id: labelId,
          ...inputProps
        }}
        // sx={
        //   contrast && {
        //     color: (theme) => theme.palette.color.color2,
        //     borderBottom: (theme) => `1px solid ${theme.palette.color.color2}`,
        //     '&:hover': {
        //       borderBottom: (theme) => `1px solid ${theme.palette.color.color2}`
        //     },
        //     '& .MuiSvgIcon-root': {
        //       color: (theme) => theme.palette.color.color2
        //     }
        //   }
        // }
        sx={
          contrast && {
            color: contrast ? (theme) => theme.palette.color.color2 : 'inherit',
            borderBottom: errorText ? '2px solid red' : '1px solid rgba(0, 0, 0, 0.42)',
            '&:hover': {
              borderBottom: errorText ? '2px solid red' : '1px solid rgba(0, 0, 0, 0.87)'
            },
            '&.Mui-focused': {
              borderBottom: errorText ? '2px solid red' : '2px solid rgba(0, 0, 0, 0.87)'
            },
            '& .MuiSvgIcon-root': {
              color: contrast ? (theme) => theme.palette.color.color2 : 'inherit'
            }
          }
        }
        ref={ref}
        id={id}
        disableUnderline
        autoWidth={autoWidth}
        variant="standard"
        value={value || ''}
        onChange={onChange}
        displayEmpty={displayEmpty}
        disabled={disabled}
        multiple={multiple}
        renderValue={renderValue}
        style={{ ...selectStyle }}>
        {children}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
};

SelectField.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  errorText: PropTypes.any,
  floatingLabelText: PropTypes.string,
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.object,
  sx: PropTypes.object,
  displayEmpty: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  selectStyle: PropTypes.object,
  autoWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  contrast: PropTypes.bool,
  multiple: PropTypes.bool,
  renderValue: PropTypes.func
};

export default SelectField;
