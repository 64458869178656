import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SortedTableCell from '../../table/SortedTableCell';
import PropTypes from 'prop-types';
import React from 'react';
import * as definedSortType from '../../../constants/definedSortTypes';
import { Checkbox, TableCell } from '@mui/material';

const sortedCells = [
  {
    id: 'firstName',
    label: 'Imię',
    type: definedSortType.STRING_SORT
  },
  {
    id: 'lastName',
    label: 'Nazwisko',
    type: definedSortType.STRING_SORT
  },
  {
    id: 'calculatedTotal',
    label: 'Pełna Kwota',
    type: definedSortType.STRING_NUMBER_SORT
  }
];

const OverdueListHeader = ({ orderBy, onRequestSort, order, setNotificationList, filteredIds, checked }) => {
  return (
    <TableHead>
      <TableRow>
        {sortedCells.map((cell) => (
          <SortedTableCell
            key={cell.id}
            order={order}
            orderBy={orderBy.property}
            onRequestSort={onRequestSort}
            cell={cell}
          />
        ))}
        <TableCell>
          <Checkbox
            checked={checked}
            onChange={(e) => {
              setNotificationList(e.target.checked ? [...filteredIds] : []);
            }}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

OverdueListHeader.propTypes = {
  orderBy: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired
};

export default OverdueListHeader;
