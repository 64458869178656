import PropTypes from 'prop-types';
import React from 'react';
import PostHeader from '../../messageBoard/post/PostHeader';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  Stack,
  Divider
} from '@mui/material';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';

class EventDetailsDialog extends React.Component {
  render() {
    const {
      header,
      events,
      isDialogOpen,
      onRequestClose,
      onShowEventFullDetails,
      onCreateNewEvent,
      isGroupArchived,
      detailsOnly = false
    } = this.props;
    return (
      <Dialog open={isDialogOpen} onClose={onRequestClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <EventOutlinedIcon />
          <Typography variant="h6" component="p" sx={{ mt: 1, ml: 1 }}>
            {header}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {events.map((e) => (
            <Box key={e.id} sx={{ py: 1 }}>
              <PostHeader post={e} />
              {events.length > 1 && <Divider />}
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Stack
            sx={{
              width: '100%',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: { xs: '', sm: 'end' },
              alignItems: 'center'
            }}>
            <Button
              variant="outlinedContrast"
              onClick={onShowEventFullDetails}
              disabled={!events.length}
              aria-label="Pokaż szczegóły">
              Pokaż szczegóły
            </Button>
            {!detailsOnly && (
              <Button
                variant="contained"
                disabled={isGroupArchived}
                color="primary"
                onClick={onCreateNewEvent}
                aria-label="Nowe wydarzenie">
                Nowe Wydarzenie
              </Button>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
    );
  }
}

EventDetailsDialog.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isDialogOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  onShowEventFullDetails: PropTypes.func.isRequired,
  onCreateNewEvent: PropTypes.func.isRequired,
  isGroupArchived: PropTypes.bool
};

export default EventDetailsDialog;
