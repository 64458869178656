import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import FooterText from '../footer/FooterText';

const ContentWrapper = ({ children, disableAdnotation }) => {
  return (
    <Box
      sx={{
        ml: 0,
        height: 'calc(100vh - 160px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
      <Box>{children}</Box>
      {!disableAdnotation && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'nowrap',

            mt: 4,
            mb: 3,
            color: (theme) => theme.palette.color.primary
          }}>
          <FooterText />
        </Box>
      )}
    </Box>
  );
};

ContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  disableAdnotation: PropTypes.bool
};

export default ContentWrapper;
