const userRoleNames = Object.freeze({
  legalGuardian: { value: 'legalGuardian', name: 'opiekun prawny' },
  staffMemberTeacher: { value: 'staffMemberTeacher', name: 'nauczyciel' },
  staffMemberPrincipal: { value: 'staffMemberPrincipal', name: 'dyrektor' },
  staffMemberAccountant: { value: 'staffMemberAccountant', name: 'księgowy' },
  staffMemberCanteenWorker: { value: 'staffMemberCanteenWorker', name: 'katering' },
  staffMemberDigitalDiary: { value: 'staffMemberDigitalDiary', name: 'nauczyciel - zajecia dodatkowe' },
  staffMemberSecretary: { value: 'staffMemberSecretary', name: 'sekretariat' },
  staffMemberSpecialist: { value: 'staffMemberSpecialistTeacher', name: 'specjalista' }
});

export default userRoleNames;
