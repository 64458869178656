import BaseModel from '../../BaseModel';
import moment from 'moment';

export class DailyScheduleEventModel extends BaseModel {
  constructor(event) {
    super();
    this.id = event?.id ?? undefined;
    this.startTime = event?.startTime ?? moment(new Date()).format('HH:mm');
    this.endTime = event?.endTime ?? moment(new Date()).format('HH:mm');
    this.title = event?.title ?? '';
    this.description = event?.description ?? '';
  }
}

export class DailyScheduleModel extends BaseModel {
  constructor() {
    super();
    this.id = null;
    this.groupId = '';
    this.year = moment().year();
    this.scheduleItems = [];
  }

  onAssignment(assigned) {
    assigned.scheduleItems = assigned?.scheduleItems.map((event) => new DailyScheduleEventModel().assign(event));
  }
}
