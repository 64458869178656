import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';
import api from '../api/SpecialistNotesApi';
import DigitalDiaryApi from '../api/DigitalDiaryApi';

export function setSpecialistClassScheduleDialogOpen() {
  return (dispatch) => {
    dispatch({ type: types.SPECIALIST_CLASS_SCHEDULE_START });
  };
}

export function setSpecialistClassScheduleDialogClosed() {
  return (dispatch) => {
    dispatch({ type: types.SPECIALIST_CLASS_SCHEDULE_CANCEL });
  };
}

export function loadSpecialistClassSchedulePlan(date, helpId) {
  return (dispatch) => {
    return api
      .loadSpecialistClassSchedulePlan(date, helpId)
      .then((response) => dispatch({ type: types.LOAD_SPECIALIST_CLASSES_SCHEDULE, response }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać rozkładu zajęć`));
        return logger.error(error);
      });
  };
}

export function addSpecialistClassSchedule(data) {
  return (dispatch) => {
    return DigitalDiaryApi.addCustomNewEvent(data, data.eventType)
      .then((response) => {
        dispatch({ type: types.ADD_SPECIALIST_CLASS_SCHEDULE_ELEMENT, data: response });
        dispatch(notificationActions.showSuccess('Dodano wpis do rozkładu zajęć'));
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            error?.response?.body?.generalError || 'Wystąpił błąd podczas dodawania wpisu do rozkładu zajęć'
          )
        );
        return logger.error(error);
      });
  };
}

export function editSpecialistClassScheduleElement(data) {
  return (dispatch) => {
    return DigitalDiaryApi.editCustomEvent(data)
      .then(() => {
        dispatch({ type: types.EDIT_SPECIALIST_CLASS_SCHEDULE_ELEMENT, data });
        dispatch(notificationActions.showSuccess('Edytowano wpis z rozkładu zajęć'));
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            error?.response?.body?.generalError || 'Wystąpił błąd podczas edycji wpisu z rozkładu zajęć'
          )
        );
        return logger.error(error);
      });
  };
}

export function deleteSpecialistClassScheduleElement(recordId) {
  return (dispatch) => {
    return DigitalDiaryApi.deleteEvent(recordId)
      .then(() => {
        dispatch(notificationActions.showSuccess('Usunięto wpis z rozkładu zajęć'));
        dispatch({ type: types.DELETE_SPECIALIST_CLASS_SCHEDULE_ELEMENT, id: recordId });
      })
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się usunąć wpisu`));
        return logger.error(error);
      });
  };
}
