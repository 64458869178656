import initialState from './initial';
import * as types from '../actions/actionTypes';
import moment from 'moment';

export default function psychologicalIndividualAttendanceReducer(
  state = initialState.psychologicalIndividualAttendances,
  action
) {
  switch (action.type) {
    case types.LOAD_INDIVIDUAL_CLASSES_ATTENDANCE: {
      return action.payload;
    }
    //tutaj normalnie powinien przyjsć gotowy response, żeby nie było potrzeby modyfikować kluczy ze state
    case types.REPORT_INDIVIDUAL_ATTENDANCE: {
      let newState = Object.assign({}, state);

      let idx = newState.childrenAttendance.findIndex((a) => a.assistance.childId === action.childId);
      newState.childrenAttendance = [...state.childrenAttendance];
      newState.childrenAttendance[idx].droppedOffAt = moment();
      newState.childrenAttendance[idx].pickedUpAt = moment();
      newState.childrenAttendance[idx].absenceFrom = null;
      newState.childrenAttendance[idx].absenceTo = null;
      return newState;
    }
    //tutaj normalnie powinien przyjsć gotowy response, żeby nie było potrzeby modyfikować kluczy ze state
    case types.REPORT_INDIVIDUAL_ABSENCE: {
      let newState = Object.assign({}, state);
      let idx = newState.childrenAttendance.findIndex((a) => a.assistance.childId === action.childId);
      newState.childrenAttendance = [...state.childrenAttendance];
      newState.childrenAttendance[idx].droppedOffAt = null;
      newState.childrenAttendance[idx].pickedUpAt = null;
      newState.childrenAttendance[idx].absenceFrom = moment().format('YYYY-MM-DD');
      newState.childrenAttendance[idx].absenceTo = moment().format('YYYY-MM-DD');
      return newState;
    }
    default: {
      return state;
    }
  }
}
