import React from 'react';
import { useSelector } from 'react-redux';
import EventsList from './EventsList';
import EventsCalendarIcon from '@mui/icons-material/Event';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import { Box, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FooterText from '../../footer/FooterText';

const ReducedTeacherDashboardPage = () => {
  const loggedUserName = useSelector((state) => state.auth.userFullName);
  const todaysEvents = useSelector((state) => state.messageBoardEvents.posts);
  const newestPosts = useSelector((state) => state.messageBoardPosts.posts);

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title={`Witaj ${loggedUserName}`} titleIcon={<HomeOutlinedIcon />} />
        <Typography
          sx={{
            color: (theme) => theme.palette.color.primary,
            fontWeight: (theme) => theme.typography.fontWeightBold,
            opacity: 0.9,
            display: 'flex',
            alignItems: 'flex-end',
            my: 2
          }}
          component="h3"
          variant="h6">
          <EventsCalendarIcon fontSize="large" sx={{ mr: 2 }} /> Najbliższe wydarzenia
        </Typography>
        {todaysEvents.length > 0 ? (
          <EventsList events={todaysEvents} />
        ) : (
          <Typography
            sx={{
              color: (theme) => theme.palette.color.primary,
              pt: 3,
              pb: 5,
              pl: 4,
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}
            variant="h6">
            Brak wydarzeń na dziś
          </Typography>
        )}
        <Typography
          sx={{
            color: (theme) => theme.palette.color.primary,
            fontWeight: (theme) => theme.typography.fontWeightBold,
            opacity: 0.9,
            display: 'flex',
            alignItems: 'flex-end',
            my: '16px !important'
          }}
          component="h3"
          variant="h6">
          <EventsCalendarIcon fontSize="large" sx={{ mr: 2 }} /> Najnowsze ogłoszenia
        </Typography>
        {newestPosts.length > 0 ? (
          <EventsList events={newestPosts} />
        ) : (
          <Typography
            sx={{
              color: (theme) => theme.palette.color.primary,
              pt: 3,
              pb: 5,
              pl: 4,
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}
            variant="h6">
            Brak ogłoszeń
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'nowrap',
            mt: 4,
            mb: 2,
            color: (theme) => theme.palette.color.primary
          }}>
          <FooterText />
        </Box>
      </Box>
    </LoadingRenderWrapper>
  );
};

export default ReducedTeacherDashboardPage;
