import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TextField, Grid } from '@mui/material';
import DialogForm from '../../forms/DialogForm';
import TimePicker from '../../common/TimePicker';
import ClearButtonWrapper from '../../common/ClearButtonWrapper';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import { DailyScheduleEventValidator } from '../../../models/digitalDiary/dailySchedule/DailyScheduleValidator'
import EventNote from '@mui/icons-material/FeedbackOutlined';
import moment from 'moment';

const FrameworkDayScheduleEditDialog = ({ event, items, onSave, onCancel, isDialogOpen, isProcessing, isMobile, errors }) => {
  const [dailyScheduleItem, setDailyScheduleItem] = useState(event);
  const [itemErrors, setItemErrors] = useState({})

  const handleTitleChange = (event) => {
    setDailyScheduleItem({ ...dailyScheduleItem, title: event.target.value });
  };

  const handleDescriptionChange = (event) => {
    setDailyScheduleItem({ ...dailyScheduleItem, description: event.target.value });
  };

  const handleStartTimeChange = (time) => {
    setDailyScheduleItem({ ...dailyScheduleItem, startTime: time?.format('HH:mm') });
  };

  const handleEventEndTimeChange = (time) => {
    setDailyScheduleItem({ ...dailyScheduleItem, endTime: time?.format('HH:mm') });
  };

  return (
    <DialogForm
      onSave={() => {
        onSave(dailyScheduleItem);
      }}
      onCancel={() => onCancel(dailyScheduleItem)}
      onValidate={() => new DailyScheduleEventValidator().validate({id: dailyScheduleItem.id,
                                                                    title: dailyScheduleItem.title,
                                                                    description: dailyScheduleItem.description,
                                                                    startTime: moment(dailyScheduleItem.startTime, 'HH:mm'),
                                                                    endTime: moment(dailyScheduleItem.endTime, 'HH:mm'),
                                                                    items})}
      onValidationDone={(errors) => {
        setItemErrors(errors);
      }}
      isDialogOpen={isDialogOpen}
      isProcessing={isProcessing}
      sx={{ fontFamily: 'League Spartan' }}
      icon={<EventNote fontSize="large" />}
      header="Utwórz wpis"
      statePathToUi="dailySchedulesUiReducer">
      <FieldsetWrapper
        legend="Pola opisujące tworzony wpis">
        <TextField
          sx={{
            fontSize: 24,
            lineHeight: 36,
            mb: 2
          }}
          label="Tytuł*"
          autoFocus={true}
          name="title"
          id="title"
          variant="standard"
          defaultValue={dailyScheduleItem.title}
          onChange={handleTitleChange}
          fullWidth={true}
          error={!!itemErrors?.title}
          helperText={itemErrors?.title}
          FormHelperTextProps={{ role: 'status' }}
        />
        <TextField
          sx={{
            fontSize: 24,
            lineHeight: 36,
            mb: 2
          }}
          label="Opis*"
          autoFocus={false}
          name="description"
          id="content"
          multiline
          minRows="8"
          defaultValue={dailyScheduleItem.description}
          onChange={handleDescriptionChange}
          fullWidth={true}
          error={!!itemErrors?.description}
          helperText={itemErrors?.description}
          FormHelperTextProps={{ role: 'status' }}
        />
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row'
          }}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ClearButtonWrapper onClear={() => handleStartTimeChange(null)} contrast>
              <TimePicker
                floatingLabelText="Rozpoczęcie*"
                onChange={(e, time) => handleStartTimeChange(time)}
                value={dailyScheduleItem.startTime ? moment(dailyScheduleItem.startTime, 'HH:mm').toDate() : null}
                name="startTime"
                errorText={itemErrors?.startTime || itemErrors?.items}
              />
            </ClearButtonWrapper>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ClearButtonWrapper onClear={() => handleEventEndTimeChange(null)} contrast>
              <TimePicker
                floatingLabelText="Zakończenie*"
                onChange={(e, time) => handleEventEndTimeChange(time)}
                value={dailyScheduleItem.endTime ? moment(dailyScheduleItem.endTime, 'HH:mm').toDate() : null}
                name="endTime"
                errorText={itemErrors?.endTime || itemErrors?.items}
              />
            </ClearButtonWrapper>
          </Grid>
        </Grid>
      </FieldsetWrapper>
    </DialogForm>
  );
};

FrameworkDayScheduleEditDialog.propTypes = {
  event: PropTypes.object,
  items: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isDialogOpen: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  errors: PropTypes.object
};

export default FrameworkDayScheduleEditDialog;
