import moment from 'moment';

export const sortByDateTime = (data) => {
  return data.sort((a, b) => {
    const aStartHour = a.eventStartTime?.hourLocal ?? 0;
    const aStartMinute = a.eventStartTime?.minuteLocal ?? 0;
    const bStartHour = b.eventStartTime?.hourLocal ?? 0;
    const bStartMinute = b.eventStartTime?.minuteLocal ?? 0;

    const aEndHour = a.eventEndTime?.hourLocal ?? 0;
    const aEndMinute = a.eventEndTime?.minuteLocal ?? 0;
    const bEndHour = b.eventEndTime?.hourLocal ?? 0;
    const bEndMinute = b.eventEndTime?.minuteLocal ?? 0;

    const aStartDateTime = moment(a.eventDate).hour(aStartHour).minute(aStartMinute);
    const bStartDateTime = moment(b.eventDate).hour(bStartHour).minute(bStartMinute);

    if (aStartDateTime.isSame(bStartDateTime)) {
      const aEndDateTime = moment(a.eventDate).hour(aEndHour).minute(aEndMinute);
      const bEndDateTime = moment(b.eventDate).hour(bEndHour).minute(bEndMinute);

      return aEndDateTime.isAfter(bEndDateTime) ? 1 : -1;
    }

    return aStartDateTime.isAfter(bStartDateTime) ? 1 : -1;
  });
};

// export const simpleSortByDateTime = (data) => {
//   return data.sort((a, b) => {
//     const aDateTime = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD HH:mm');
//     const bDateTime = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD HH:mm');

//     return aDateTime.isAfter(bDateTime) ? 1 : -1;
//   });
// };

export const simpleSortByDateTime = (data) => {
  return data.sort((a, b) => {
    const aDate = a.date || '';
    const bDate = b.date || '';

    const aTime = a.time || '00:00';
    const bTime = b.time || '00:00';

    if (aDate && bDate) {
      const aDateTime = moment(`${aDate} ${aTime}`, 'YYYY-MM-DD HH:mm');
      const bDateTime = moment(`${bDate} ${bTime}`, 'YYYY-MM-DD HH:mm');
      return aDateTime.isAfter(bDateTime) ? 1 : -1;
    }

    if (aDate && !bDate) return -1;
    if (!aDate && bDate) return 1;

    const aTimeOnly = moment(aTime, 'HH:mm');
    const bTimeOnly = moment(bTime, 'HH:mm');
    return aTimeOnly.isAfter(bTimeOnly) ? 1 : -1;
  });
  // return data.sort((a, b) => {
  //   const aHasDate = Boolean(a.date);
  //   const bHasDate = Boolean(b.date);
  //   const aHasTime = Boolean(a.time);
  //   const bHasTime = Boolean(b.time);

  //   if (!aHasDate && !aHasTime && (bHasDate || bHasTime)) return -1;
  //   if (!bHasDate && !bHasTime && (aHasDate || aHasTime)) return 1;

  //   if (!aHasDate && aHasTime && (bHasDate || (!bHasDate && !bHasTime))) return -1;
  //   if (!bHasDate && bHasTime && (aHasDate || (!aHasDate && !aHasTime))) return 1;

  //   if (aHasDate && aHasTime && bHasDate && bHasTime) {
  //     const aDateTime = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD HH:mm');
  //     const bDateTime = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD HH:mm');
  //     return aDateTime.isAfter(bDateTime) ? 1 : -1;
  //   }

  //   return 0;
  // });
};
