import ApiBase, { ROOT_URL } from './ApiBase';

class DailySchedulesApi extends ApiBase {

  static createDailySchedule(schedule ) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/dailySchedule/`).send(schedule).type('application/json')
    );
  }

  static getDailyScheduleByGroupAndYear(groupId, year) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/dailySchedule/group/${groupId}/${year}`).type('application/json')
    );
  }

  static getAllDailySchedules() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/dailySchedule/`).type('application/json')
    );
  }

  static getDailyScheduleById({ scheduleId }) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/dailySchedule/${scheduleId}`).type('application/json')
    );
  }

  static addItemToDailySchedule(scheduleId, item) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/dailySchedule/${scheduleId}/items`).send(item).type('application/json')
    );
  }

  static deleteItemFromDailySchedule(scheduleId, itemId) {
    return super.toPromise((agent) =>
      agent.delete(`${ROOT_URL}/dailySchedule/${scheduleId}/items/${itemId}`).type('application/json')
    );
  }

  static updateDailyScheduleItem(scheduleId, itemId, item) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/dailySchedule/${scheduleId}/items/${itemId}`).send(item).type('application/json')
    );
  }
}

export default DailySchedulesApi;
