import React from 'react';

import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import _ from 'lodash';
import userRoles from '../../../../constants/userRoles';
import EmptyState from '../../../common/EmptyState';
import SpecialistPostCard from './SpecialistPostCard';
import { sortByDateTime } from '../../utils/sortByDateTime';

const SpecialistEventsList = ({ posts, onEdit, onDelete, editable }) => {
  const sortedArray = sortByDateTime(posts);

  const auth = useSelector((state) => state.auth);

  if (sortedArray.length === 0) {
    return <EmptyState contrast isPaper message="Brak wpisów" />;
  }

  const canEdit = (post) => {
    if (typeof editable !== 'undefined') {
      return editable;
    }
    return post.author.id === auth.userId || auth.userRole === userRoles.staffMemberPrincipal;
  };

  return (
    <Box>
      {sortedArray.map((post) => {
        return (
          <Box sx={{ my: 2 }} key={post?.id || _.uniqueId()}>
            <SpecialistPostCard
              id={post.id}
              key={post.id}
              post={post}
              canEdit={canEdit(post)}
              onEdit={() => onEdit(post)}
              onDelete={() => onDelete(post)}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default SpecialistEventsList;
