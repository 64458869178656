import React from 'react';
import ContactComponent from './components/ContactComponent';
import { routePaths } from '../../routePaths';
import GroupClassesCalendar from './components/GroupClassesCalendar';
import SpecialistNotesList from './SpecialistNotesList';
import SpecialistObservationsList from './SpecialistObservationsList';
import ClassScheduleCalendar from './components/ClassScheduleCalendar';
import WorkPlanList from './WorkPlanList';
import WorkPlanProgressCalendar from './components/WorkPlanProgressCalendar';
import { Box } from '@mui/material';
import AccordionElement from './components/Accordion';

const SpecialistWorkNotesDetails = ({ workId }) => {
  return (
    <Box>
      <AccordionElement
        title="Rozkład zajęć"
        content={<ClassScheduleCalendar workId={workId} routePath={routePaths.specialistClassScheduleDetails} />}
      />
      <AccordionElement title="Plan pracy" content={<WorkPlanList workId={workId} />} />
      <AccordionElement
        title="Realizacja planu pracy"
        content={<WorkPlanProgressCalendar workId={workId} routePath={routePaths.specialistWorkPlanProgressDetails} />}
      />
      <AccordionElement title="Kontakt z rodzicami/instytucjami" content={<ContactComponent workId={workId} />} />
      <AccordionElement title="Obserwacje w grupie" content={<SpecialistObservationsList />} />
      <AccordionElement
        title="Zajęcia edukacyjne w grupach"
        content={<GroupClassesCalendar workId={workId} routePath={routePaths.specialistGroupActivitiesDetails} />}
      />
      <AccordionElement title="Notatki" content={<SpecialistNotesList />} />
    </Box>
  );
};

export default SpecialistWorkNotesDetails;
