import BaseModel from '../BaseModel';
import { AuthorModel } from '../digitalDiary/digitalDiaryEvent/DigitalDiaryEventModel';
import moment from 'moment';
import { TimeModel } from '../TimeModel';
import { BaseModelValidator } from '../BaseModelValidator';
import { check } from '../validation/validateHelper';

export class SpecialistGroupActivitiesModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.title = '';
    this.content = '';
    this.author = new AuthorModel();
    this.availableToAll = false;
    this.eventDate = '';
    this.eventStartTime = new TimeModel();
    this.eventEndTime = new TimeModel();
    this.eventType = '';
    this.publishedAt = Date.now();
  }

  onAssignment(assigned) {
    /* eslint no-param-reassign: 'off' */
    assigned.author = new AuthorModel().assign(assigned.author);
    assigned.eventDate = assigned.eventDate ? moment(assigned.eventDate).format('YYYY-MM-DD') : '';
    assigned.eventStartTime = new TimeModel().assign(assigned.eventStartTime);
    assigned.eventEndTime = new TimeModel().assign(assigned.eventEndTime);
  }
}

export class SpecialistGroupActivitiesValidator extends BaseModelValidator {
  constructor() {
    super();
    this.model = SpecialistGroupActivitiesModel;

    this.constraints = {
      title: (value) => {
        if (check.isEmpty(value)) return 'Nie podano tytułu';
        return null;
      },
      eventDate: (value) => {
        if (check.isEmpty(value)) return 'Nie podano daty zdarzenia';
        if (!moment(value).isValid()) return 'Niepoprawna data';
        if (!moment(value).isSameOrBefore(moment(new Date()).add(2, 'year')))
          return `Wybierz datę wcześniejszą niż ${moment(new Date()).add(2, 'year').format('DD/MM/YYYY')}`;
        return null;
      },
      eventStartTime: (value, model) => {
        if (value.isAfter(model.eventEndTime))
          return `Zadeklarowana godzina musi być wcześniejsza niż ${model.eventEndTime.toString()}`;
        if (!value.isEmpty() && !moment(value, 'hh:mm').isValid()) return 'Niepoprawny czas';
        return null;
      },
      eventEndTime: (value) => {
        if (!value.isEmpty() && !moment(value, 'hh:mm').isValid()) return 'Niepoprawny czas';
        return null;
      },
      content: (value) => {
        const cleanText = value.replace(/<\/?[^>]+(>|$)/g, '');
        if (cleanText.trim().length === 0) return 'Nie podano treści ogłoszenia';
        return null;
      }
    };
  }
}
