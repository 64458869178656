import React from 'react';
import PropTypes from 'prop-types';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import GroupsIcon from '@mui/icons-material/Groups';
import SpecialistWorkNotesDetails from './SpecialistWorkNotesDetails';
import { Box } from '@mui/material';

const SpecialistWorkNotesDetailsPage = ({ params }) => {
  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeaderText title="Zapis pracy specjalisty" titleIcon={<GroupsIcon />} />
        <SpecialistWorkNotesDetails workId={params.id} />
      </Box>
    </LoadingRenderWrapper>
  );
};

SpecialistWorkNotesDetailsPage.alerpropTypes = {
  location: PropTypes.object.isRequired
};

export default SpecialistWorkNotesDetailsPage;
