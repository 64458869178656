/*eslint indent:0*/
import initialState from './initial';
import * as types from '../actions/actionTypes';
import { UiModel } from '../models/UiModel';

export default function registerPaymentsUiReducer(state = initialState.registerPaymentsUi, action) {
  switch (action.type) {
    case types.REGISTER_PAYMENTS: {
      return UiModel.start();
    }
    case types.REGISTER_PAYMENTS_CANCEL: {
      return UiModel.cancel();
    }
    case types.REGISTER_PAYMENTS_SUBMIT: {
      return UiModel.submit();
    }
    case types.REGISTER_PAYMENTS_SUCCESS: {
      return UiModel.success();
    }
    case types.REGISTER_PAYMENTS_FAILURE: {
      return UiModel.failure(action.errors);
    }
    //
    case types.NAVIGATE_REQUEST: {
      return Object.assign({}, initialState.registerPaymentsUi);
    }
    default:
      return state;
  }
}
