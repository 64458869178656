import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography, Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIsSpecialist } from '../../../../hooks/useIsSpecialist';
import {
  createNewObservationElement,
  deleteObservationElement,
  editObservationElement,
  setObservationsDialogClosed,
  setObservationsDialogOpen
} from '../../../../actions/psychologicalIndividualJournalClassesActions';
import { NoteModel } from '../../../../models/psychological/notes/NoteModel';
import IndividualJournalObservation from './IndividualJournalObservation';
import EmptyState from '../../../common/EmptyState';
import NotesAndObservationsDialog from '../../../individualClassesDetails/NotesAndObservationsDialog';
import { simpleSortByDateTime } from '../../../specialistNotes/utils/sortByDateTime';

const IndividualJournalObservationsList = () => {
  const dispatch = useDispatch();

  const observations = useSelector((state) => state.psychologicalIndividualJournalWorkProgram.observations);
  const Ui = useSelector((state) => state.psychologicalIndividualJournalUi.observations);
  const [currentObservation, setCurrentObservation] = useState(null);
  const assistanceId = useSelector((state) => state.psychologicalIndividualJournalWorkProgram.id);
  const { isSpecialistOrPrincipal } = useIsSpecialist();

  const handleSave = (note) => {
    note.type = 'observations';
    if (!!note.id) {
      dispatch(editObservationElement(note, assistanceId));
    } else {
      dispatch(createNewObservationElement(note, assistanceId));
    }
    setCurrentObservation(null);
  };

  const handleDelete = (data) => {
    data.type = 'observations';
    dispatch(deleteObservationElement(data, assistanceId));
  };

  const handleObservationEdit = (note) => {
    if (note) {
      setCurrentObservation(note);
    } else {
      setCurrentObservation(new NoteModel());
    }
    dispatch(setObservationsDialogOpen());
  };

  const handleCancel = () => {
    setCurrentObservation(null);
    dispatch(setObservationsDialogClosed());
  };

  return (
    <Box>
      <Paper sx={{ p: 1 }}>
        <Accordion sx={{ boxShadow: 0 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6">Obserwacje</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {isSpecialistOrPrincipal && (
                <Button
                  variant="contained"
                  aria-label="dodaj"
                  sx={{ py: 1, lineHeight: 1 }}
                  onClick={() => handleObservationEdit()}>
                  dodaj
                </Button>
              )}
            </Box>
          </AccordionDetails>
          {!!observations?.length > 0 ? (
            simpleSortByDateTime(observations)?.map((observation) => (
              <IndividualJournalObservation
                observation={observation}
                onEdit={(observation) => handleObservationEdit(observation)}
                onDelete={(observation) => handleDelete(observation)}
                key={`observation-${observation.id}`}
                canManage={isSpecialistOrPrincipal}
              />
            ))
          ) : (
            <EmptyState message="Brak" contrast />
          )}
        </Accordion>
      </Paper>
      {Ui.isEditing && (
        <NotesAndObservationsDialog
          handleCancel={handleCancel}
          selectedElement={currentObservation}
          onSave={handleSave}
          type="observations"
          Ui={Ui}
        />
      )}
    </Box>
  );
};

export default IndividualJournalObservationsList;
