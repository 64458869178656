import initialState from './initial';
import * as types from '../actions/actionTypes';
import moment from 'moment';

export default function psychologicalIndividualJournalAttendanceJournalReducer(
  state = initialState.psychologicalIndividualJournalAttendances,
  action
) {
  switch (action.type) {
    case types.LOAD_INDIVIDUAL_JOURNAL_CLASSES_ATTENDANCE: {
      return action.payload;
    }
    case types.REPORT_INDIVIDUAL_JOURNAL_ATTENDANCE: {
      let newState = Object.assign({}, state);
      let idx = newState.childrenAttendance.findIndex((a) => a.child.id === action.child.id);
      newState.childrenAttendance = [...state.childrenAttendance];
      newState.childrenAttendance[idx].droppedOffAt = moment();
      newState.childrenAttendance[idx].pickedUpAt = moment();
      newState.childrenAttendance[idx].absenceFrom = null;
      newState.childrenAttendance[idx].absenceTo = null;
      return newState;
    }
    case types.REPORT_INDIVIDUAL_JOURNAL_ABSENCE: {
      let newState = Object.assign({}, state);
      let idx = newState.childrenAttendance.findIndex((a) => a.child.id === action.child.id);
      newState.childrenAttendance = [...state.childrenAttendance];
      newState.childrenAttendance[idx].droppedOffAt = null;
      newState.childrenAttendance[idx].pickedUpAt = null;
      newState.childrenAttendance[idx].absenceFrom = moment().format('YYYY-MM-DD');
      newState.childrenAttendance[idx].absenceTo = moment().format('YYYY-MM-DD');
      return newState;
    }

    case types.REPORT_CHILD_INDIVIDUAL_JOURNAL_ATTENDANCE_SUCCESS: {
      const isEqual = (c) =>
        moment(c.date).format('YYYY-MM-DD') === moment(action.data.droppedOfAt).format('YYYY-MM-DD');
      if (!state.entries.find((c) => isEqual(c))) return state;
      const nextEntries = state.entries.map((e) => {
        return isEqual(e)
          ? Object.assign({}, e, {
              droppedOffAt: action.data.droppedOfAt,
              pickedUpAt: action.data.pickedUpAt,
              pickedUpBy: action.data.pickedUpBy,
              absenceFrom: null,
              absenceTo: null,
              absenceNotes: null,
              receivedBy: action.data.receivedBy,
              releasedBy: action.data.releasedBy
            })
          : e;
      });
      return Object.assign({}, state, { entries: [...nextEntries] });
    }

    case types.REPORT_CHILD_INDIVIDUAL_JOURNAL_ABSENCE_SUCCESS: {
      const isInRange = (c) => moment(c.date).isBetween(action.absenceFrom, action.absenceTo, 'day', '[]');
      if (!state.entries.find((c) => isInRange(c))) return state;

      const violated = action.absenceDeadlineViolated ? [{}] : [];
      const nextEntries = state.entries.map((e) => {
        return isInRange(e)
          ? Object.assign({}, e, {
              droppedOffAt: null,
              pickedUpAt: null,
              pickedUpBy: null,
              absenceFrom: action.absenceFrom,
              absenceTo: action.absenceTo,
              absenceNotes: action.absenceNotes,
              absenceReportedBy: action.absenceReportedBy,
              absenceReportedAt: moment().format(),
              violatedSchemeDeadlines: e.violatedSchemeDeadlines.length > 0 ? e.violatedSchemeDeadlines : violated
            })
          : e;
      });
      return Object.assign({}, state, { entries: [...nextEntries] });
    }

    case types.REPORT_CHILD_INDIVIDUAL_JOURNAL_REVERT_ABSENCE_SUCCESS: {
      const isInRange = (c) => moment(c.date).isBetween(action.from.date, action.to.date, 'day', '[]');
      if (!state.entries.find((c) => isInRange(c))) return state;

      const nextEntries = state.entries.map((e) => {
        return isInRange(e)
          ? Object.assign({}, e, {
              absenceFrom: null,
              absenceTo: null,
              absenceNotes: null,
              absenceReportedAt: null
            })
          : e;
      });
      return Object.assign({}, state, { entries: [...nextEntries] });
    }

    default: {
      return state;
    }
  }
}
