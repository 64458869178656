import PropTypes from 'prop-types';
import React from 'react';
import FieldsetWrapper from '../../forms/FieldsetWrapper';
import SelectField from '../../common/SelectField';
import { Box, MenuItem } from '@mui/material';
import DatePicker from '../../common/DatePicker';
import moment from 'moment';

const menuItems = [
  { value: 'getDataFromStatementOfSettlements', primaryText: 'Przenieś wartość zaległości z raportu rozliczeń' },
  { value: 'default', primaryText: 'Domyślny' },
  { value: 'defaultWithArchived', primaryText: 'Domyślny - zawiera dzieci zarchiwizowane' },
  { value: 'splitByType', primaryText: 'Pokaż kwoty poszczególnych typów zaległości' },
  {
    value: 'splitByTypeWithArchived',
    primaryText: 'Pokaż kwoty poszczególnych typów zaległości - zawiera dzieci zarchiwizowane'
  }
];

const ListOfArrearsForMonthReport = ({ groupId, selectedDate, type, groups, onChange, errors }) => {
  return (
    <FieldsetWrapper legend="Pola dla generowania raportu zestawienia przelewów">
      <SelectField
        errorText={errors.groupId}
        contrast
        sx={{ width: 'auto', overflow: 'hidden' }}
        floatingLabelText="Grupa"
        id="groupId"
        value={groupId}
        onChange={(event) => {
          onChange({ target: { name: 'groupId', value: event.target.value } });
        }}>
        {groups.map((group) => {
          return (
            <MenuItem key={group.id} value={group.id} className="menu-item">
              {group.name}
            </MenuItem>
          );
        })}
      </SelectField>
      <SelectField
        contrast
        sx={{ width: 'auto', overflow: 'hidden' }}
        floatingLabelText="Typ raportu"
        id="type"
        value={type}
        onChange={(event) => {
          onChange({ target: { name: 'type', value: event.target.value } });
        }}>
        {menuItems.map((item) => {
          return (
            <MenuItem key={item.value} value={item.value} className="menu-item">
              {item.primaryText}
            </MenuItem>
          );
        })}
      </SelectField>
      <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
        <DatePicker
          floatingLabelText="Data generacji raportu"
          name="date"
          value={selectedDate ? new Date(selectedDate) : new Date()}
          onChange={(e, value) =>
            onChange({ target: { name: 'selectedDate', value: moment(value).format('YYYY-MM-DD') } })
          }
        />
      </Box>
    </FieldsetWrapper>
  );
};

ListOfArrearsForMonthReport.propTypes = {
  groupId: PropTypes.string.isRequired,
  selectedDate: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ListOfArrearsForMonthReport;
