import ApiBase, { ROOT_URL } from './ApiBase';

class GroupsApi extends ApiBase {
  static getGroups() {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/psychologicalPedagogicalGroups/`).type('application/json')
    );
  }

  static createGroup(group) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/psychologicalPedagogicalGroups`).send(group).type('application/json')
    );
  }

  static getGroup(id) {
    return super.toPromise((agent) =>
      agent.get(`${ROOT_URL}/psychologicalPedagogicalGroups/${id}`).type('application/json')
    );
  }

  static updateGroup(id, group) {
    return super.toPromise((agent) =>
      agent.put(`${ROOT_URL}/psychologicalPedagogicalGroups/${id}`).send(group).type('application/json')
    );
  }

  static deleteGroup(group) {
    return super.toPromise((agent) =>
      agent.post(`${ROOT_URL}/psychologicalPedagogicalGroups/${group.id}/archive`).send(group).type('application/json')
    );
  }

  static patchGroupChildren(id, childrenIds) {
    return super.toPromise((agent) =>
      agent
        .patch(`${ROOT_URL}/psychologicalPedagogicalGroups/${id}/children`)
        .send(childrenIds)
        .type('application/json')
    );
  }

  static removeChild(id, childId) {
    return super.toPromise((agent) =>
      agent
        .delete(`${ROOT_URL}/psychologicalPedagogicalGroups/${id}/children/${childId}`)
        .send()
        .type('application/json')
    );
  }

  static associateTeacher(id, teacherId) {
    return super.toPromise((agent) =>
      agent
        .put(`${ROOT_URL}/psychologicalPedagogicalGroups/${id}/teachers/${teacherId}`)
        .send()
        .type('application/json')
    );
  }

  static removeTeacher(id, teacherId) {
    return super.toPromise((agent) =>
      agent
        .delete(`${ROOT_URL}/psychologicalPedagogicalGroups/${id}/teachers/${teacherId}`)
        .send()
        .type('application/json')
    );
  }
}

export default GroupsApi;
