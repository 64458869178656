import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography, Box } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ProgramContainer from '../../common/psychologicalWorkProgram/ProgramContainer';
import {
  deleteProgramElement,
  setGeneralDialogClosed,
  setGeneralDialogOpen
} from '../../../actions/psychologicalIndividualJournalClassesActions';
import IndividualJournalProgramDialog from './IndividualJournalProgramDialog';

const IndividualJournalProgram = () => {
  const dispatch = useDispatch();

  const teachingResources = useSelector((state) => state.psychologicalIndividualJournalWorkProgram.teachingResources);
  const basicGoals = useSelector((state) => state.psychologicalIndividualJournalWorkProgram.basicGoals);
  const specificGoals = useSelector((state) => state.psychologicalIndividualJournalWorkProgram.specificGoals);
  const methods = useSelector((state) => state.psychologicalIndividualJournalWorkProgram.methods);
  const conclusions = useSelector((state) => state.psychologicalIndividualJournalWorkProgram.conclusions);
  const Ui = useSelector((state) => state.psychologicalIndividualJournalUi.individualProgram);
  const [currentData, setCurrentData] = useState(null);
  const assistanceId = useSelector((state) => state.psychologicalIndividualJournalWorkProgram.id);

  const [dialogType, setDialogType] = useState('');

  const handleSave = () => {
    setCurrentData(null);
  };

  const handleDelete = (data, type) => {
    data.type = type;
    dispatch(deleteProgramElement(data, assistanceId));
  };

  const handleGeneralDialogOpen = (post) => {
    if (!!post) {
      setCurrentData(post);
    }
    setDialogType('basicGoals');
    dispatch(setGeneralDialogOpen());
  };

  const handleSpecificDialogOpen = (post) => {
    if (!!post) {
      setCurrentData(post);
    }
    setDialogType('specificGoals');
    dispatch(setGeneralDialogOpen());
  };

  const handleGeneralMethods = (post) => {
    if (!!post) {
      setCurrentData(post);
    }
    setDialogType('methods');
    dispatch(setGeneralDialogOpen());
  };

  const handleGeneralConclusions = (post) => {
    if (!!post) {
      setCurrentData(post);
    }
    setDialogType('conclusions');
    dispatch(setGeneralDialogOpen());
  };

  const handleTeachingResources = (post) => {
    if (!!post) {
      setCurrentData(post);
    }
    setDialogType('teachingResources');
    dispatch(setGeneralDialogOpen());
  };

  const handleCancel = () => {
    setDialogType('');
    setCurrentData(null);
    dispatch(setGeneralDialogClosed());
  };

  return (
    <Box>
      <Paper sx={{ p: 1 }}>
        <Accordion sx={{ boxShadow: 0 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6">Indywidualny program pracy z uczniem</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProgramContainer
              title="Cele ogólne"
              data={basicGoals}
              onClick={() => handleGeneralDialogOpen(null)}
              onEdit={handleGeneralDialogOpen}
              onDelete={(element) => handleDelete(element, 'basicGoals')}
            />
            <ProgramContainer
              title="Cele szczegółowe"
              data={specificGoals}
              onClick={() => {
                handleSpecificDialogOpen(null);
              }}
              onEdit={handleSpecificDialogOpen}
              onDelete={(element) => handleDelete(element, 'specificGoals')}
            />
            <ProgramContainer
              title="Metody"
              data={methods}
              onClick={() => {
                handleGeneralMethods(null);
              }}
              onEdit={handleGeneralMethods}
              onDelete={(element) => handleDelete(element, 'methods')}
            />
            <ProgramContainer
              title="Wnioski"
              data={conclusions}
              onClick={() => {
                handleGeneralConclusions(null);
              }}
              onEdit={handleGeneralConclusions}
              onDelete={(element) => handleDelete(element, 'conclusions')}
            />
            <ProgramContainer
              title="Środki dydaktyczne"
              data={teachingResources}
              onClick={() => {
                handleTeachingResources(null);
              }}
              onEdit={handleTeachingResources}
              onDelete={(element) => handleDelete(element, 'teachingResources')}
            />
          </AccordionDetails>
        </Accordion>
      </Paper>
      {Ui.isEditing && (
        <IndividualJournalProgramDialog
          dialogType={dialogType}
          handleCancel={handleCancel}
          selectedElement={currentData}
          onSave={handleSave}
        />
      )}
    </Box>
  );
};

export default IndividualJournalProgram;
