import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography, Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  editObservation,
  createNewObservation,
  deleteObservation,
  setObservationsDialogOpen,
  setObservationsDialogClosed
} from '../../../actions/psychologicalIndividualClassesActions';
import Observation from './Observation';
import NotesAndObservationsDialog from '../NotesAndObservationsDialog';
import { NoteModel } from '../../../models/psychological/notes/NoteModel';
import EmptyState from '../../common/EmptyState';
import { useIsSpecialist } from '../../../hooks/useIsSpecialist';

const ObservationsList = () => {
  const dispatch = useDispatch();

  const observations = useSelector((state) => state.psychologicalIndividualWorkProgram.observations);
  const Ui = useSelector((state) => state.psychologicalIndividualProgramUi.observations);
  const [currentObservation, setCurrentObservation] = useState(null);
  const assistanceId = useSelector((state) => state.psychologicalIndividualWorkProgram.id);
  const { isSpecialistOrPrincipal } = useIsSpecialist();

  const handleSave = (note) => {
    if (!!note.id) {
      dispatch(editObservation(note, assistanceId));
    } else {
      dispatch(createNewObservation(note, assistanceId));
    }
    setCurrentObservation(null);
  };

  const handleDelete = (data) => {
    dispatch(deleteObservation(data, assistanceId));
  };

  const handleObservationEdit = (note) => {
    if (note) {
      setCurrentObservation(note);
    } else {
      setCurrentObservation(new NoteModel());
    }
    dispatch(setObservationsDialogOpen());
  };

  const handleCancel = () => {
    setCurrentObservation(null);
    dispatch(setObservationsDialogClosed());
  };

  return (
    <Box>
      <Paper sx={{ p: 1 }}>
        <Accordion sx={{ boxShadow: 0 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6">Obserwacje</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {isSpecialistOrPrincipal && (
                <Button
                  variant="contained"
                  aria-label="dodaj"
                  sx={{ py: 1, lineHeight: 1 }}
                  onClick={() => handleObservationEdit()}>
                  dodaj
                </Button>
              )}
            </Box>
          </AccordionDetails>
          {!!observations?.length > 0 ? (
            observations?.map((observation) => (
              <Observation
                observation={observation}
                onEdit={(observation) => handleObservationEdit(observation)}
                onDelete={(observation) => handleDelete(observation)}
                key={`observation-${observation.id}`}
                canManage={isSpecialistOrPrincipal}
              />
            ))
          ) : (
            <EmptyState message="Brak" contrast />
          )}
        </Accordion>
      </Paper>
      {Ui.isEditing && (
        <NotesAndObservationsDialog
          handleCancel={handleCancel}
          selectedElement={currentObservation}
          onSave={handleSave}
          type="observations"
          Ui={Ui}
        />
      )}
    </Box>
  );
};

export default ObservationsList;
