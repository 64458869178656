import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Paper, Typography } from '@mui/material';

const AccordionElement = ({ title, content }) => {
  return (
    <Paper sx={{ p: 1, my: 2 }}>
      <Accordion sx={{ boxShadow: 0 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              mr: 2
            }}>
            <Typography variant="h6">{title}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ overflowX: 'auto' }}>{content}</AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default AccordionElement;
