import { BaseModelValidator } from '../../../../models/BaseModelValidator';
import { check } from '../../../../models/validation/validateHelper';

export class SpecialistWorkPlanValidator extends BaseModelValidator {
  constructor() {
    super();

    this.constraints = {
      name: (value) => {
        return check.isEmpty(value) && 'Nie podano nazwy';
      },
      specialistsIds: (value) => {
        return check.isEmpty(value) && 'Nie wybrano specjalistów';
      }
    };
  }
}
