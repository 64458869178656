import BaseModel from '../../BaseModel';
import moment from 'moment';
import _ from 'lodash';

const leadingZerosPattern = new RegExp('{(0+):{numer}}');

export class NumberingSchemeModel extends BaseModel {
  constructor() {
    super();
    this.id = '';
    this.name = '';
    this.template = '{numer}';
    this.nextNumber = 1;
  }

  static getNumberExample(scheme) {
    const now = moment();
    const result = leadingZerosPattern.exec(scheme.template);
    const leadingZeros = result && result.length > 1 ? result[1] : '';

    return scheme.template
      .replace(`{${leadingZeros}:{numer}}`, _.padStart(scheme.nextNumber, leadingZeros.length, '0'))
      .replace('{numer}', scheme.nextNumber)
      .replace('{rok}', now.format('YYYY'))
      .replace('{miesiąc}', now.format('MM'));
  }
}
