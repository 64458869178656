import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingRenderWrapper from '../common/loading/LoadingRenderWrapper';
import PageHeader from '../common/PageHeader';
import PageHeaderText from '../common/pageHeader/PageHeaderText';

import EventNoteIcon from '@mui/icons-material/EventNote';
import {
  addSpecialistClassSchedule,
  deleteSpecialistClassScheduleElement,
  editSpecialistClassScheduleElement,
  loadSpecialistClassSchedulePlan,
  setSpecialistClassScheduleDialogClosed,
  setSpecialistClassScheduleDialogOpen
} from '../../actions/specialistClassScheduleActions';
import { SpecialistClassScheduleEventModel } from '../../models/specialist/SpecialistClassScheduleEventModel';
import ClassScheduleDialog from './components/dialogs/ClassScheduleDialog';
import { useIsSpecialist } from '../../hooks/useIsSpecialist';
import moment from 'moment';
import SpecialistEventsList from './components/specialistEventsList/SpecialistEventsList';

const SpecialistClassSchedulePageDetails = (props) => {
  const today = `${props.params.year}-${props.params.month}-${props.params.day}`;
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.specialistNotes.classSchedule);
  const workId = props.params.id;

  const ui = useSelector((state) => state.specialistNotesUi.classSchedule);
  const [currentEvent, setcurrentEvent] = useState(null);

  useEffect(() => {
    dispatch(loadSpecialistClassSchedulePlan(moment(today).format('YYYY-MM'), workId));
  }, [dispatch, today, workId]);

  const { isSpecialist } = useIsSpecialist();

  const handleOpenEditPost = (post) => {
    dispatch(setSpecialistClassScheduleDialogOpen());
    setcurrentEvent(new SpecialistClassScheduleEventModel().assign(post));
  };
  const handleOpenCreatePost = () => {
    dispatch(setSpecialistClassScheduleDialogOpen());
    setcurrentEvent(
      new SpecialistClassScheduleEventModel().assign({
        eventDate: `${props.params.year}-${props.params.month}-${props.params.day}`
      })
    );
  };

  const handleEventSave = (post) => {
    let data = post;
    data.group = workId;
    data.eventType = 'specialistClassSchedule';
    if (post.id) {
      dispatch(editSpecialistClassScheduleElement(data));
    } else {
      dispatch(addSpecialistClassSchedule(data));
    }
  };

  const handlePostDelete = (post) => {
    setcurrentEvent(null);
    dispatch(deleteSpecialistClassScheduleElement(post.id, workId));
  };
  const handleEventCancel = () => {
    dispatch(setSpecialistClassScheduleDialogClosed());
  };

  const renderAddPostButton = () => {
    return (
      <Button sx={{ mb: 1 }} variant="contained" aria-label="Nowy wpis" onClick={handleOpenCreatePost}>
        Nowy wpis
      </Button>
    );
  };
  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeader>
          <PageHeaderText
            title="Rozkład zajęć"
            titleIcon={<EventNoteIcon />}
            additionalInfo={`${props.params.day}.${props.params.month}.${props.params.year}`}
          />
          {isSpecialist && renderAddPostButton()}
        </PageHeader>
        {ui.isEditing && (
          <ClassScheduleDialog
            editable={isSpecialist}
            onSave={handleEventSave}
            onCancel={handleEventCancel}
            post={currentEvent}
            isEditing={ui.isEditing}
            isProcessing={ui.isProcessing}
            canSetDate={true}
          />
        )}
        <SpecialistEventsList
          editable={isSpecialist}
          posts={posts?.filter((post) => post.eventDate === today)}
          pageCount={1}
          hasNextPage={false}
          onEdit={handleOpenEditPost}
          onDelete={handlePostDelete}
        />
      </Box>
    </LoadingRenderWrapper>
  );
};

export default SpecialistClassSchedulePageDetails;
