import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, MenuItem } from '@mui/material';
import moment from 'moment';
import DotsMenu from '../../../common/menu/DotsMenu';
import ActionConfirmationMenuItem from '../../../forms/ActionConfirmationMenuItem';

const IndividualJournalObservation = ({ observation, onEdit, onDelete, canManage }) => {
  const getDateAndTime = (element) => {
    const date = moment(element.date, 'YYYY-MM-DD').format('DD MMMM YYYY');
    if (!!element.date && !!element.time) {
      return `${date}, godzina ${element.time}`;
    } else if (!!element.date) {
      return date;
    } else if (!!element.time) {
      return `godzina ${element.time}`;
    }
    return '';
  };

  return (
    <Box sx={{ p: 2 }} elevation={1}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ flexDirection: 'row' }}>
          <Typography
            variant="h6"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              fontWeight: (theme) => theme.typography.fontWeightBold
            }}>
            {observation.title}
          </Typography>
          <Typography>{getDateAndTime(observation)}</Typography>
          <Typography>{observation.content}</Typography>
        </Box>
        {canManage && (
          <DotsMenu key={`dostMenu-${observation.id}`}>
            {(onClose) => [
              <MenuItem key={`edit-${observation.id}`} onClick={() => onEdit(observation)}>
                Edytuj
              </MenuItem>,
              <ActionConfirmationMenuItem
                id={observation.id}
                key={`action-${observation.id}`}
                actionLabel="Usuń"
                confirmationText="Czy na pewno chcesz usunąć obserwację?"
                confirmationTitle="Usuń obserwację"
                confirmLabel="Usuń"
                onItemClick={onClose}
                onConfirm={() => onDelete(observation)}
                onCancel={() => {}}
              />
            ]}
          </DotsMenu>
        )}
      </Box>
    </Box>
  );
};

IndividualJournalObservation.propTypes = {
  observation: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  canManage: PropTypes.bool.isRequired
};

export default IndividualJournalObservation;
