import psychologicalApi from '../api/psychologicalApi';
import api from '../api/PsychologicalAttendanceApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as logger from '../utils/logger';
import { Person } from '../models/attendance/AttendanceModels';
import * as get from './getStateHelpers';

export function loadIndividualJournalClassesAttendanceData(psychologicalGroupId) {
  return (dispatch) => {
    return psychologicalApi
      .loadIndividualJournalAttendance(psychologicalGroupId)
      .then((payload) => dispatch({ type: types.LOAD_INDIVIDUAL_JOURNAL_CLASSES_ATTENDANCE, payload }))
      .catch((error) => {
        dispatch(notificationActions.showError(`Nie udało się pobrać listy obecności`));
        return logger.error(error);
      });
  };
}

export function loadChildIndividualJournalAttendanceAsync(groupId, childId, date) {
  return (dispatch) => {
    return api
      .loadMonthlyIndividualJournalAttendance(groupId, childId, date)
      .then((payload) => dispatch({ type: types.LOAD_INDIVIDUAL_JOURNAL_CLASSES_ATTENDANCE, payload }))
      .catch((error) => {
        dispatch(notificationActions.showError('Nie udało się pobrać obecności na zajęciach specjalistycznych'));
        return logger.error(error);
      });
  };
}

function getCurrentUserAsPerson(getState) {
  const { userFullName, userId, profilePhoto } = getState().auth;
  const firstName = userFullName.split(' ')[0];
  const lastName = userFullName.split(' ')[1];

  return new Person().assign({ firstName, lastName, id: userId, photoUrl: profilePhoto });
}

export function reportIndividualJournalAttendance(child, groupId) {
  return (dispatch, getState) => {
    return psychologicalApi.loadJournalId(child.id, groupId).then((payload) => {
      return api
        .reportJournalChildAttendance(child, payload.id)
        .then(() => {
          dispatch(notificationActions.showSuccess(`Zgłoszono obecność dziecka ${get.childName(getState, child.id)}`));
          dispatch({ type: types.REPORT_INDIVIDUAL_JOURNAL_ATTENDANCE, child });
        })
        .catch((error) => {
          dispatch(
            notificationActions.showError(
              `Nie udało się zgłosić obecności dziecka ${get.childName(getState, child.id)}}`
            )
          );
        });
    });
  };
}

export function reportIndividualJournalAbsence(child, groupId) {
  return (dispatch, getState) => {
    return psychologicalApi.loadJournalId(child.id, groupId).then((payload) => {
      return api
        .reportJournalChildAbsence(child, payload.id)
        .then(() => {
          dispatch(
            notificationActions.showSuccess(
              `Zgłoszono nieobecność dziecka ${get.childName(getState, child.id)} ${get.displayDateRange()}`
            )
          );
          const absenceReportedBy = getCurrentUserAsPerson(getState);
          dispatch({ type: types.REPORT_INDIVIDUAL_JOURNAL_ABSENCE, child, groupId, absenceReportedBy });
        })
        .catch((error) => {
          dispatch(
            notificationActions.showError(
              `Nie udało się zgłosić nieobecności dziecka ${get.childName(getState, child.id)} }`
            )
          );
        });
    });
  };
}
