import { TextField, Box, MenuItem, ListItemIcon, Checkbox, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogForm from '../../../forms/DialogForm';
import { SpecialistWorkPlanValidator } from './SpecialistWorkPlanValidator';
import SelectField from '../../../common/SelectField';
import { createSpecialistWorkPlan, updateSpecialistWorkPlan } from '../../../../actions/specialistNotesActions';
import staffMemberRoles from '../../../../constants/staffMemberRoles';

const SpecialistWorkPlanDialog = ({ handleClose, selectedPlan }) => {
  const staffMembers = useSelector((state) => state.staffMembers);
  const specialists = staffMembers.filter(
    (member) =>
      member.role === staffMemberRoles.principal.value ||
      member.role === staffMemberRoles.teacher.value ||
      member.role === staffMemberRoles.specialistTeacher.value
  );
  const ui = useSelector((state) => state.specialistNotesUi.specialistWorksList);
  const [errors, setErrors] = useState();
  const [name, setName] = useState(selectedPlan?.name || '');
  const selectedPlansPecialists = selectedPlan
    ? specialists.filter((spec) => selectedPlan?.specialistIds?.includes(spec.id))
    : [];
  const [specialistsIds, setSpecialistsIds] = useState(selectedPlansPecialists);
  const dispatch = useDispatch();

  const handleSave = () => {
    let data = {
      name: name,
      specialistIds: specialistsIds.map((spec) => spec.id)
    };
    !selectedPlan
      ? dispatch(createSpecialistWorkPlan(data))
      : dispatch(updateSpecialistWorkPlan(data, selectedPlan?.id));

    handleClose();
  };

  const handleSpecialistSelected = (event) => {
    const selectedValues = event.target.value;
    setSpecialistsIds(selectedValues);
  };

  return (
    <>
      {ui.isEditing && (
        <DialogForm
          header={selectedPlan ? 'Edycja zapisu planu specjalisty' : 'Utwórz zapis pracy specjalisty'}
          maxWidth="md"
          onSave={handleSave}
          onCancel={() => handleClose()}
          isDialogOpen={ui.isEditing}
          isProcessing={ui.isProcessing}
          onValidate={() => new SpecialistWorkPlanValidator().validate({ name: name, specialistsIds: specialistsIds })}
          onValidationDone={(errors) => setErrors({ errors })}
          statePathToUi="specialistNotesUi.specialistWorksList"
          requiredFieldText>
          <Box sx={{ my: 1 }}>
            {}
            <TextField
              variant="standard"
              sx={{ mb: 2 }}
              label="Podaj tytuł"
              onChange={(e) => setName(e.target.value)}
              fullWidth
              defaultValue={name}
              error={errors?.errors?.name}
              helperText={errors?.errors?.name}
            />

            <SelectField
              //852px for DialogForm with maxWidth="md"
              sx={{ width: '100%', maxWidth: 852 }}
              errorText={errors?.errors?.specialistsIds}
              contrast
              floatingLabelText="Wybierz specjalistów"
              multiple
              value={specialistsIds}
              onChange={handleSpecialistSelected}
              renderValue={(x) => x.map((a) => a.firstName + ' ' + a.lastName).join(', ')}>
              {specialists.map((option) => (
                <MenuItem key={option.id} value={option}>
                  <ListItemIcon>
                    <Checkbox checked={specialistsIds.some((item) => item.id === option.id)} />
                  </ListItemIcon>
                  <ListItemText primary={option.firstName + ' ' + option.lastName} />
                </MenuItem>
              ))}
            </SelectField>
          </Box>
        </DialogForm>
      )}
    </>
  );
};

export default SpecialistWorkPlanDialog;
