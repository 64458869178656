import _ from 'lodash';
import { calculationStatusComparator } from '../constants/calculationStatus';
import { chargingReportTypesComparator } from '../constants/chargingReportTypes';
import * as sortTypes from '../constants/definedSortTypes';

const descendingComparator = (a, b, orderBy, order) => {
  const tempA = a[orderBy] && a[orderBy].toLowerCase();
  const tempB = b[orderBy] && b[orderBy].toLowerCase();
  if (tempA !== null) {
    return tempA.localeCompare(tempB, 'pl');
  }
  return order === 'asc' ? 1 : -1;
};

const stringNumberComparator = (a, b, orderBy) => {
  const numA = Number(a[orderBy]);
  const numB = Number(b[orderBy]);

  if (isNaN(numA) || isNaN(numB)) {
    return 0;
  }

  return numA - numB;
};

const numberComparator = (a, b, orderBy) => a[orderBy] - b[orderBy];

const dateComparator = (a, b, orderBy) => new Date(a[orderBy]) - new Date(b[orderBy]);

export const getComparator = (order, orderBy) => {
  const { property, type } = orderBy;
  switch (type) {
    case sortTypes.STRING_SORT:
      return order === 'asc'
        ? (a, b) => descendingComparator(a, b, property, 'asc')
        : (a, b) => -descendingComparator(a, b, property, 'desc');
    case sortTypes.NUMBER_SORT:
      return order === 'asc' ? (a, b) => numberComparator(a, b, property) : (a, b) => -numberComparator(a, b, property);
    case sortTypes.DATE_SORT:
      return order === 'asc' ? (a, b) => dateComparator(a, b, property) : (a, b) => -dateComparator(a, b, property);
    case sortTypes.CHARGING_REPORT_TYPE:
      return order === 'asc'
        ? (a, b) => chargingReportTypesComparator(a, b, property)
        : (a, b) => -chargingReportTypesComparator(a, b, property);
    case sortTypes.CALCULATION_STATUS_TYPE:
      return order === 'asc'
        ? (a, b) => calculationStatusComparator(a, b, property)
        : (a, b) => -calculationStatusComparator(a, b, property);
    case sortTypes.STRING_NUMBER_SORT:
      return order === 'asc'
        ? (a, b) => stringNumberComparator(a, b, property)
        : (a, b) => -stringNumberComparator(a, b, property);
    default:
      return (a, b) => numberComparator(a, b, property);
  }
};

export const stableSort = (array, comparator) => {
  const arr = _.cloneDeep(array);
  const stabilizedThis = arr.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return null;
  });
  return stabilizedThis.map((el) => el[0]);
};
