import { eventTypes } from './constants/eventTypes';

export const routePaths = {
  unmatched: '*',
  home: '/',
  signIn: '/logowanie',
  accountActivation: '/aktywacja-konta',
  passwordRecovery: '/odzyskiwanie-hasla',
  passwordExpired: '/odswiezenie-hasla?token=',
  passwordReset: '/reset-hasla',
  userProfile: '/uzytkownik/profil',
  groups: '/grupy',
  groupsArchive: '/grupy/archiwum',
  groupRegister: '/grupy/rejestracja',
  groupDetails: '/grupy/:id',
  groupArchiveDetails: '/grupy/archiwum/:id',
  activities: '/zajecia-dodatkowe',
  activitiesArchive: '/zajecia-dodatkowe/archiwum',
  activityRegister: '/zajecia-dodatkowe/rejestracja',
  activityDetails: '/zajecia-dodatkowe/:id',
  activityArchiveDetails: '/zajecia-dodatkowe/archiwum/:id',
  children: '/rejestr-dzieci',
  student: '/rejestr-uczniow',
  childrenArchive: '/rejestr-dzieci/archiwum',
  studentArchive: '/rejest-uczniow/archiwum',
  childRegister: '/rejestr-dzieci/rejestracja',
  studentRegister: 'rejestr-uczniow/rejestracja',
  childDetails: '/rejestr-dzieci/:id',
  studentDetails: '/rejestr-uczniow/:id',
  childFinancialDetails: '/rejestr-dzieci/:id/stan-srodkow',
  meals: '/posilki',
  mealRegister: '/posilki/rejestracja',
  mealsArchive: '/posilki/archiwum',
  mealDetails: '/posilki/:id',
  diets: '/diety',
  dietDetails: '/diety/:id',
  createDiet: '/diety/nowa',
  staffFinancialDetails: '/pracownicy/:id/stan-srodkow',
  childSummary: '/rejestr-dzieci/:id/ewidencja-opłat',
  childArchiveDetails: '/rejestr-dzieci/archiwum/:id',
  legalGuardians: '/opiekunowie-prawni',
  staffMembers: '/pracownicy',
  staffMembersArchive: '/pracownicy/archiwum',
  staffMemberRegister: '/pracownicy/rejestracja',
  staffMemberDetails: '/pracownicy/:id',
  staffMemberArchiveDetails: '/pracownicy/archiwum/:id',
  messageBoard: '/tablica-ogloszen',
  messageBoardEvents: '/tablica-ogloszen/:year/:month/:day',
  privateMessages: '/wiadomosci',
  staffMemberPrivateMessages: '/wiadomosci/moje',
  guardianPrivateMessages: '/opiekun/wiadomosci/moje',
  eventsCalendar: '/wydarzenia/:year/:month',
  attendance: '/dziennik-obecnosci',
  absentTomorrow: '/nieobecne-jutro',
  attendanceMonthlyHistory: '/dziennik-obecnosci/historia/:groupId/:year/:month',
  attendanceDailyHistory: '/dziennik-obecnosci/historia/:groupId/:year/:month/:day',
  activitiesAttendanceSheetPage: '/dziennik-obecnosci/zajecia-dodatkowe',
  returnFromOnlinePayments: '/platnosci',
  guardianDues: '/opiekun/naleznosci/:year',
  guardianDueDetails: '/opiekun/naleznosc/:id',
  guardianChildAttendance: '/opiekun/rejestr-dzieci/:childId/obecnosci/:year/:month',
  guardianChildDetails: '/opiekun/rejestr-dzieci/:id',
  guardianApplications: '/opiekun/wnioski',
  configurationUnit: '/konfiguracja/placowka',
  configurationSettlementsSettings: '/konfiguracja/ustawienia-platnosci',
  configurationDaysOff: '/konfiguracja/dni-wolne/:year/:month',
  configurationChargingSchemes: '/konfiguracja/schematy-platnosci',
  configurationReliefs: '/konfiguracja/ulgi',
  configurationAttributesSets: '/konfiguracja/atrybuty',
  configurationGuardiansPinService: '/konfiguracja/urzadzenia',
  configurationDaycare: '/konfiguracja/aktywnosc-dzieci',
  settlements: '/rozliczenia/:year/:month',
  settlementsGroup: '/rozliczenia/:year/:month/grupa/:groupId',
  settlementsExport: '/rozliczenia/eksport',
  fkExport: '/rozliczenia/fk_eksport',
  symphonyExport: '/rozliczenia/symfonia-eksport',
  settlementsImport: '/rozliczenia/import/:year/:month',
  settlementsGroupDetails: '/rozliczenia/:year/:month/grupa/:groupId/rozliczenie/:settlementId',
  staffMembersSettlements: '/rozliczenia-pracownikow/:year/:month',
  childDueDetails: '/rozliczenia/:settlementId',
  reports: '/raporty',
  configurationNumberingSchemes: '/konfiguracja/schematy-numeracji',
  configurationReports: '/konfiguracja/raporty',
  pinSignIn: '/pin/logowanie',
  pinKeypad: '/pin',
  pinEntries: '/pin/rejestracja',
  daycare: '/aktywnosc-dzieci',
  electronicDiaryTimeTable: `/dziennik-elektroniczny/kalendarz/${eventTypes.daySchedule.path}/:eventType/:year/:month`,
  electronicDiarySaveWork: `/dziennik-elektroniczny/kalendarz/${eventTypes.saveWork.path}/:eventType/:year/:month`,
  electronicDiaryTrip: `/dziennik-elektroniczny/kalendarz/${eventTypes.trip.path}/:eventType/:year/:month`,
  electronicDiaryAdditionalActivities: `/dziennik-elektroniczny/kalendarz/${eventTypes.additionalActivities.path}/:eventType/:year/:month`,
  electronicDiaryWorkWithParents: `/dziennik-elektroniczny/kalendarz/${eventTypes.workWithParents.path}/:eventType/:year/:month`,
  electronicDiaryWorkPlan: `/dziennik-elektroniczny/${eventTypes.workPlan.path}/:year/:month`,
  electronicDiaryFrameworkDaySchedule: `/dziennik-elektroniczny/roczny-ramowy-plan-dnia`,
  configurationDiagnosisTemplate: '/konfiguracja/szablony-diagnoz',
  electronicDiaryMessageBoard: '/dziennik-elektroniczny/tablica-ogloszen/:eventType/:year/:month/:day/:groupId',
  entrances: '/wejscia',
  applications: '/wnioski',
  events: '/rejestr-zdarzen',
  changeLog: '/nowosci',
  authorizedBySign: '/podpisano-upowaznienie',
  loginHistory: '/historia-logowan',
  currentUsers: '/zalogowani-uzytkownicy',
  overdueList: '/zalegle-oplaty',
  overpaymentsList: '/nadplaty',
  childrenOnVacationPeriod: '/urlopowane-dzieci',
  expiringReliefs: '/wygasajace-ulgi',
  takenOverByDebtCollection: '/przejete-przez-windykacje',
  loginGov: 'logingov',
  gallery: '/galeria',
  statuteChanged: '/zmiana-regulaminu',
  userManual: '/instrukcja-obslugi',
  staffMessageBoard: '/pracownicy-ogloszenia',
  staffCalendarDetails: '/pracownicy-kalendarz/:year/:month/:day',
  staffCalendar: '/pracownicy-kalendarz/:year/:month',
  scheduledTransfer: '/zaplanowane-przeniesienia',
  virtualDisc: '/dysk-wirtualny',
  authorizedToPickUpChild: '/upowaznienia',
  mobileDownload: '/aplikacja-mobilna',
  childrenImport: '/import',
  posterDownload: '/poster',

  psychologicalGroups: '/grupy-pomocy-psychologiczno-pedagogicznej',
  psychologicalGroupDetails: '/grupy-pomocy-psychologiczno-pedagogicznej/:id',
  psychologicalHelp: '/pomoc-psychologiczno-pedagogiczna',
  psychologicalHelpChild: '/pomoc-psychologiczno-pedagogiczna/:id',
  psychologicalSchemes: '/schematy-pomocy-psychologiczno-pedagogicznej',
  individualClasses: '/zajecia-indywidualne',
  individualClassesDetails: 'zajecia-indywidualne/:id/:childId/:year/:month',
  individualClassesEventsDetails: '/zajecia-indywidualne-szczegoly/:id/:year/:month/:day',
  groupClassesEventsDetails: '/zajecia-grupowe/:id/:year/:month/:day',
  groupDiaryEventsDetails: '/dziennik-zajec-grupowych/:id/:year/:month/:day',
  groupClasses: '/zajecia-grupowe',
  individualClassesJournal: '/indywidualne-dzienniki-zajec-specjalistycznych',
  individualClassesJournalDetails: '/indywidualne-dzienniki/:childId/:psychologicalGroupId/:journalId/:year/:month',
  individualClassesJournalEventDetails: '/wpisy-indywidualnych-dziennikow/:id/:childId/:year/:month/:day',
  specialistNotes: '/zapis-pracy-specjalisty',
  specialistNotesDetails: '/zapis-pracy-specjalisty/:id',
  specialistClassScheduleDetails: '/zapis-pracy-specjalisty/rozklad-zajec/:id/:year/:month/:day',
  specialistGroupActivitiesDetails: '/zapis-pracy-specjalisty/zajecia-edukacyjne-w-grupach/:id/:year/:month/:day',
  specialistWorkPlanProgressDetails: '/zapis-pracy-specjalisty/realizacja-planu-pracy/:id/:year/:month/:day'
};

export const fromTemplate = (path, args) => {
  /*eslint no-param-reassign: 0*/
  const regex = /:(.*?)(?=[/]|$)/;

  if (args && Array.isArray(args)) {
    args.forEach((a) => {
      path = path.replace(regex, a);
    });
  }
  if (args) {
    return path.replace(regex, args);
  }
  return path;
};
