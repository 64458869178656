import {
  Box,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewNoteDialog from './components/dialogs/NewNoteDialog';
import {
  deleteSpecialistNoteElement,
  setNotesDialogClose,
  setNotesDialogOpen
} from '../../actions/specialistNotesActions';
import EmptyState from '../common/EmptyState';
import DotsMenu from '../common/menu/DotsMenu';
import ActionConfirmationMenuItem from '../forms/ActionConfirmationMenuItem';
import { useIsSpecialist } from '../../hooks/useIsSpecialist';
import moment from 'moment';
import { simpleSortByDateTime } from './utils/sortByDateTime';

const SpecialistNotesList = () => {
  const [currentData, setCurrentData] = useState(null);
  const data = useSelector((state) => state.specialistNotes.notes);
  const specialistWorkRecordId = useSelector((state) => state.specialistNotes.id);
  const ui = useSelector((state) => state.specialistNotesUi.notes);

  const { isSpecialist } = useIsSpecialist();

  const dispatch = useDispatch();
  const handleSave = () => {};

  const handleDialogOpen = (post) => {
    setCurrentData(post);
    dispatch(setNotesDialogOpen());
  };

  const handleCancel = () => {
    dispatch(setNotesDialogClose());
  };

  const handleRemove = (id) => {
    dispatch(deleteSpecialistNoteElement(id, 'notes', specialistWorkRecordId));
  };

  const getDateAndTime = (element) => {
    const date = moment(element.date, 'YYYY-MM-DD').format('DD MMMM YYYY');
    if (!!element.date && !!element.time) {
      return `${date}, godzina ${element.time}`;
    } else if (!!element.date) {
      return date;
    } else if (!!element.time) {
      return `godzina ${element.time}`;
    }
    return '';
  };

  return (
    <Box>
      {isSpecialist && (
        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <Button variant="contained" onClick={() => handleDialogOpen(null)}>
            Dodaj
          </Button>
        </Box>
      )}
      {ui.isEditing && (
        <NewNoteDialog
          handleCancel={handleCancel}
          selectedElement={currentData}
          onSave={handleSave}
          specialistWorkRecordId={specialistWorkRecordId}
        />
      )}

      <Box sx={{ mb: 4 }}>
        {!!data?.length > 0 ? (
          <List sx={{ width: '100%' }}>
            {simpleSortByDateTime(data).map((element) => (
              <ListItem key={`note-${element.id}`}>
                <Box sx={{ flexDirection: 'row' }}>
                  <ListItemText
                    primary={element.title}
                    secondary={getDateAndTime(element)}
                    primaryTypographyProps={{ variant: 'h6' }}
                    secondaryTypographyProps={{ variant: 'subtitle2', color: (theme) => theme.palette.color.black }}
                  />
                  <Typography>{element.content}</Typography>
                </Box>
                {isSpecialist && (
                  <ListItemSecondaryAction>
                    <DotsMenu>
                      {(onClose) => [
                        <MenuItem
                          key="edit"
                          onClick={() => {
                            onClose();
                            handleDialogOpen(element);
                          }}>
                          Edytuj
                        </MenuItem>,
                        <ActionConfirmationMenuItem
                          key="1"
                          onItemClick={onClose}
                          actionLabel="Usuń"
                          confirmationText="Czy na pewno chcesz usunąć notatkę?"
                          confirmationTitle="Usuń notatkę"
                          confirmLabel="Usuń"
                          onConfirm={() => handleRemove(element.id)}
                          onCancel={() => {}}
                        />
                      ]}
                    </DotsMenu>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        ) : (
          <EmptyState message="Brak" contrast />
        )}
      </Box>
    </Box>
  );
};

export default SpecialistNotesList;
