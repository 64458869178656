import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import TeacherMenu from './menu/TeacherMenu';
import PrincipalMenu from './menu/PrincipalMenu';
import AccountantMenu from './menu/AccountantMenu';
import SecretaryMenu from './menu/SecretaryMenu';
import CanteenWorkerMenu from './menu/CanteenWorkerMenu';
import GuardianMenu from './menu/LegalGuardianMenu';
import DigitalDiaryTeacherMenu from './menu/DigitalDiaryTeacherMenu';
import userRoles from '../../constants/userRoles';
import { bindActionCreators } from 'redux';
import * as importChildrenActions from '../../actions/importChildrenActions';
import * as exportChildrenActions from '../../actions/exportChildrenActions';
import ErrorAlertBox from '../common/ErrorAlertBox';
import { Box, Stack } from '@mui/material';
import withMediaQuery from '../../hoc/withMediaQuery';
import TeacherSupportMenu from './menu/TeacherSupportMenu';
import ReducedPrincipalMenu from './menu/ReducedPrincipalMenu';
import ReducedStudentMenu from './menu/ReducedStudentMenu';
import ReducedTeacherMenu from './menu/ReducedTeacherMenu';
import navlogo from '../../assets/navlogo.png';
import { emptyUrlGroup } from '../../constants/emptyUrlGroup';
import kleksLogo from '../../assets/images/kleks.png';
import kleksLogoDark from '../../assets/images/kleks_dark.jpg';
import SpecialistMenu from './menu/SpecialistMenu';

const SHOW_ALERT = true;

// eslint-disable-next-line no-unused-vars
const MESSAGE =
  'Nowa aktualizacja (18.10.2024). Aby zobaczyć nowe funkcjonalności przejdź do zakładki "Co nowego?" Jesteśmy dostępni dla Państwa pod nr. +48 22 398 74 85 lub mailowo biuro@4parents.pl';

class NavigationDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: SHOW_ALERT
    };
  }

  getFirstId() {
    return emptyUrlGroup;
  }

  renderMenu(auth, groups, onRouteSelect) {
    const rest = {
      groups,
      onRouteSelect,
      currentRoute: this.props.currentRoute,
      isDaycareEnabled: this.props.isDaycareEnabled,
      features: this.props.features,
      insurance: this.props.insurance,
      fkIntegration: this.props.fkIntegration,
      symphonyIntegration: this.props.symphonyIntegration
    };
    if (auth.isStaffMember(userRoles.staffMemberTeacher)) {
      if (rest.features.nadarzyn) return <ReducedTeacherMenu {...rest} />;

      return <TeacherMenu firstGroupId={this.getFirstId()} {...rest} />;
    }
    if (auth.isStaffMember(userRoles.staffMemberSecretary)) {
      return (
        <SecretaryMenu
          exportUi={this.props.exportUi}
          importChildren={this.props.importActions.importChildrenStart}
          exportChildren={this.props.exportActions.exportChildrenStart}
          firstGroupId={this.getFirstId()}
          {...rest}
        />
      );
    }
    if (auth.isStaffMember(userRoles.staffMemberPrincipal)) {
      if (rest.features.nadarzyn)
        return (
          <ReducedPrincipalMenu
            exportUI={this.props.exportUi}
            importChildren={this.props.importActions.importChildrenStart}
            exportChildren={this.props.exportActions.exportChildrenStart}
            {...rest}
          />
        );

      return (
        <PrincipalMenu
          exportUi={this.props.exportUi}
          importChildren={this.props.importActions.importChildrenStart}
          exportChildren={this.props.exportActions.exportChildrenStart}
          firstGroupId={this.getFirstId()}
          {...rest}
        />
      );
    }
    if (auth.isStaffMember(userRoles.staffMemberAccountant)) {
      return <AccountantMenu firstGroupId={this.getFirstId()} {...rest} />;
    }
    if (auth.isStaffMember(userRoles.staffMemberCanteenWorker)) {
      return <CanteenWorkerMenu firstGroupId={this.getFirstId()} {...rest} />;
    }
    if (auth.isStaffMember(userRoles.staffMemberDigitalDiary)) {
      return <DigitalDiaryTeacherMenu firstGroupId={this.getFirstId()} {...rest} />;
    }
    if (auth.isStaffMember(userRoles.staffMemberTeacherSupport)) {
      return <TeacherSupportMenu firstGroupId={this.getFirstId()} {...rest} />;
    }
    if (auth.isStaffMember(userRoles.staffMemberSpecialist)) {
      return <SpecialistMenu firstGroupId={this.getFirstId()} {...rest} />;
    }
    if (auth.isLegalGuardian()) {
      if (rest.features.nadarzyn) return <ReducedStudentMenu {...rest} />;

      return <GuardianMenu {...rest} />;
    }
    return null;
  }

  render() {
    const { auth, groups, openChangeRequest } = this.props;
    return (
      <Box sx={{ zIndex: 5000 }}>
        <Box sx={{ overflow: 'auto' }}>
          <Stack sx={{ alignItems: 'stretch' }}>
            <Box
              sx={{
                width: '244px',
                height: '70px',
                background: `url(${navlogo})`,
                m: 4,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat'
              }}
            />

            {!this.props.nadarzyn && (
              <Stack sx={{ mx: 1 }}>
                <ErrorAlertBox
                  severity="success"
                  message={MESSAGE}
                  state={this.state.showAlert}
                  onClose={() => this.setState({ showAlert: false })}
                />
              </Stack>
            )}
            {this.renderMenu(auth, groups, () => openChangeRequest())}
          </Stack>
          <Box
            sx={{
              p: 0,
              m: 0,
              background: this.props.theme === 'dark' ? 'black' : 'white',
              boxShadow: ' 0 -5px 5px -5px #333',
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}>
            <a href="https://www.kleksacademy.com/">
              <img
                src={this.props.theme === 'dark' ? kleksLogoDark : kleksLogo}
                style={{
                  width: 300
                }}
                alt="Akademia pana kleksa, zapraszamy do kin"
              />
            </a>
          </Box>
        </Box>
      </Box>
    );
  }
}

NavigationDrawer.propTypes = {
  openChangeRequest: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  currentRoute: PropTypes.string.isRequired,
  isDaycareEnabled: PropTypes.bool,
  features: PropTypes.object,
  insurance: PropTypes.object,
  importActions: PropTypes.object.isRequired,
  exportActions: PropTypes.object.isRequired,
  fkIntegration: PropTypes.bool.isRequired,
  symphonyIntegration: PropTypes.bool.isRequired,
  exportUi: PropTypes.object.isRequired,
  nadarzyn: PropTypes.bool,
  drawerPresistance: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    theme: state.theme,
    groups: state.groups,
    currentRoute: state.routing.current.matchedRoute,
    isDaycareEnabled: state.configuration.daycare.enabled,
    features: state.configuration.unit.features,
    insurance: state.configuration.unit.insurance,
    fkIntegration: state.configuration.settlementsSettings.integrationEnabled,
    symphonyIntegration: state.configuration.settlementsSettings.symphonyIntegrationEnabled,
    exportUi: state.configuration.exportChildrenUi,
    nadarzyn: state.configuration.unit.features.nadarzyn
  };
}

function mapDispatchToProps(dispatch) {
  return {
    importActions: bindActionCreators(importChildrenActions, dispatch),
    exportActions: bindActionCreators(exportChildrenActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withMediaQuery()(NavigationDrawer));
