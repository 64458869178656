import { useSelector } from 'react-redux';
import userRoles from '../constants/userRoles';

export function useIsSpecialist() {
  const accountType = useSelector((state) => state.auth.userRole);
  const specialistId = useSelector((state) => state.auth.userId);
  const allowedSpecialistsIds = useSelector((state) => state.specialistNotes.specialistsIds);

  const isSpecialist =
    accountType === userRoles.staffMemberPrincipal ||
    ((accountType === userRoles.staffMemberTeacher || accountType === userRoles.staffMemberSpecialist) &&
      allowedSpecialistsIds.includes(specialistId));

  const isPrincipal = accountType === userRoles.staffMemberPrincipal;

  const isSpecialistOrPrincipal =
    accountType === userRoles.staffMemberPrincipal ||
    accountType === userRoles.staffMemberTeacher ||
    accountType === userRoles.staffMemberSpecialist;

  return { isSpecialist, isSpecialistOrPrincipal, specialistId, isPrincipal };
}
