import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PageHeader from '../../common/PageHeader';
import LoadingRenderWrapper from '../../common/loading/LoadingRenderWrapper';
import * as digitalDiaryEvents from '../../../actions/digitalDiaryActions';
import * as dailySchedulesEvents from '../../../actions/dailySchedulesActions';
import PageHeaderText from '../../common/pageHeader/PageHeaderText';
import ContentWrapper from '../../common/contentWrapper';
import GroupSwitch from '../../common/groups/GroupSwitch';
import EmptyState from '../../common/EmptyState';
import { DailyScheduleEventModel } from '../../../models/digitalDiary/dailySchedule/DailyScheduleModel';
import FrameworkDayScheduleEditDialog from './FrameworkDayScheduleEditDialog';
import DailyScheduleItem from './DailyScheduleItem';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  Grid,
  Box,
  Paper,
  Button
} from '@mui/material';
import moment from 'moment';

const FrameworkDaySchedulePage = () => {
  const [currentEvent, setCurrentEvent] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [scheduleYearbook, setScheduleYearbook] = useState(() => moment().year());
  const yearbooks = useMemo(generateYearbookValues, []);

  const choosenGroup = useSelector((state) => state.digitalDiary.choosenGroup);
  const dailySchedule = useSelector((state) => state.dailySchedulesReducer);
  const ui = useSelector((state) => state.dailySchedulesUiReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (choosenGroup?.id && scheduleYearbook) {
      dispatch(dailySchedulesEvents.loadDailySchedule(choosenGroup.id, scheduleYearbook));
    }
  }, [scheduleYearbook, choosenGroup]);

  const groupSelect = useCallback((group) => {
    dispatch(digitalDiaryEvents.changeChoosenGroup(group));
  }, []);

  const handleYearbookChange = (value) => {
    const isTemporary = moment().year().toString() === value;
    setScheduleYearbook(isTemporary ? moment().year() : parseInt(value, 10));
  };

  const handleEventSave = async (event) => {
    if (event.id) {
      await dispatch(dailySchedulesEvents.updateDailyScheduleItem(dailySchedule.id, event));
    } else if (dailySchedule?.id) {
      await dispatch(dailySchedulesEvents.createDailyScheduleItem(dailySchedule.id, event));
    } else {
      const dailyScheduleId = await dispatch(
        dailySchedulesEvents.createDailySchedule(choosenGroup.id, scheduleYearbook)
      );
      await dispatch(dailySchedulesEvents.createDailyScheduleItem(dailyScheduleId, event));
    }
    setDialogOpen(false);
  };

  const handleEventEdit = (event = null) => {
    setCurrentEvent(event ? event : new DailyScheduleEventModel());
    setDialogOpen(true);
  };

  const handleDeleteEvent = (event) => {
    dispatch(dailySchedulesEvents.deleteDailyScheduleItem(dailySchedule.id, event));
  };

  return (
    <LoadingRenderWrapper>
      <Box>
        <PageHeader>
          <PageHeaderText title="Roczny ramowy plan dnia" titleIcon={<EventNoteIcon />} />
        </PageHeader>
        <ContentWrapper>
          <Grid container sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Grid item xs={12} md={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <Typography variant="caption" sx={{ pl: 0, color: (theme) => theme.palette.color.primary }}>
                    Rok szkolny
                  </Typography>
                </FormLabel>
                <RadioGroup
                  aria-label="wybierz rok szkolny"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    '& .MuiSvgIcon-root': {
                      fontSize: 40,
                      color: (theme) => theme.palette.color.secondary
                    }
                  }}
                  name="selectYearBooks"
                  onChange={(e) => handleYearbookChange(e.target.value)}
                  value={scheduleYearbook.toString()}>
                  {yearbooks?.map((yearbook) => (
                    <FormControlLabel
                      key={`yearbook-${yearbook.name}`}
                      value={yearbook.value}
                      sx={{ mr: 5, color: (theme) => theme.palette.color.primary }}
                      control={<Radio />}
                      label={yearbook.name}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <GroupSwitch onGroupChange={groupSelect} showArchivedGroups={false} isPsychological={false} />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              aria-label="Nowy wpis"
              sx={{ py: 1, lineHeight: 1 }}
              onClick={() => handleEventEdit()}>
              Nowy wpis
            </Button>
          </Box>
          <Box>
            {dailySchedule?.id && dailySchedule.scheduleItems?.length > 0 ? (
              dailySchedule.scheduleItems.map((item) => (
                <DailyScheduleItem key={item.id} item={item} onEdit={handleEventEdit} onDelete={handleDeleteEvent} />
              ))
            ) : (
              <Paper sx={{ my: 2, p: 4 }} elevation={1}>
                <EmptyState message="Brak wpisów na wybrany rok szkolny" contrast />
              </Paper>
            )}
          </Box>
        </ContentWrapper>
        {dialogOpen && (
          <FrameworkDayScheduleEditDialog
            event={currentEvent}
            items={dailySchedule.scheduleItems}
            onSave={handleEventSave}
            onCancel={() => setDialogOpen(false)}
            isDialogOpen={dialogOpen || ui.items.isEditing}
            isProcessing={ui.items.isProcessing}
            isMobile={false}
            errors={ui.items.asyncErrors}
          />
        )}
      </Box>
    </LoadingRenderWrapper>
  );
};

function generateYearbookValues() {
  const year = moment().year();
  const prevYear = year - 1;
  const nextYear = year + 1;
  return [
    { name: `${prevYear}/${year}`, value: prevYear.toString() },
    { name: `${year}/${nextYear}`, value: year.toString() }
  ];
}

export default FrameworkDaySchedulePage;
