import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import PosterTemplateNoQr from './posterTemplateNoQr';
import { Box, Button, Stack, Typography } from '@mui/material';
import './css/style.css';

function DownloadPosterNoQr({ domain }) {
  const reportTemplateRef = useRef(null);
  const handleGeneratePdf = async (formatType) => {
    if (reportTemplateRef.current === null) {
      return;
    }
    try {
      const canvas = await html2canvas(reportTemplateRef.current, {
        scale: 8,
        useCORS: true,
        logging: false,
        windowWidth: formatType === 'a4' ? 2480 : 3508,
        windowHeight: formatType === 'a4' ? 3508 : 4961
      });

      const imgData = canvas.toDataURL('image/jpeg', 1.0);

      const pdf = new jsPDF({
        format: formatType,
        unit: 'mm',
        orientation: 'portrait'
      });

      const imgWidth = formatType === 'a4' ? 210 : 297;
      const imgHeight = formatType === 'a4' ? 297 : 420;

      pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, '', 'FAST');

      pdf.save(`plakat-${formatType}.pdf`);
    } catch (error) {
      console.error('Wystąpił błąd podczas generowania PDF:', error);
    }
  };

  return (
    <Box textAlign="center" justifyContent="center">
      <Typography variant="h4" fontWeight={600}>
        Pobierz plakat
      </Typography>
      <Stack direction="column" spacing={1} alignItems="center">
        <Button
          variant="contained"
          fullWidth={false}
          sx={{ mt: 2, textTransform: 'none', width: '30%' }}
          onClick={() => handleGeneratePdf('a4')}>
          Format A4
        </Button>
        <Button
          variant="contained"
          fullWidth={false}
          sx={{ mt: 2, textTransform: 'none', width: '30%' }}
          onClick={() => handleGeneratePdf('a3')}>
          Format A3
        </Button>
      </Stack>
      <Box ref={reportTemplateRef} sx={{ background: 'white !important', color: 'black !important' }}>
        <PosterTemplateNoQr domain={domain} />
      </Box>
    </Box>
  );
}

export default DownloadPosterNoQr;
