import BaseModel from './BaseModel';

export class UiModel extends BaseModel {
  constructor(errors) {
    super();
    this.isEditing = false;
    this.isProcessing = false;
    this.asyncErrors = errors || null;
  }

  static start() {
    return new UiModel().assign({
      isEditing: true,
      isProcessing: false,
      asyncErrors: null
    });
  }

  static cancel() {
    return new UiModel().assign({
      isEditing: false,
      isProcessing: false,
      asyncErrors: null
    });
  }

  static submit() {
    return new UiModel().assign({
      isEditing: true,
      isProcessing: true,
      asyncErrors: null
    });
  }

  static success() {
    return new UiModel().assign({
      isEditing: false,
      isProcessing: false,
      asyncErrors: null
    });
  }

  static failure(errors) {
    return new UiModel().assign({
      isEditing: true,
      isProcessing: false,
      asyncErrors: errors
    });
  }
}
