import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography, Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIsSpecialist } from '../../../../hooks/useIsSpecialist';

import { NoteModel } from '../../../../models/psychological/notes/NoteModel';
import IndividualJournalNote from './IndividualJournalNote';
import EmptyState from '../../../common/EmptyState';
import NotesAndObservationsDialog from '../../../individualClassesDetails/NotesAndObservationsDialog';
import {
  createNewNoteElement,
  deleteNoteElement,
  editNoteElement,
  setNotesDialogClosed,
  setNotesDialogOpen
} from '../../../../actions/psychologicalIndividualJournalClassesActions';
import moment from 'moment';
import { simpleSortByDateTime } from '../../../specialistNotes/utils/sortByDateTime';

const IndividualJournalNotesList = () => {
  const dispatch = useDispatch();

  const notes = useSelector((state) => state.psychologicalIndividualJournalWorkProgram.notes);
  const Ui = useSelector((state) => state.psychologicalIndividualJournalUi.notes);
  const [currentNote, setCurrentNote] = useState(null);
  const assistanceId = useSelector((state) => state.psychologicalIndividualJournalWorkProgram.id);
  const { isSpecialistOrPrincipal } = useIsSpecialist();

  const handleSave = (note) => {
    note.type = 'notes';
    if (!!note.id) {
      dispatch(editNoteElement(note, assistanceId));
    } else {
      dispatch(createNewNoteElement(note, assistanceId));
    }
  };

  const handleDelete = (data) => {
    data.type = 'notes';
    dispatch(deleteNoteElement(data, assistanceId));
  };

  const handleNoteEdit = (note) => {
    if (note) {
      setCurrentNote(note);
    } else {
      setCurrentNote(new NoteModel());
    }
    dispatch(setNotesDialogOpen());
  };

  const handleCancel = () => {
    setCurrentNote(null);
    dispatch(setNotesDialogClosed());
  };

  return (
    <Box>
      <Paper sx={{ p: 1 }}>
        <Accordion sx={{ boxShadow: 0 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="h6">Notatki</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {isSpecialistOrPrincipal && (
                <Button
                  variant="contained"
                  aria-label="dodaj"
                  sx={{ py: 1, lineHeight: 1 }}
                  onClick={() => handleNoteEdit()}>
                  dodaj
                </Button>
              )}
            </Box>
          </AccordionDetails>
          {!!notes?.length > 0 ? (
            simpleSortByDateTime(notes)?.map((note) => (
              <IndividualJournalNote
                note={note}
                onEdit={(note) => handleNoteEdit(note)}
                onDelete={(note) => handleDelete(note)}
                key={`note-${note.id}`}
                canManage={isSpecialistOrPrincipal}
              />
            ))
          ) : (
            <EmptyState message="Brak" contrast />
          )}
        </Accordion>
      </Paper>
      {Ui.isEditing && (
        <NotesAndObservationsDialog
          handleCancel={handleCancel}
          selectedElement={currentNote}
          onSave={handleSave}
          type="notes"
          Ui={Ui}
        />
      )}
    </Box>
  );
};

export default IndividualJournalNotesList;
