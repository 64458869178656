import React from 'react';
import { Box, Card, CardContent, Paper } from '@mui/material';
import DownloadPosterNoQr from './downloadPosterNoQr';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import PageHeaderText from '../common/pageHeader/PageHeaderText';
import { getTenantName } from '../../utils/getTenantName';

const PosterDownloadPage = () => {
  // const currentDomain = window.location.origin.replace(/^https?:\/\//, '');
  const currentDomain = getTenantName();

  return (
    <Box sx={{ padding: 2, display: 'column', flexDirection: 'column', justifyContent: 'center' }}>
      <PageHeaderText titleIcon={<DriveFolderUploadIcon />} title="Materiały promocyjne" />
      <Paper sx={{ padding: 4, minWidth: '100%' }}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
              <DownloadPosterNoQr domain={currentDomain} />
            </Box>
          </CardContent>
        </Card>
      </Paper>
    </Box>
  );
};

export default PosterDownloadPage;
