import BaseModel from '../BaseModel';
import jwtDecode from 'jwt-decode';
import userRoles from '../../constants/userRoles';

export class AuthModel extends BaseModel {
  constructor(jwtToken) {
    super();
    if (!jwtToken) {
      this.userId = null;
      this.userName = null;
      this.userFullName = null;
      this.signedIn = false;
      this.userRole = null;
      this.trustedProfileUserId = null;
    } else {
      const decoded = jwtDecode(jwtToken);
      this.userId = decoded.userId;
      this.userName = decoded.userName;
      this.userFullName = decoded.userFullName;
      this.signedIn = true;
      this.userRole = decoded.userRole;
      this.trustedProfileUserId = null;
    }
    this.profilePhoto = null;
    this.lastAuthErrorMessage = null;
    this.isProcessing = false;
    this.routeAllowedActions = [];
    this.pin = null;
    this.acceptanceRequired = false;
  }

  isStaffMember(roles) {
    if (!roles)
      return [
        userRoles.staffMemberTeacher,
        userRoles.staffMemberPrincipal,
        userRoles.staffMemberAccountant,
        userRoles.staffMemberCanteenWorker,
        userRoles.staffMemberSecretary,
        userRoles.staffMemberTeacherSupport,
        userRoles.staffMemberDigitalDiary,
        userRoles.staffMemberSpecialist
      ].includes(this.userRole);
    if (Array.isArray(roles)) return roles.includes(this.userRole);
    return roles === this.userRole;
  }

  isLegalGuardian() {
    return this.userRole === userRoles.legalGuardian;
  }

  isPinDevice() {
    return this.userRole === userRoles.pinDevice;
  }

  isAllowed(actionName) {
    return this.routeAllowedActions.includes(actionName);
  }
}
