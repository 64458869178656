import api from '../api/DailySchedulesApi';
import * as types from './actionTypes';
import * as notificationActions from './notificationActions';
import * as get from './getStateHelpers';
import * as logger from '../utils/logger';

export function createDailySchedule(groupId, year) {
  return (dispatch, getState) => {
    dispatch({ type: types.CREATE_DAILY_SCHEDULE });
    return api
      .createDailySchedule({groupId, year})
      .then((dailySchedule) => {
        dispatch({ type: types.CREATE_DAILY_SCHEDULE_SUCCESS, dailyScheduleId: dailySchedule.id });
        return dailySchedule.id
      })
      .catch((error) => {
        dispatch(
          notificationActions.showError(
            `Nie udało się utworzyć ramowego planu dnia dla grupy '${get.groupName(getState, groupId)}'`
          )
        );
        return logger.error(error);
      });
  };
}

export function loadDailySchedule(groupId, year) {
  return (dispatch, getState) => {
    dispatch({ type: types.LOAD_DAILY_SCHEDULE_START });
    return api
      .getDailyScheduleByGroupAndYear(groupId, year)
      .then((dailySchedules) => {
        dispatch({ type: types.LOAD_DAILY_SCHEDULE_SUCCESS, dailySchedules });
      })
      .catch((errors) => {
        dispatch({ type: types.LOAD_DAILY_SCHEDULE_FAILURE, errors });
        if (errors.status !== 404) {
          dispatch(
            notificationActions.showError(
              `Nie udało się pobrać rocznego planu dla grupy '${get.groupName(getState, groupId)}'`
            )
          );
        }
        return logger.error(errors);
      });
  };
}

export function createDailyScheduleItem(scheduleId, item) {
  return (dispatch, getState) => {
    dispatch({ type: types.ADD_DAILY_SCHEDULE_ITEM });
    return api
      .addItemToDailySchedule(scheduleId, item)
      .then((item) => {
        dispatch({ type: types.ADD_DAILY_SCHEDULE_ITEM_SUCCESS, item });
        dispatch(
          notificationActions.showSuccess(
            `Utworzono nowy element rozkładu`
          )
        );
      })
      .catch((error) => {
        dispatch({ type: types.ADD_DAILY_SCHEDULE_ITEM_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się utworzyć nowego elementu rozkładu`
          )
        );
        return logger.error(error);
      });
  };
}

export function updateDailyScheduleItem(scheduleId, item) {
  return (dispatch, getState) => {
    dispatch({ type: types.UPDATE_DAILY_SCHEDULE_ITEM });
    return api
      .updateDailyScheduleItem(scheduleId, item.id, item)
      .then((id) => {
        dispatch({ type: types.UPDATE_DAILY_SCHEDULE_ITEM_SUCCESS, item});
        dispatch(
          notificationActions.showSuccess(
            `Edytowano element rozkładu ${item.title}`
          )
        );
      })
      .catch((error) => {
        dispatch({ type: types.UPDATE_DAILY_SCHEDULE_ITEM_FAILURE, errors: error.response.body });
        dispatch(
          notificationActions.showError(
            `Nie udało się edytować elementu rozkładu ${item.title}`
          )
        );
        return logger.error(error);
      });
  };
}

export function deleteDailyScheduleItem(scheduleId, item) {
  return (dispatch, getState) => {
    dispatch({ type: types.REMOVE_DAILY_SCHEDULE_ITEM });
    return api
      .deleteItemFromDailySchedule(scheduleId, item.id)
      .then(() => {
        dispatch({ type: types.REMOVE_DAILY_SCHEDULE_ITEM_SUCCESS, item });
        dispatch(
          notificationActions.showSuccess(
            `Usunięto element rozkładu ${item.title}`
          )
        );
      })
      .catch((errors) => {
        dispatch({ type: types.REMOVE_DAILY_SCHEDULE_ITEM_FAILURE, errors });
        dispatch(
          notificationActions.showError(
            `Nie udało się usunąć elementu rozkładu ${item.title}`
          )
        );
        return logger.error(errors);
      });
  };
}

